import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {of} from 'rxjs'
import {
  map,
  concat,
  mapTo,
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators'
import {get} from 'lodash'
import {FirebaseApi} from '../../api'

import {setCurrentModal, closeModal} from '../app'
import {getDetailsGoal} from '../goalDetails'

const INITAL_STATE = {
  taskId: null,
  taskBeforeEdit: null,
}

const setTaskBeforeEdit = createAction(
  'remente/goal/task/edit/setTaskBeforeEdit',
)
export const editGoalTask = createAction('remente/goal/task/edit/editGoalTask')
export const updateGoalTask = createAction(
  'remente/goal/task/edit/updateGoalTask',
)
const updateGoalTaskFulfilled = createAction(
  'remente/goal/task/edit/updateGoalTaskFulfilled',
)
const updateGoalTaskRejected = createAction(
  'remente/goal/task/edit/updateGoalTaskRejected',
)
const showEditGoalTaskModal = createAction(
  'remente/goal/task/edit/showEditGoalTaskModal',
)
export const deleteGoalTask = createAction(
  'remente/goal/task/edit/deleteGoalTask',
)
const deleteGoalTaskFulfilled = createAction(
  'remente/goal/task/edit/deleteGoalTaskFulfilled',
)
const deleteGoalTaskRejected = createAction(
  'remente/goal/task/edit/deleteGoalTaskRejected',
)

/**
 * Reducers
 */

const taskIdReducer = handleActions(
  {
    [editGoalTask]: (state, {payload}) => payload,
  },
  INITAL_STATE.taskId,
)

const taskBeforeEditReducer = handleActions(
  {
    [setTaskBeforeEdit]: (state, {payload}) => payload,
  },
  INITAL_STATE.taskBeforeEdit,
)

export default combineReducers({
  taskId: taskIdReducer,
  taskBeforeEdit: taskBeforeEditReducer,
})

/**
 * Selectors
 */

const taskIdSelector = ({editGoalTask}) => editGoalTask.taskId
const taskBeforeEditSelector = ({editGoalTask}) => editGoalTask.taskBeforeEdit

const getGoalTaskId = createSelector(taskIdSelector, id => id)

const getGoalTask = createSelector(
  getDetailsGoal,
  getGoalTaskId,
  (goal, taskId) => get(goal, ['tasksById', taskId]),
)

export const getTaskBeforeEdit = createSelector(
  taskBeforeEditSelector,
  task => task,
)

/**
 * Epics
 */

const editGoalTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(editGoalTask().type),
    flatMap(() => {
      const task = getGoalTask(state$.value)
      return [setTaskBeforeEdit(task), showEditGoalTaskModal()]
    }),
  )

const showEditGoalTaskModalEpic = action$ =>
  action$.pipe(
    ofType(showEditGoalTaskModal().type),
    mapTo(
      setCurrentModal({
        currentModal: 'goal/task/edit',
      }),
    ),
  )

const updateGoalTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateGoalTask().type),
    map(({payload}) => payload),
    switchMap(task => {
      const {id, organizationId} = getDetailsGoal(state$.value)
      return FirebaseApi.updateGoalTask({
        organizationId,
        goalId: id,
        task,
      }).pipe(
        flatMap(() => [updateGoalTaskFulfilled(), closeModal()]),
        catchError(err => of(updateGoalTaskRejected(err))),
      )
    }),
  )

const deleteGoalTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(deleteGoalTask().type),
    switchMap(() => {
      const {id, organizationId} = getDetailsGoal(state$.value)
      const taskId = getGoalTaskId(state$.value)
      return of(closeModal()).pipe(
        concat(
          FirebaseApi.deleteGoalTask({
            organizationId,
            goalId: id,
            id: taskId,
          }).pipe(
            map(deleteGoalTaskFulfilled),
            catchError(err => of(deleteGoalTaskRejected(err))),
          ),
        ),
      )
    }),
  )

export const editGoalTaskEpics = combineEpics(
  editGoalTaskEpic,
  showEditGoalTaskModalEpic,
  updateGoalTaskEpic,
  deleteGoalTaskEpic,
)
