import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {map, mapTo, delay} from 'rxjs/operators'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {get} from 'lodash'
import queryString from 'query-string'

export const historyChanged = createAction('remente/route/historyChanged')
export const pathnamePartsChanged = createAction(
  'remente/route/pathnamePartsChanged',
)
export const redirectRoute = createAction('remente/route/redirectRoute')
export const resetRedirectRoute = createAction(
  'remente/route/resetRedirectRoute',
)
export const redirectToGoalDetails = createAction(
  'remente/route/redirectToGoalDetails',
)
export const redirectToGoals = createAction('remente/route/redirectToGoals')

/**
 * Reducers
 */

const routerParamsReducer = handleActions(
  {
    [historyChanged]: (state, {payload}) => payload,
  },
  {},
)

const redirectToReducer = handleActions(
  {
    [redirectRoute]: (state, {payload}) => payload,
    [resetRedirectRoute]: () => null,
  },
  null,
)

export default combineReducers({
  routerParams: routerParamsReducer,
  redirectTo: redirectToReducer,
})

/**
 * Selectors
 */

export const getRouterParams = ({route}) => route.routerParams
export const getLocation = ({route}) => route.routerParams.location
export const getLastAction = ({route}) => route.routerParams.action
export const redirToSelector = ({route}) => route.redirectTo

export const getPathname = createSelector(getLocation, location =>
  get(location, 'pathname', ''),
)

export const getSearch = createSelector(getLocation, location =>
  get(location, 'search'),
)

export const getPathnameParts = createSelector(getPathname, pathname =>
  pathname.substring(1).split('/'),
)

export const getQuery = createSelector(getSearch, search =>
  queryString.parse(search),
)

export const getRouteRedirect = createSelector(redirToSelector, route => route)

/**
 * Epics
 */

const pathnamePartsChangedEpic = action$ =>
  action$.pipe(
    ofType(historyChanged().type),
    map(({payload}) => payload.location.pathname),
    map(pathname => pathname.split('/').filter(s => s.length)),
    map(parts => pathnamePartsChanged(parts)),
  )

const resetRedirectToEpic = action$ =>
  action$.pipe(
    ofType(redirectRoute().type),
    // hotfix(18/9-17): allow React to render <Redirect /> in App.js
    delay(200),
    mapTo(resetRedirectRoute()),
  )

const redirectToGoalDetailsEpic = action$ =>
  action$.pipe(
    ofType(redirectToGoalDetails().type),
    map(({payload}) => payload),
    map(id => redirectRoute(`/achieve/${id}`)),
  )

const redirectToGoalsEpic = action$ =>
  action$.pipe(ofType(redirectToGoals().type), mapTo(redirectRoute('/achieve')))

export const routeEpics = combineEpics(
  pathnamePartsChangedEpic,
  resetRedirectToEpic,
  redirectToGoalDetailsEpic,
  redirectToGoalsEpic,
)
