import React, {Component} from 'react'
import styled from 'styled-components'

import {BrowserRouter as Router, Redirect} from 'react-router-dom'

import {IntlProvider, addLocaleData} from 'react-intl'
import en from 'react-intl/locale-data/en'
import sv from 'react-intl/locale-data/sv'
import de from 'react-intl/locale-data/de'
import es from 'react-intl/locale-data/es'
import 'moment/locale/sv'

import {getTranslations} from './locales'

import './app.css'

import RouteAuth from './components/RouteAuth.js'
import AppLoading from './components/AppLoading.js'
import CoverSwitch from './components/CoverSwitchContainer.js'
import ModalSwitch from './components/ModalSwitchContainer.js'
import RouterRedux from './components/RouterRedux.js'

import UpdateAvailableBanner from './components/UpdateAvailableBannerContainer.js'

addLocaleData([...en, ...sv, ...de, ...es])

const Body = styled(RouterRedux)`
  ${({theme}) => `
    color: ${theme.colorBodyFg};
    background: ${theme.colorBodyBg};
  `}
`

class App extends Component {
  render() {
    const {hasAuthState, language, isAuthenticated, redirectTo} = this.props

    const DoRedirect = redirectTo && (
      <Redirect push to={{pathname: redirectTo}} />
    )

    const intlMessages = getTranslations(language)

    const MainRoutes = (
      <Router>
        <Body>
          {DoRedirect}

          <UpdateAvailableBanner />

          <RouteAuth isAuthenticated={isAuthenticated} />

          <ModalSwitch />

          <CoverSwitch />
        </Body>
      </Router>
    )

    return (
      <IntlProvider key={language} locale={language} messages={intlMessages}>
        {hasAuthState ? MainRoutes : <AppLoading />}
      </IntlProvider>
    )
  }
}

export default App
