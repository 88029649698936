import styled from 'styled-components'

export const Next = styled.div`
  color: ${({theme}) => theme.NextLesson.c.fg};
  background: ${({theme}) => theme.NextLesson.c.bg};
  position: relative;
  margin-top: 4em;
  padding: 180px 0 40px;
  text-align: right;
  transition: all 150ms ease-out;
  h3 {
    text-transform: uppercase;
    opacity: 0.5;
  }
  &:hover {
    background: ${({theme}) => theme.NextLesson.c.hoverBg};
  }
`

export const IconWrap = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`
