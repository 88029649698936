import React, {Component} from 'react'
import {withRouter} from 'react-router'

class ScrollTopClass extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location === prevProps.location) return
    this.mainNode.scrollTop = 0
  }

  setMainNode(mainNode) {
    this.mainNode = mainNode
  }

  render() {
    const {props} = this
    const {className} = props
    const mainProps = {
      className,
    }
    return (
      <main {...mainProps} ref={this.setMainNode.bind(this)}>
        {props.children}
      </main>
    )
  }
}

const ScrollTop = withRouter(ScrollTopClass)

export default ScrollTop
