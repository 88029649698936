import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createAction, handleActions, combineActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {keyBy, get} from 'lodash'
import {of} from 'rxjs'
import {map, flatMap, mergeMap, catchError, delay, concat} from 'rxjs/operators'
import moment from 'moment'

import {FirebaseApi} from '../../api'
import {arrayMove} from '../../utils/array'

import {
  getAutoPlannedTasks,
  getActiveTodoTasks,
  compileTodoListItems,
  sortTodoListItems,
  includeJourneyEntriesInTasks,
} from '../../utils/dayplanner'

import {
  getGoalsWithProgressById,
  getMyGoalsEnrichedActiveTasks,
  getMyGoalsEnrichedActiveTasksById,
} from '../goalProgress'

import {getMyTodoTasksById} from '../userContent'
import {getDayplansByYearMonthDay} from '../dayplan'
import {getMyTodoTasks, todoTasksLastPlannedHashMapSelector} from '../todoTask'
import {getMyJourneyEntriesByDate} from '../journeyEntry'
import {getCurrentTimeMinute, tickMinuteHeartbeat} from '../timing'
import {redirectRoute} from '../route'
import {getIsAuthenticatedUserAllowedPremiumContent} from '../auth'
import {showPaywall} from '../app'

const INITAL_STATE = {
  taskInputValue: '',
  isPlanMode: false,
  isPlanModeAllowed: true,
  selectedDate: moment(),
  selectedDateLastSet: moment(),
}

export const navigateAndEnterPlanMode = createAction(
  'remente/dayplanner/navigateAndEnterPlanMode',
)
export const setSelectedDate = createAction(
  'remente/dayplanner/setSelectedDate',
)
export const setSelectedDateFromDatepicker = createAction(
  'remente/dayplanner/setSelectedDateFromDatepicker',
)
export const setSelectedDateToday = createAction(
  'remente/dayplanner/setSelectedDateToday',
)
export const incrementSelectedDate = createAction(
  'remente/dayplanner/incrementSelectedDate',
)
export const decrementSelectedDate = createAction(
  'remente/dayplanner/decrementSelectedDate',
)
export const setTaskInputValue = createAction(
  'remente/dayplanner/setTaskInputValue',
)
export const setIsPlanMode = createAction('remente/dayplanner/setIsPlanMode')
export const togglePlanMode = createAction('remente/dayplanner/togglePlanMode')
export const togglePlanModeIllegal = createAction(
  'remente/dayplanner/togglePlanModeIllegal',
)
export const toggleTaskCompleted = createAction(
  'remente/dayplanner/toggleTaskCompleted',
)
export const toggleTaskCompletedFulfilled = createAction(
  'remente/dayplanner/toggleTaskCompletedFulfilled',
)
const toggleTaskCompletedRejected = createAction(
  'remente/dayplanner/toggleTaskCompletedRejected',
)
export const updateTodoListOrder = createAction(
  'remente/dayplanner/updateTodoListOrder',
)
const updateTodoListOrderFulfilled = createAction(
  'remente/dayplanner/updateTodoListOrderFulfilled',
)
const updateTodoListOrderRejected = createAction(
  'remente/dayplanner/updateTodoListOrderRejected',
)
export const postponeTask = createAction('remente/dayplanner/postponeTask')
const postponeTaskFulfilled = createAction(
  'remente/dayplanner/postponeTaskFulfilled',
)
const postponeTaskRejected = createAction(
  'remente/dayplanner/postponeTaskRejected',
)
export const addGoalStepToPlan = createAction(
  'remente/dayplanner/addGoalStepToPlan',
)
const addGoalStepToPlanFulfilled = createAction(
  'remente/dayplanner/addGoalStepToPlanFulfilled',
)
const addGoalStepToPlanRejected = createAction(
  'remente/dayplanner/addGoalStepToPlanRejected',
)
export const createAndPlanTodoTask = createAction(
  'remente/dayplanner/createAndPlanTodoTask',
)
export const createAndPlanTodoTaskFulfilled = createAction(
  'remente/dayplanner/createAndPlanTodoTaskFulfilled',
)
const createAndPlanTodoTaskRejected = createAction(
  'remente/dayplanner/createAndPlanTodoTaskRejected',
)
export const deleteTodoTask = createAction('remente/dayplanner/deleteTodoTask')
const deleteTodoTaskFulfilled = createAction(
  'remente/dayplanner/deleteTodoTaskFulfilled',
)
const deleteTodoTaskRejected = createAction(
  'remente/dayplanner/deleteTodoTaskRejected',
)
export const setIsPlanModeAllowed = createAction(
  'remente/dayplanner/setIsPlanModeAllowed',
)
const tickNextDayNoop = createAction('remente/dayplanner/tickNextDayNoop')

/**
 * Reducers
 */

const selectedDateReducer = handleActions(
  {
    [setSelectedDate]: (state, {payload}) => payload,
    [setSelectedDateToday]: () => moment(),
  },
  INITAL_STATE.selectedDate,
)

const selectedDateLastSetReducer = handleActions(
  {
    [combineActions(setSelectedDate, setSelectedDateToday)]: () => moment(),
  },
  INITAL_STATE.selectedDateLastSet,
)

const taskInputValueReducer = handleActions(
  {
    [setTaskInputValue]: (state, {payload}) => payload,
    [combineActions(createAndPlanTodoTask, addGoalStepToPlan)]: () =>
      INITAL_STATE.taskInputValue,
    [setIsPlanMode]: (state, {payload}) =>
      payload ? state : INITAL_STATE.taskInputValue,
  },
  INITAL_STATE.taskInputValue,
)

const isPlanModeReducer = handleActions(
  {
    [setIsPlanMode]: (state, {payload}) => payload,
  },
  INITAL_STATE.isPlanMode,
)

const isPlanModeAllowedReducer = handleActions(
  {
    [setIsPlanModeAllowed]: (state, {payload}) => payload,
  },
  INITAL_STATE.isPlanModeAllowed,
)

export default combineReducers({
  selectedDate: selectedDateReducer,
  selectedDateLastSet: selectedDateLastSetReducer,
  taskInputValue: taskInputValueReducer,
  isPlanMode: isPlanModeReducer,
  isPlanModeAllowed: isPlanModeAllowedReducer,
})

/**
 * Selectors
 */

const YMD_PREFIXES = ['year-', 'month-', 'day-']

const getYearMonthDayArray = (date, isAddPrefix) => {
  const d = moment(date)
  const arr = [d.year(), d.month() + 1, d.date()]
  if (!isAddPrefix) return arr
  return arr.map((num, i) => YMD_PREFIXES[i] + num)
}

const getDayplan = (dayplans, date, fallback) => {
  const ymd = getYearMonthDayArray(date, true)
  return get(dayplans, ymd, fallback)
}

const selectedDateSelector = ({dayplanner}) => dayplanner.selectedDate
const getSelectedDateLastSet = ({dayplanner}) => dayplanner.selectedDateLastSet
const taskInputValueSelector = ({dayplanner}) => dayplanner.taskInputValue
const isPlanModeSelector = ({dayplanner}) => dayplanner.isPlanMode
const isPlanModeAllowedSelector = ({dayplanner}) => dayplanner.isPlanModeAllowed

export const getSelectedDate = createSelector(
  selectedDateSelector,
  todoTasksLastPlannedHashMapSelector,
  date => date,
)

export const getSelectedDateNum = createSelector(getSelectedDate, date =>
  date.format('YYMMDD'),
)

export const getSelectedDateYearMonthDate = createSelector(
  getSelectedDate,
  date => getYearMonthDayArray(date),
)

export const getSelectedDateYearMonthDateStr = createSelector(
  getSelectedDateYearMonthDate,
  nums => nums.map(num => num.toString()),
)

export const getSelectedDateDateDiff = createSelector(
  getCurrentTimeMinute,
  getSelectedDate,
  (currentTime, selectedDate) =>
    moment(selectedDate)
      .startOf('day')
      .diff(moment(currentTime).startOf('day'), 'days'),
)

export const getTaskInputValue = createSelector(
  taskInputValueSelector,
  value => value,
)

export const getIsPlanMode = createSelector(isPlanModeSelector, value => value)

export const getIsPlanModeAllowed = createSelector(
  isPlanModeAllowedSelector,
  value => value,
)

const dayplanSelector = createSelector(
  getDayplansByYearMonthDay,
  getSelectedDate,
  (plans, date) => getDayplan(plans, date, {}),
)

const dayplanPlannedTasksSelector = createSelector(
  dayplanSelector,
  ({plannedTasks}) => plannedTasks,
)
const dayplanPlannedTaskOrderSelector = createSelector(
  dayplanSelector,
  ({plannedTaskOrder}) => plannedTaskOrder,
)
const dayplanPostponedTasksSelector = createSelector(
  dayplanSelector,
  ({postponedTasks}) => postponedTasks,
)
const dayplanPostponedTaskOrderSelector = createSelector(
  dayplanSelector,
  ({postponedTaskOrder}) => postponedTaskOrder,
)

const autoPlannedTasksForDateSelector = createSelector(
  getSelectedDate,
  getMyGoalsEnrichedActiveTasks,
  dayplanPostponedTasksSelector,
  (date, goalTasks, postponedTasks) =>
    getAutoPlannedTasks({
      date,
      goalTasks,
      postponedTasks,
    }),
)

const activeTodoTasksSelector = createSelector(
  getSelectedDate,
  getCurrentTimeMinute,
  getMyTodoTasks,
  todoTasksLastPlannedHashMapSelector,
  dayplanPostponedTasksSelector,
  (date, currentTime, tasks, tasksLastPlannedById, postponedTasks) =>
    getActiveTodoTasks({
      date,
      currentTime,
      tasks,
      tasksLastPlannedById,
      postponedTasks,
    }),
)

const todoListItemsSelector = createSelector(
  getMyGoalsEnrichedActiveTasksById,
  getMyTodoTasksById,
  activeTodoTasksSelector,
  dayplanPlannedTasksSelector,
  dayplanPlannedTaskOrderSelector,
  autoPlannedTasksForDateSelector,
  (
    goalTasksById,
    todoTasksById,
    activeTodoTasks,
    plannedTasksById,
    plannedTaskOrder,
    autoPlannedTasks,
  ) =>
    compileTodoListItems({
      goalTasksById,
      todoTasksById,
      activeTodoTasks,
      plannedTasksById,
      plannedTaskOrder,
      autoPlannedTasks,
    }),
)

const todoListItemsJourneyEntriesSelector = createSelector(
  todoListItemsSelector,
  getSelectedDateNum,
  getMyJourneyEntriesByDate,
  (tasks, date, journeyEntriesByDate) =>
    includeJourneyEntriesInTasks({
      tasks,
      date,
      journeyEntriesByDate,
    }),
)

const todoListItemsCompletionSelector = createSelector(
  todoListItemsJourneyEntriesSelector,
  items =>
    items.map(item => ({
      ...item,
      isCompleted:
        !!item.completedAt || !!item.checkedInAt || !!item.journeyEntryId,
    })),
)

const todoListItemsSortedSelector = createSelector(
  todoListItemsCompletionSelector,
  getSelectedDate,
  (items, date) => sortTodoListItems({items, date}),
)

const activeTodoListItemsSelector = createSelector(
  todoListItemsSortedSelector,
  getSelectedDate,
  (items, date) =>
    items.filter(({completedAt}) =>
      completedAt ? moment(completedAt).isSame(date, 'day') : true,
    ),
)

const todoListItemsGoalDetailsSelector = createSelector(
  activeTodoListItemsSelector,
  getGoalsWithProgressById,
  (items, goalsById) =>
    items.map(item => {
      const {goalId} = item
      if (!goalId) return item
      const {title, organizationId} = goalsById[goalId]
      return {
        ...item,
        subtitle: title,
        organizationId,
      }
    }),
)

export const getTodoListItems = createSelector(
  todoListItemsGoalDetailsSelector,
  items => items,
)

export const getVisibleTodoListItems = createSelector(
  getTodoListItems,
  getIsPlanMode,
  (items, isPlanMode) =>
    isPlanMode ? items.filter(({isCompleted}) => !isCompleted) : items,
)

export const getTodoListItemsById = createSelector(getTodoListItems, items =>
  keyBy(items, 'id'),
)

export const getNumTodoListItems = createSelector(
  getTodoListItems,
  items => items.length,
)

export const getNumVisibleTodoListItems = createSelector(
  getVisibleTodoListItems,
  items => items.length,
)

export const getNumTodoListItemsCompleted = createSelector(
  getTodoListItems,
  items => items.filter(({isCompleted}) => isCompleted).length,
)

export const getTodoListItemIds = createSelector(getTodoListItems, items =>
  items ? items.map(({id}) => id) : [],
)

/**
 * Epics
 */

const navigateAndEnterPlanModeEpic = action$ =>
  action$.pipe(
    ofType(navigateAndEnterPlanMode().type),
    mergeMap(() =>
      of(redirectRoute('/achieve'), setSelectedDateToday()).pipe(
        concat(of(setIsPlanMode(true)).pipe(delay(200))),
      ),
    ),
  )

const toggleTaskCompletedEpic = (action$, state$) =>
  action$.pipe(
    ofType(toggleTaskCompleted().type),
    map(({payload}) => payload),
    mergeMap(taskId => {
      let loggedAt = undefined
      const state = state$.value
      const task = getTodoListItemsById(state)[taskId]
      const {goalId, journeyEntryId, isCompleted} = task
      const selectedDateDateDiff = getSelectedDateDateDiff(state)
      if (selectedDateDateDiff < 0) {
        const selectedDate = getSelectedDate(state)
        const now = moment()
        loggedAt = +moment(selectedDate)
          .hour(now.hour())
          .minute(now.minute())
          .second(now.second())
      }

      const organizationId = get(getGoalsWithProgressById(state), [
        goalId,
        'organizationId',
      ])

      return of(
        goalId
          ? FirebaseApi.toggleGoalJourneyEntry({
              organizationId,
              task,
              journeyEntryId,
              loggedAt,
            })
          : FirebaseApi.toggleTodoTaskCompleted({
              taskId,
              isCompleted,
              loggedAt,
            }),
      ).pipe(
        map(() =>
          toggleTaskCompletedFulfilled({
            type: goalId ? 'goal' : 'todo',
            taskId,
          }),
        ),
        catchError(err => of(toggleTaskCompletedRejected(err))),
      )
    }),
  )

const updateTodoListOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(updateTodoListOrder().type),
    map(({payload}) => payload),
    mergeMap(({oldIndex, newIndex}) => {
      const state = state$.value
      const currentOrder = getTodoListItemIds(state)
      const plannedTaskOrder = arrayMove(currentOrder, oldIndex, newIndex)
      const datePath = getSelectedDateYearMonthDate(state)
      const dayplans = [{plannedTaskOrder, datePath}]
      return FirebaseApi.updateUserDayplans({dayplans}).pipe(
        map(updateTodoListOrderFulfilled),
        catchError(err => of(updateTodoListOrderRejected(err))),
      )
    }),
  )

const postponeTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(postponeTask().type),
    map(({payload}) => payload),
    mergeMap(({taskId, postponeDate}) => {
      const state = state$.value
      const datePath = getSelectedDateYearMonthDate(state)
      const postponed = dayplanPostponedTaskOrderSelector(state) || []
      const selectedDayplan = {
        datePath,
        plannedTaskOrder: getTodoListItemIds(state).filter(id => id !== taskId),
        postponedTaskOrder: [...postponed, taskId],
      }

      const dayplans = [selectedDayplan]

      if (postponeDate) {
        const dayplansByYMD = getDayplansByYearMonthDay(state)
        const targetDayplan = getDayplan(dayplansByYMD, postponeDate)
        const targetPlanned = get(targetDayplan, 'plannedTaskOrder', [])
        dayplans.push({
          datePath: getYearMonthDayArray(postponeDate),
          plannedTaskOrder: [...targetPlanned, taskId],
        })
      }

      return FirebaseApi.updateUserDayplans({dayplans}).pipe(
        map(postponeTaskFulfilled),
        catchError(err => of(postponeTaskRejected(err))),
      )
    }),
  )

const addGoalStepToPlanEpic = (action$, state$) =>
  action$.pipe(
    ofType(addGoalStepToPlan().type),
    map(({payload: {id}}) => id),
    mergeMap(taskId => {
      const state = state$.value
      const plannedTaskOrder = [...getTodoListItemIds(state), taskId]
      const postponed = dayplanPostponedTaskOrderSelector(state) || []
      const postponedTaskOrder = postponed.filter(id => id !== taskId)
      const datePath = getSelectedDateYearMonthDate(state)
      const dayplans = [{postponedTaskOrder, plannedTaskOrder, datePath}]
      return FirebaseApi.updateUserDayplans({dayplans}).pipe(
        map(addGoalStepToPlanFulfilled),
        catchError(err => of(addGoalStepToPlanRejected(err))),
      )
    }),
  )

const incrementDecrementSelectedDateEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      incrementSelectedDate().type,
      decrementSelectedDate().type,
      setSelectedDateFromDatepicker().type,
    ),
    map(({type, payload}) => {
      const state = state$.value
      const isAuthenticatedUserAllowedPremiumContent = getIsAuthenticatedUserAllowedPremiumContent(
        state,
      )
      if (!isAuthenticatedUserAllowedPremiumContent) return showPaywall()
      if (type === setSelectedDateFromDatepicker().type)
        return setSelectedDate(payload)
      const selectedDate = getSelectedDate(state)
      const method = type === incrementSelectedDate().type ? 'add' : 'subtract'
      return setSelectedDate(moment(selectedDate)[method](1, 'day'))
    }),
  )

const isPlanModeAllowedEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      setSelectedDate().type,
      setSelectedDateToday().type,
      incrementSelectedDate().type,
      decrementSelectedDate().type,
    ),
    flatMap(() => {
      const state = state$.value
      const currentTime = getCurrentTimeMinute(state)
      const selectedDate = getSelectedDate(state)
      const isPlanModeAllowed = selectedDate.isSameOrAfter(currentTime, 'd')
      const wasPlanMode = getIsPlanMode(state)
      let isPlanMode = isPlanModeAllowed ? wasPlanMode : false
      if (isPlanMode !== wasPlanMode)
        return of(
          setIsPlanModeAllowed(isPlanModeAllowed),
          setIsPlanMode(isPlanMode),
        )
      return of(setIsPlanModeAllowed(isPlanModeAllowed))
    }),
  )

const togglePlanModeEpic = (action$, state$) =>
  action$.pipe(
    ofType(togglePlanMode().type),
    map(({payload}) => payload),
    map(forceIsPlanMode => {
      const state = state$.value
      const isPlanModeAllowed = getIsPlanModeAllowed(state)
      if (!isPlanModeAllowed) return togglePlanModeIllegal()
      if (forceIsPlanMode) return setIsPlanMode(forceIsPlanMode)
      const isPlanMode = getIsPlanMode(state)
      return setIsPlanMode(!isPlanMode)
    }),
  )

const createAndPlanTodoTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(createAndPlanTodoTask().type),
    map(({payload}) => payload),
    mergeMap(title => {
      const datePath = getSelectedDateYearMonthDate(state$.value)
      return FirebaseApi.createAndPlanTodoTask({title, datePath}).pipe(
        map(createAndPlanTodoTaskFulfilled),
        catchError(err => of(createAndPlanTodoTaskRejected(err))),
      )
    }),
  )

const deleteTodoTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(deleteTodoTask().type),
    map(({payload}) => payload),
    mergeMap(id => {
      const datePath = getSelectedDateYearMonthDate(state$.value)
      return FirebaseApi.deleteAndUnplanTodoTask({id, datePath}).pipe(
        map(deleteTodoTaskFulfilled),
        catchError(err => of(deleteTodoTaskRejected(err))),
      )
    }),
  )

const switchToNextDayEpic = (action$, state$) =>
  action$.pipe(
    ofType(tickMinuteHeartbeat().type),
    map(() => {
      const state = state$.value
      const isPlanMode = getIsPlanMode(state)
      if (isPlanMode) return tickNextDayNoop()
      const selectedDate = getSelectedDate(state)
      const currentTimeMinute = getCurrentTimeMinute(state)
      if (!currentTimeMinute.isSame(selectedDate, 'day')) {
        const selectedDateLastSet = getSelectedDateLastSet(state)
        const threshold = moment(selectedDateLastSet).add(5, 'minutes')
        if (currentTimeMinute.isAfter(threshold, 'minute'))
          return setSelectedDate(currentTimeMinute)
      }
      return tickNextDayNoop()
    }),
  )

export const dayplannerEpics = combineEpics(
  navigateAndEnterPlanModeEpic,
  toggleTaskCompletedEpic,
  updateTodoListOrderEpic,
  postponeTaskEpic,
  addGoalStepToPlanEpic,
  incrementDecrementSelectedDateEpic,
  isPlanModeAllowedEpic,
  togglePlanModeEpic,
  createAndPlanTodoTaskEpic,
  deleteTodoTaskEpic,
  switchToNextDayEpic,
)
