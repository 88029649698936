import isElectron from 'is-electron'

const Config = {
  version: process.env.REACT_APP_version,

  firebase: {
    enableLogging: false,

    config: {
      projectId: process.env.REACT_APP_firebaseProjectId,
      appId: process.env.REACT_APP_firebaseAppId,
      measurementId: process.env.REACT_APP_firebaseMeasurementId,
      apiKey: process.env.REACT_APP_firebaseApiKey,
      authDomain: process.env.REACT_APP_firebaseAuthDomain,
      databaseURL: process.env.REACT_APP_firebaseDatabaseURL,
      storageBucket: process.env.REACT_APP_firebaseStorageBucket,
      messagingSenderId: process.env.REACT_APP_firebaseMessagingSenderId,
    },

    authProviders: [
      {
        provider: 'GoogleAuthProvider',
        name: 'Google',
        icon: 'fa fa-google',
      },

      {
        provider: 'FacebookAuthProvider',
        name: 'Facebook',
        icon: 'fa fa-facebook-official',
      },
    ],
  },

  CLOUD_FUNCTIONS_ROOT: process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT,
  PULSE_API_ROOT: process.env.REACT_APP_PULSE_API_ROOT,

  SUPPORTED_LANGUAGES: {
    en: 'en',
    sv: 'sv',
    de: 'de',
    es: 'es',
  },
}

// hotfix(9/11-18): Facebook won't allow HTTP for oAuth redirects
if (isElectron()) Config.firebase.authProviders.splice(1, 1)

export default Config
