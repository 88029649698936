import React from 'react'
import {NavLink} from 'react-router-dom'

const MainMenuButton = ({icon, link, children, exact, onClick}) => (
  <li>
    <NavLink to={link} exact={exact} onClick={onClick}>
      {icon}
      {children}
    </NavLink>
  </li>
)

export default MainMenuButton
