import {connect} from 'react-redux'
import RouteResourceCourseLesson from './RouteResourceCourseLesson.js'
import {
  getCoursesById,
  getCourseContentByLessonId,
  subscribeCourseContent,
} from '../ducks/content'
import {
  getCourseUserProgress,
  markLessonRead,
  updateUserHistory,
} from '../ducks/userContent'

const mapStateToProps = (
  state,
  {
    match: {
      params: {language, courseId, lessonId},
    },
  },
) => ({
  language,
  courseId,
  lessonId,
  course: getCoursesById(state)[courseId],
  contentById: getCourseContentByLessonId(state)[lessonId],
  userReadLessons: getCourseUserProgress(state, {language, courseId}),
})

const mapDispatchToProps = dispatch => ({
  subscribeCourseContent: options => dispatch(subscribeCourseContent(options)),
  markLessonRead: options => dispatch(markLessonRead(options)),
  updateUserHistory: options => dispatch(updateUserHistory(options)),
})

class RouteResourceCourseLessonContainerClass extends RouteResourceCourseLesson {}

const RouteResourceCourseLessonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteResourceCourseLessonContainerClass)

export default RouteResourceCourseLessonContainer
