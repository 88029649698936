import React from 'react'

import IconChecked from 'material-ui/svg-icons/action/done'

import {Wrap, Day, DayLabel} from './WeekdayPicker.styles.js'

import {getFullWeekFormatted} from '../utils/date'

const WeekdayPicker = ({selectedDays = {}, onWeekdayToggle}) => {
  const weekdays = getFullWeekFormatted({})

  const renderDay = ({weekdayId, isoWeekday, dayNameShort}) => {
    const isSelected = selectedDays[weekdayId]
    return (
      <Day
        key={isoWeekday}
        onClick={() =>
          onWeekdayToggle({
            isoWeekday,
            weekdayId,
          })
        }
        isSelected={isSelected}
      >
        <DayLabel>{dayNameShort}</DayLabel>
        <IconChecked />
      </Day>
    )
  }

  return <Wrap>{weekdays.map(renderDay)}</Wrap>
}

export default WeekdayPicker
