import {combineReducers} from 'redux'
import {combineEpics} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'

import {FirebaseApi} from '../../api'
import {createEnsureAuthenticatedEpic} from '../../utils/epic'

const setDayplans = createAction('remente/app/setDayplans')

/**
 * Reducers
 */

const dayplansReducer = handleActions(
  {
    [setDayplans]: (state, {payload: {dayplans}}) => dayplans,
  },
  {},
)

export default combineReducers({
  dayplans: dayplansReducer,
})

/**
 * Selectors
 */

const dayplansSelector = ({dayplan}) => dayplan.dayplans
export const getDayplansByYearMonthDay = createSelector(
  dayplansSelector,
  dayplans => dayplans,
)

/**
 * Epics
 */

const setDayplansEpic = createEnsureAuthenticatedEpic({
  createObservables: ({uid}) => ({
    dayplans: FirebaseApi.observableRef(`user-dayplans2/${uid}`),
  }),
  actionFulfilled: setDayplans,
})

export const dayplanEpics = combineEpics(setDayplansEpic)
