import moment from 'moment'
import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {of} from 'rxjs'
import {map, mergeMap} from 'rxjs/operators'
import {createAction, handleAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {FirebaseApi} from '../../api'

import {setCurrentModal, closeModal} from '../app'
import {getPathnameParts} from '../route'
import {createGoal} from '../goal'
import {getGoalTemplatesById} from '../content'
import {cloneGoalTemplate, getGoalPlanDuration} from '../../utils/goalTemplate'

const INITAL_STATE = {
  selectedStartDate: null,
  goalTemplateId: null,
}

export const showModal = createAction(
  'remente/goal-template-details-modal/showModal',
)
export const setSelectedStartDate = createAction(
  'remente/goal-template-details-modal/setSelectedStartDate',
)
export const acceptGoalTemplate = createAction(
  'remente/goal-template-details-modal/acceptGoalTemplate',
)

/**
 * Reducers
 */

const selectedStartDateReducer = handleActions(
  {
    [showModal]: () => moment(),
    [setSelectedStartDate]: (state, {payload}) => moment(payload),
  },
  INITAL_STATE.selectedStartDate,
)

const goalTemplateIdReducer = handleAction(
  showModal,
  (state, {payload}) => payload,
  INITAL_STATE.goalTemplateId,
)

export default combineReducers({
  selectedStartDate: selectedStartDateReducer,
  goalTemplateId: goalTemplateIdReducer,
})

/**
 * Selectors
 */

const selectedStartDateSelector = ({goalTemplateDetailsModal}) =>
  goalTemplateDetailsModal.selectedStartDate
const goalTemplateIdSelector = ({goalTemplateDetailsModal}) =>
  goalTemplateDetailsModal.goalTemplateId

export const getSelectedStartDate = createSelector(
  selectedStartDateSelector,
  selectedStartDate => selectedStartDate,
)

export const getGoalTemplate = createSelector(
  getGoalTemplatesById,
  goalTemplateIdSelector,
  (templates, id) => templates[id],
)

export const getClonedGoalTemplate = createSelector(
  getGoalTemplate,
  selectedStartDateSelector,
  getPathnameParts,
  (goalTemplate, selectedStartDate, pathnameParts) => {
    if (!goalTemplate) return null
    let organizationId = undefined
    // TODO replace shitty router
    if (pathnameParts.length === 3) organizationId = pathnameParts[2]
    return {
      organizationId,
      ...cloneGoalTemplate({
        goalTemplate,
        taskIdGenerator: FirebaseApi.newIdGenerator(),
        reminderIdGenerator: FirebaseApi.newIdGenerator(),
        startAtDay: selectedStartDate,
        createdAt: FirebaseApi.TIMESTAMP,
      }),
    }
  },
)

export const getTemplatePlanDuration = createSelector(
  getGoalTemplate,
  goalTemplate => getGoalPlanDuration(goalTemplate),
)

export const getTemplatePlanDescription = createSelector(
  getGoalTemplate,
  plan => plan && plan.description,
)

/**
 * Epics
 */

export const showGoalTemplateDetailsModalEpic = action$ =>
  action$.pipe(
    ofType(showModal().type),
    map(() =>
      setCurrentModal({
        currentModal: 'goal-template/details',
      }),
    ),
  )

export const acceptGoalTemplateEpic = (action$, state$) =>
  action$.pipe(
    ofType(acceptGoalTemplate().type),
    mergeMap(() => {
      const goal = getClonedGoalTemplate(state$.value)
      return of(createGoal(goal), closeModal())
    }),
  )

export const goalTemplateDetailsModalEpics = combineEpics(
  showGoalTemplateDetailsModalEpic,
  acceptGoalTemplateEpic,
)
