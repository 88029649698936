import {connect} from 'react-redux'
import App from './App.js'

import {
  getIsAuthenticated,
  getHasAuthState,
  observeAuthStateChanged,
} from './ducks/auth'

import {getLanguage, focusApp, blurApp} from './ducks/app'

import {setIsElectron} from './ducks/electron'

import {startHeartbeat} from './ducks/timing'

import {getRouteRedirect} from './ducks/route'

const mapStateToProps = state => ({
  language: getLanguage(state),
  isAuthenticated: getIsAuthenticated(state),
  hasAuthState: getHasAuthState(state),
  redirectTo: getRouteRedirect(state),
})

const mapDispatchToProps = dispatch => ({
  onComponentWillMount: () => {
    dispatch(observeAuthStateChanged())
    dispatch(startHeartbeat())
    dispatch(setIsElectron(!!(window && window.process && window.process.type)))
  },
  onFocus: () => dispatch(focusApp()),
  onBlur: () => dispatch(blurApp()),
})

class AppContainerClass extends App {
  componentWillMount() {
    const {onComponentWillMount, onFocus, onBlur} = this.props
    onComponentWillMount && onComponentWillMount()
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
  }

  componentWillUnmount() {
    const {onFocus, onBlur} = this.props
    window.removeEventListener('focus', onFocus)
    window.removeEventListener('blur', onBlur)
  }
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainerClass)

export {AppContainer}
