import React, {Component} from 'react'
import {get} from 'lodash'
import {withTheme} from 'styled-components'
import {FormattedMessage} from 'react-intl'
import IconButton from 'material-ui/IconButton'
import Checkbox from 'material-ui/Checkbox'
import IconAddToPlan from 'material-ui/svg-icons/av/playlist-add'
import IconUnchecked from 'material-ui/svg-icons/navigation/check'
import IconChecked from 'material-ui/svg-icons/action/check-circle'

import Markdown from './Markdown.loadable.js'
import AssignedTaskProgress from './AssignedTaskProgressContainer.js'

import PieChart from './PieChart.js'
import iconChecklist from '../graphics/icon-checklist.svg'

import {
  Wrap,
  GoalTaskWrap,
  Title,
  Subtitle,
  IconBar,
  Description,
  Content,
  CircleWrap,
  CheckboxWrap,
  PieWrap,
  Toolbar,
} from './GoalTask.styles.js'

// intentionally "hairy" to support babel-react-intl extraction
const getTaskSubtitle = ({
  startDate,
  endDate,
  target,
  completedAt,
  organizationUsersById,
  numCheckinsTotal,
}) => {
  let parts = []

  if (numCheckinsTotal > 0)
    return (
      <FormattedMessage
        key="goal_task_subtitle_num_checkins"
        id="goal_task_subtitle_num_checkins"
        defaultMessage="You've done this {numCheckinsTotal, plural, one {one time} other {# times}}"
        values={{numCheckinsTotal}}
      />
    )

  if (completedAt)
    parts.push(
      <FormattedMessage
        key="goal_task_subtitle_completed_at"
        id="goal_task_subtitle_completed_at"
        defaultMessage="Completed {completedAt, date, medium}"
        values={{completedAt}}
      />,
    )

  // target
  if (target)
    parts.push(
      <FormattedMessage
        key="goal_task_subtitle_target_frequency"
        id="goal_task_subtitle_target_frequency"
        defaultMessage="{targetFrequency, plural, one {Once} other {# times}} / week"
        values={{targetFrequency: target.frequency}}
      />,
    )

  // dates
  if (startDate && endDate)
    parts.push(
      <FormattedMessage
        key="goal_task_subtitle_dates_start_and_end"
        id="goal_task_subtitle_dates_start_and_end"
        defaultMessage="{startDate, date, medium} - {endDate, date, medium}"
        values={{startDate, endDate}}
      />,
    )
  else if (startDate && target)
    parts.push(
      <FormattedMessage
        key="goal_task_subtitle_dates_start"
        id="goal_task_subtitle_dates_start"
        defaultMessage="Starting {startDate, date, medium}"
        values={{startDate}}
      />,
    )
  else if (endDate && target)
    parts.push(
      <FormattedMessage
        key="goal_task_subtitle_dates_end"
        id="goal_task_subtitle_dates_end"
        defaultMessage="Ending {endDate, date, medium}"
        values={{endDate}}
      />,
    )
  else if (startDate)
    parts.push(
      <FormattedMessage
        key="goal_task_subtitle_dates_start_single"
        id="goal_task_subtitle_dates_start_single"
        defaultMessage="{startDate, date, medium}"
        values={{startDate}}
      />,
    )

  if (parts.length) return parts.reduce((prev, curr) => [prev, ' • ', curr])

  return (
    <FormattedMessage
      id="goal_task_subtitle_anytime"
      defaultMessage="Anytime"
    />
  )
}

class GoalTaskClass extends Component {
  handleTitleClick = e => {
    const {isGoalCompleted, onClick} = this.props
    e.preventDefault()
    if (isGoalCompleted) return
    onClick()
  }

  render() {
    const {
      theme: {colorBodyFg, colorDisabled},
      id,
      goalId,
      title,
      description,
      linkedTo,
      checklists,
      isCompact,
      isSelected,
      isCompleted,
      isGoalCompleted,
      isTaskDescriptionVisible,
      percentCompleted,
      onClick,
      onCheckboxClick,
      onAddToPlanClick,
    } = this.props

    const subtitle = getTaskSubtitle({...this.props})

    const ToolbarButtons = onAddToPlanClick && (
      <IconButton onClick={() => onAddToPlanClick({id, goalId})}>
        <IconAddToPlan />
      </IconButton>
    )

    const checkboxIconStyle = {
      fill: isCompleted ? colorDisabled : colorBodyFg,
    }

    const slices = [
      {
        percent: percentCompleted,
        color: isCompact
          ? '#fff'
          : isCompleted || isGoalCompleted
          ? '#CFCFD8'
          : '#F3A63D',
      },
      {percent: 1 - percentCompleted, color: isCompact ? '#1d1e1f' : '#f4f4f6'},
    ]

    const TheCircle = !isCompact && (
      <CircleWrap>
        <PieWrap>
          <PieChart
            data={slices}
            strokeColor={isCompact ? '#1d1e1f' : '#fff'}
            innerFill={isCompact ? (isSelected ? '#fff' : '#242527') : '#fff'}
          />
        </PieWrap>
        <CheckboxWrap>
          <Checkbox
            disabled={!!onAddToPlanClick || isGoalCompleted || isCompleted}
            checked={isCompleted}
            onCheck={onCheckboxClick}
            checkedIcon={<IconChecked color="blue" />}
            uncheckedIcon={
              <IconUnchecked
                style={{fill: '#CFCFD8', transform: 'scale(.6)'}}
              />
            }
            iconStyle={checkboxIconStyle}
          />
        </CheckboxWrap>
      </CircleWrap>
    )

    const titleClickHandler = !isGoalCompleted &&
      onClick && {
        onClick: this.handleTitleClick,
      }

    const Desc = !isCompact &&
      ((isTaskDescriptionVisible && description) || linkedTo) && (
        <Description>
          {isTaskDescriptionVisible && description && (
            <Markdown>{description}</Markdown>
          )}
          {linkedTo && <AssignedTaskProgress linkedTo={linkedTo} />}
        </Description>
      )

    const IconChecklist = get(checklists, ['0', 'items'], []).length > 0 && (
      <IconBar>
        <img src={iconChecklist} alt="" />
      </IconBar>
    )

    return (
      <Wrap isCompact={isCompact}>
        <GoalTaskWrap
          isCompact={isCompact}
          isSelected={isSelected}
          isCompleted={isCompleted}
          isGoalCompleted={isGoalCompleted}
        >
          {TheCircle}

          <Content>
            <Title {...titleClickHandler}>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            {IconChecklist}
          </Content>

          <Toolbar>{ToolbarButtons}</Toolbar>
        </GoalTaskWrap>

        {Desc}
      </Wrap>
    )
  }
}

const GoalTask = withTheme(GoalTaskClass)

export default GoalTask
