import {createStore, applyMiddleware, compose} from 'redux'
import {createEpicMiddleware} from 'redux-observable'
import persistState from 'redux-localstorage'

import {rootReducer, rootEpic} from './reducer.js'

const epicMiddleware = createEpicMiddleware()

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = composeEnhancers(
  applyMiddleware(epicMiddleware),
  persistState(
    [
      'journeyEntry',
      'wheel',
      'wheelAssessment',
      'moodAssessment',
      'moodTag',
      'userSetting',
      'language',
      'dayplan',
      'exercise',
    ],
    {
      key: 'remente-redux-cache-19',
    },
  ),
)

const store = createStore(rootReducer, preloadedState, enhancer)

epicMiddleware.run(rootEpic)

export default store
