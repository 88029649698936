import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {get, values} from 'lodash'
import {of} from 'rxjs'
import {map, mergeMap, catchError, debounceTime} from 'rxjs/operators'

import {FirebaseApi} from '../../api'
import {arrayMove} from '../../utils/array'
import {compileGoalJourney} from '../../utils/journey'
import {sortGoalTasks} from '../../utils/goalTask'
import {getAuthenticatedUserId} from '../auth'
import {pathnamePartsChanged, redirectToGoals} from '../route'
import {
  getGoalsWithProgressById,
  getJourneyEntriesByGoalId,
} from '../goalProgress'
import {deleteGoal, setGoalCompleted, setGoalActive} from '../goal'
import {
  getUsersByOrganizationId,
  getUsersArrByOrganizationId,
} from '../organization'

const INITAL_STATE = {
  taskTitleInputValue: '',
  detailsGoalId: null,
  transactions: {},
  isEditModeActive: false,
  isEditingTitle: false,
}

export const setTitle = createAction('remente/goalDetails/setTitle')
const setTitleFulfilled = createAction('remente/goalDetails/setTitleFulfilled')
const setTitleRejected = createAction('remente/goalDetails/setTitleRejected')
export const setTaskTitleInputValue = createAction(
  'remente/goalDetails/setTaskTitleInputValue',
)
export const setCoverPhoto = createAction('remente/goalDetails/setCoverPhoto')
const setCoverPhotoFulfilled = createAction(
  'remente/goalDetails/setCoverPhotoFulfilled',
)
const setCoverPhotoRejected = createAction(
  'remente/goalDetails/setCoverPhotoRejected',
)
export const activateEditMode = createAction(
  'remente/goalDetails/activateEditMode',
)
export const deactivateEditMode = createAction(
  'remente/goalDetails/deactivateEditMode',
)
export const toggleEditMode = createAction('remente/goalDetails/toggleEditMode')
export const toggleEditTitle = createAction(
  'remente/goalDetails/toggleEditTitle',
)
export const addGoalTask = createAction('remente/goalDetails/addGoalTask')
const addGoalTaskFulfilled = createAction(
  'remente/goalDetails/addGoalTaskFulfilled',
)
const addGoalTaskRejected = createAction(
  'remente/goalDetails/addGoalTaskRejected',
)
export const changeTaskOrder = createAction(
  'remente/goalDetails/changeTaskOrder',
)
const changeTaskOrderFulfilled = createAction(
  'remente/goalDetails/changeTaskOrderFulfilled',
)
const changeTaskOrderRejected = createAction(
  'remente/goalDetails/changeTaskOrderRejected',
)
export const toggleTaskCompleted = createAction(
  'remente/goalDetails/toggleTaskCompleted',
)
const toggleTaskCompletedFulfilled = createAction(
  'remente/goalDetails/toggleTaskCompletedFulfilled',
)
const toggleTaskCompletedRejected = createAction(
  'remente/goalDetails/toggleTaskCompletedRejected',
)
export const deleteDetailsGoal = createAction(
  'remente/goalDetails/deleteDetailsGoal',
)
export const setDetailsGoalCompleted = createAction(
  'remente/goalDetails/setDetailsGoalCompleted',
)
export const setDetailsGoalActive = createAction(
  'remente/goalDetails/setDetailsGoalActive',
)

/**
 * Reducers
 */

const taskTitleInputValueReducer = handleActions(
  {
    [setTaskTitleInputValue]: (state, {payload}) => payload,
    [addGoalTask]: () => '',
  },
  INITAL_STATE.taskTitleInputValue,
)

const detailsGoalIdReducer = handleActions(
  {
    [pathnamePartsChanged]: (state, {payload}) => {
      if (payload.length !== 2 || payload[0] !== 'achieve') return state
      return payload[1]
    },
  },
  INITAL_STATE.detailsGoalId,
)

const transactionsReducer = handleActions(
  {
    [setCoverPhoto]: ({transactions}) => ({
      ...transactions,
      coverPhoto: {isPending: true, error: null},
    }),
    [setCoverPhotoFulfilled]: ({transactions}) => ({
      ...transactions,
      coverPhoto: {isPending: false, error: null},
    }),
    [setCoverPhotoRejected]: ({transactions}, {payload}) => ({
      ...transactions,
      coverPhoto: {isPending: false, error: payload},
    }),
  },
  INITAL_STATE.transactions,
)

const isEditModeActiveReducer = handleActions(
  {
    [activateEditMode]: () => true,
    [deactivateEditMode]: () => false,
    [setCoverPhoto]: () => false,
    [pathnamePartsChanged]: () => false,
    [toggleEditMode]: state => !state,
  },
  INITAL_STATE.isEditModeActive,
)

const isEditingTitleReducer = handleActions(
  {
    [toggleEditTitle]: (state, {payload}) =>
      payload !== undefined ? payload : !state,
    [setTitle]: () => false,
  },
  INITAL_STATE.isEditingTitle,
)

export default combineReducers({
  taskTitleInputValue: taskTitleInputValueReducer,
  detailsGoalId: detailsGoalIdReducer,
  transactions: transactionsReducer,
  isEditModeActive: isEditModeActiveReducer,
  isEditingTitle: isEditingTitleReducer,
})

/**
 * Selectors
 */

const taskTitleInputValueSelector = ({goalDetails}) =>
  goalDetails.taskTitleInputValue
const detailsGoalIdSelector = ({goalDetails}) => goalDetails.detailsGoalId
const transactionsSelector = ({goalDetails}) => goalDetails.transactions
const isEditModeActiveSelector = ({goalDetails}) => goalDetails.isEditModeActive
const isEditingTitleSelector = ({goalDetails}) => goalDetails.isEditingTitle

export const getDetailsGoalId = createSelector(
  detailsGoalIdSelector,
  goalId => goalId,
)

export const getIsCoverPhotoUploadPending = createSelector(
  transactionsSelector,
  ({coverPhoto}) => get(coverPhoto, 'isPending'),
)

export const getDetailsGoal = createSelector(
  getGoalsWithProgressById,
  getDetailsGoalId,
  (userGoalsById, detailsGoalId) => userGoalsById[detailsGoalId],
)

export const getDetailsGoalTitle = createSelector(getDetailsGoal, goal =>
  get(goal, 'title'),
)

export const getDetailsGoalIsCompleted = createSelector(
  getDetailsGoal,
  goal => !!goal.completedAt,
)

export const getDetailsGoalTasksById = createSelector(getDetailsGoal, goal =>
  get(goal, 'tasks', {}),
)

const detailsGoalTasksSelector = createSelector(
  getDetailsGoalTasksById,
  tasks => tasks,
)

const detailsGoalTaskOrderSelector = createSelector(getDetailsGoal, goal =>
  get(goal, 'taskOrder', []),
)

export const getDetailsGoalTasks = createSelector(
  detailsGoalTasksSelector,
  detailsGoalTaskOrderSelector,
  (tasks, manualSortOrder) =>
    sortGoalTasks({
      tasks,
      manualSortOrder,
    }).map(task => ({
      ...task,
      isCompleted: !!task.completedAt,
    })),
)

const detailsGoalTaskIds = createSelector(getDetailsGoalTasks, tasks =>
  tasks.map(({id}) => id),
)

export const getTaskTitleInputValue = createSelector(
  taskTitleInputValueSelector,
  taskTitleInputValue => taskTitleInputValue,
)

export const getCoverPhoto = createSelector(getDetailsGoal, goal =>
  get(goal, 'images.featured.servingUrl'),
)

const detailsGoalJourneyEntriesSelector = createSelector(
  getJourneyEntriesByGoalId,
  getDetailsGoalId,
  (journeyEntries, detailsGoalId) => get(journeyEntries, detailsGoalId),
)

export const getJourneyEntriesByDay = createSelector(
  getDetailsGoal,
  detailsGoalJourneyEntriesSelector,
  (goal, goalJourneyEntries) =>
    compileGoalJourney({
      goal,
      goalJourneyEntries,
    }),
)

export const getIsEditModeActive = createSelector(
  isEditModeActiveSelector,
  is => is,
)

export const getIsEditingTitle = createSelector(
  isEditingTitleSelector,
  is => is,
)

export const getGoalOrganizationUsersById = createSelector(
  getDetailsGoal,
  getUsersByOrganizationId,
  (goal, usersByOrganizationId) => {
    if (!goal) return {}
    return get(usersByOrganizationId, goal.organizationId, {})
  },
)

export const getGoalOrganizationUsers = createSelector(
  getDetailsGoal,
  getUsersArrByOrganizationId,
  (goal, usersByOrganizationId) => {
    if (!goal) return []
    return get(usersByOrganizationId, goal.organizationId, [])
  },
)

const getInitials = name => {
  if (!name) return
  const parts = name.split(' ')
  if (parts.length !== 2) return parts[0].substr(0, 2).toUpperCase()
  return `${parts[0].substr(0, 1)}${parts[1].substr(0, 1)}`.toUpperCase()
}

export const getUserProgress = (state, {linkedTo}) => {
  const organizationId = getDetailsGoal(state).organizationId
  const usersByOrganizationId = getUsersByOrganizationId(state)
  return values(linkedTo).map(
    ({userId, goalId, numTasks = 0, numTasksCompleted = 0, completedAt}) => {
      const displayName = get(usersByOrganizationId, [
        organizationId,
        userId,
        'name',
      ])
      const initials = getInitials(displayName)
      const percentCompleted = completedAt
        ? 1
        : numTasks === 0
        ? 0
        : numTasksCompleted / numTasks
      return {userId, initials, displayName, percentCompleted, completedAt}
    },
  )
}

/**
 * Epics
 */

const setGoalCoverPhotoEpic = (action$, state$) =>
  action$.pipe(
    ofType(setCoverPhoto().type),
    map(({payload}) => payload),
    mergeMap(file => {
      const state = state$.value
      const uid = getAuthenticatedUserId(state)
      const {id, organizationId} = getDetailsGoal(state)
      const rootStoragePath = `user-data/${organizationId || uid}/goals/${id}`
      const rootDbPath = `goals/${organizationId || uid}/${id}/images/featured`
      FirebaseApi.setGoalUpdatedAt({organizationId, id})
      return FirebaseApi.setImageRefObservable({
        file,
        rootStoragePath,
        rootDbPath,
      }).pipe(
        map(setCoverPhotoFulfilled),
        catchError(err => of(setCoverPhotoRejected(err))),
      )
    }),
  )

const setGoalTitleEpic = (action$, state$) =>
  action$.pipe(
    ofType(setTitle().type),
    map(({payload}) => payload),
    mergeMap(title => {
      const {id, organizationId} = getDetailsGoal(state$.value)
      return FirebaseApi.setGoalTitle({
        id,
        organizationId,
        title,
      }).pipe(
        map(setTitleFulfilled),
        catchError(err => of(setTitleRejected(err))),
      )
    }),
  )

const addGoalTaskEpic = (action$, state$) =>
  action$.pipe(
    ofType(addGoalTask().type),
    map(({payload}) => payload),
    mergeMap(taskTitle => {
      const state = state$.value
      const {id, organizationId} = getDetailsGoal(state)
      const title = taskTitle || getTaskTitleInputValue(state)
      return FirebaseApi.addGoalTask({
        organizationId,
        goalId: id,
        title,
      }).pipe(
        map(addGoalTaskFulfilled),
        catchError(err => of(addGoalTaskRejected(err))),
      )
    }),
  )

const changeTaskOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(changeTaskOrder().type),
    map(({payload}) => payload),
    mergeMap(({oldIndex, newIndex}) => {
      const state = state$.value
      const {id, organizationId} = getDetailsGoal(state)
      const currentOrder = detailsGoalTaskIds(state)
      const taskOrder = arrayMove(currentOrder, oldIndex, newIndex)
      return FirebaseApi.updateGoal({id, organizationId, taskOrder}).pipe(
        map(changeTaskOrderFulfilled),
        catchError(err => of(changeTaskOrderRejected(err))),
      )
    }),
  )

const toggleTaskCompletedEpic = (action$, state$) =>
  action$.pipe(
    ofType(toggleTaskCompleted().type),
    map(({payload}) => payload),
    mergeMap(id => {
      const state = state$.value
      const task = getDetailsGoalTasks(state).find(t => t.id === id)
      const {organizationId} = getDetailsGoal(state)
      return FirebaseApi.toggleGoalJourneyEntry({task, organizationId}).pipe(
        map(toggleTaskCompletedFulfilled),
        catchError(err => of(toggleTaskCompletedRejected(err))),
      )
    }),
  )

const deleteDetailsGoalEpic = (action$, state$) =>
  action$.pipe(
    ofType(deleteDetailsGoal().type),
    mergeMap(() => {
      const {id, organizationId} = getDetailsGoal(state$.value)
      return [redirectToGoals(), deleteGoal({id, organizationId})]
    }),
  )

const setDetailsGoalCompletedEpic = (action$, state$) =>
  action$.pipe(
    ofType(setDetailsGoalCompleted().type),
    debounceTime(400),
    map(() => {
      const {id, organizationId} = getDetailsGoal(state$.value)
      return setGoalCompleted({id, organizationId})
    }),
  )

const setDetailsGoalActiveEpic = (action$, state$) =>
  action$.pipe(
    ofType(setDetailsGoalActive().type),
    debounceTime(400),
    map(() => {
      const {id, organizationId} = getDetailsGoal(state$.value)
      return setGoalActive({id, organizationId})
    }),
  )

export const goalDetailsEpics = combineEpics(
  setGoalCoverPhotoEpic,
  setGoalTitleEpic,
  addGoalTaskEpic,
  changeTaskOrderEpic,
  toggleTaskCompletedEpic,
  deleteDetailsGoalEpic,
  setDetailsGoalCompletedEpic,
  setDetailsGoalActiveEpic,
)
