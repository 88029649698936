import React, {Component} from 'react'
import {FormattedMessage} from 'react-intl'

import TextValueButton from './TextValueButton.js'

// datepicker uses refs so this has to be a stateful component.
class DatePickerButtonInput extends Component {
  render() {
    const {onClick, value, placeholderText} = this.props
    const buttonLabel = value || placeholderText || (
      <FormattedMessage
        id="date_picker_placeholder"
        defaultMessage="Select Date"
      />
    )
    return <TextValueButton onClick={onClick} value={buttonLabel} />
  }
}

export default DatePickerButtonInput
