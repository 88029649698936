import styled from 'styled-components'
import Flipmove from 'react-flip-move'

export const List = styled(Flipmove)`
  margin-bottom: 1em;
`

export const DeleteButtonWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  svg {
    background: white;
  }
  transform: scale(0.8);
  opacity: 0;
  transition: all 150ms ease-out;
`

export const ReminderWrap = styled.div`
  position: relative;
  margin-left: 10px;
  margin-top: 0.8em;
  padding: 0.5em 0 0.5em 1em;
  border-left: 3px solid ${({theme}) => theme.colorPrimary1};
  &:hover {
    ${DeleteButtonWrap} {
      transform: scale(1);
      opacity: 1;
    }
  }
`
