import React from 'react'
import styled from 'styled-components'
import LoadingIndicator from './LoadingIndicator.js'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`

const LoadingIndicatorFull = ({pastDelay}) =>
  pastDelay ? (
    <Wrap>
      <LoadingIndicator />
    </Wrap>
  ) : null

export default LoadingIndicatorFull
