import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import SignUp from './SignUp.js'
import {registerUser} from '../ducks/auth'

const mapDispatchToProps = dispatch => ({
  registerUser: payload => {
    localStorage.authUserDidSignUp = true
    return dispatch(registerUser(payload))
  },
})

const SignUpContainer = connect(null, mapDispatchToProps)(SignUp)

export default withRouter(SignUpContainer)
