const getActiveTodoTasks = ({
  date,
  currentTime,
  tasks,
  tasksLastPlannedById,
  postponedTasks = {},
}) => {
  return tasks.filter(({id, checkedInAt}) => {
    if (checkedInAt) return date.isSame(checkedInAt, 'day')
    if (!tasksLastPlannedById || !tasksLastPlannedById[id]) return false

    const lastPlannedAt = tasksLastPlannedById[id]

    // (lastPlannedAt in the past OR same as selected date) AND (selected date is same OR before TODAY)
    // OR
    // selected date is after TODAY AND lastPlannedAt is selected date

    return (
      !postponedTasks[id] &&
      ((lastPlannedAt.isSameOrBefore(date, 'day') &&
        date.isSameOrBefore(currentTime, 'd')) ||
        (date.isAfter(currentTime, 'd') && lastPlannedAt.isSame(date, 'd')))
    )
  })
}

export {getActiveTodoTasks}
