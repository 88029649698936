import {max, get} from 'lodash'

const getGoalPlanDuration = goalTemplate => {
  if (!goalTemplate) return
  const tasks = get(goalTemplate, 'tasks', [])
  if (!tasks.length) return
  const dates = tasks.map(({endDate, startDate}) => endDate || startDate)
  return dates.indexOf(undefined) === -1 && max(dates) + 1
}

export {getGoalPlanDuration}
