import styled from 'styled-components'

export const Content = styled.div`
  text-align: center;
  padding: 0.3em 0.5em;
  transition: all 100ms ease-out;
  color: #7b7b91;
  opacity: 0.8;
  will-change: transform;
`

export const Label = styled.label`
  cursor: pointer;
  input {
    display: none;
    &:checked + ${Content} {
      color: ${({selectedColor}) => selectedColor};
      opacity: 1;
      transform: scale(1);
      border-radius: 30px;
      box-shadow: 0 2px 15px 0 rgba(50, 50, 95, 0.1),
        0 1px 4px 0 rgba(0, 0, 0, 0.08);
    }
  }
  &:hover {
    ${Content} {
      transform: scale(0.95);
    }
  }
`
