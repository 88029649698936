import {connect} from 'react-redux'
import {
  getExerciseById,
  getIsExerciseAccepted,
  fetchExercise,
  acceptExercise,
} from '../ducks/exercise'
import Exercise from './Exercise.js'

const mapStateToProps = (state, {id}) => ({
  exercise: getExerciseById(state, {id}),
  isExerciseAccepted: getIsExerciseAccepted(state, {id}),
})

const mapDispatchToProps = dispatch => ({
  onFetchExercise: id => dispatch(fetchExercise(id)),
  onAcceptButtonClick: exercise => dispatch(acceptExercise(exercise)),
})

const ExerciseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class ExerciseContainer extends Exercise {})

export default ExerciseContainer
