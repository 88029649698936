import {connect} from 'react-redux'
import PaywallModal from './PaywallModal.js'
import {validateReceipt, getIsValidatingReceipt} from '../ducks/electron'
import {getAuthenticatedUser} from '../ducks/auth'
import {closeModal} from '../ducks/app'

const mapStateToProps = state => ({
  authedUser: getAuthenticatedUser(state),
  isMacAppStoreBuild: process.mas,
  isValidatingReceipt: getIsValidatingReceipt(state),
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  onValidateReceipt: payload => dispatch(validateReceipt(payload)),
})

const PaywallModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaywallModal)

export default PaywallModalContainer
