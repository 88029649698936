import {combineReducers} from 'redux'
import {combineEpics} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'

import {FirebaseApi} from '../../api'
import {createEnsureAuthenticatedEpic} from '../../utils/epic'

const setMyOrganizationsById = createAction(
  'remente/pulse/setMyOrganizationsById',
)
const setMyOrganizationsByIdRejected = createAction(
  'remente/pulse/setMyOrganizationsByIdRejected',
)

/**
 * Reducers
 */

const myOrganizationsByIdReducer = handleActions(
  {
    [setMyOrganizationsById]: (state, {payload}) =>
      payload.pulseOrganizationsById,
  },
  {},
)

export default combineReducers({
  myOrganizationsById: myOrganizationsByIdReducer,
})

/**
 * Selectors
 */

export const getMyOrganizationsById = ({pulse}) =>
  pulse.myOrganizationsById || {}

export const getMyOrganizations = createSelector(
  getMyOrganizationsById,
  organizationsById => Object.keys(organizationsById),
)

export const getMyPulseOrganizationId = createSelector(
  getMyOrganizations,
  organizations => (organizations.length > 0 ? organizations[0] : null),
)

/**
 * Epics
 */

const pulseOrganizationsByUserEpic = createEnsureAuthenticatedEpic({
  createObservables: ({uid}) => ({
    pulseOrganizationsById: FirebaseApi.observableRef(
      `pulse/organizations-by-user/${uid}`,
    ),
  }),
  actionFulfilled: setMyOrganizationsById,
  actionRejected: setMyOrganizationsByIdRejected,
})

export const pulseEpics = combineEpics(pulseOrganizationsByUserEpic)
