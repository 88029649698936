export const ISO_WEEKDAYS = {
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
  7: 'sun',
}

export const GOAL_PLACEHOLDER_IMAGES = [
  'https://lh3.googleusercontent.com/vzo04UxLfRo38D-pC06c0UT_A9NkxDCK0MyPXncK4yCoTpEclCDF8OizQoaKYgtLvEHcUfo1Obn8PYuc7lsC',
  'https://lh3.googleusercontent.com/xioGJtzlE1GasKJgGxt2sYbredzFBjZdWvf8dHaN495a0p-d3dY3Vfz_FpzLywdtDnR50cCNpdcpbJ_g-gvsHw',
  'https://lh3.googleusercontent.com/6OyMAQfA_FcC2pwr5l444ZI_U7vRu963mboAPJFcu8WLAR4u7O6uFo3d19O1mza4MStNEJQb1ntPMj2YHiR7eWc',
  'https://lh3.googleusercontent.com/rrd1JW-kaUhqGwEV1twgBPQaThSkRy7D-GBi4_OG_c5haa2WdVoov0bzYZOmyXYsuw07UR5EvOOYvEc0EBbRGA',
  'https://lh3.googleusercontent.com/kZlxarY_fpx047pbxcpEU3TpiqQ5y3XU-VKjFTehS2TncWyKC8hVkXaGBrtf5jpLcqmJ2mifgHA2Aqb1ASlO',
  'https://lh3.googleusercontent.com/y5VadNQGFeMpp97eQFiUWCV9TX6bSFzmfDjJkn7j-BJWJhb9HVhkxt9LkMSA_dcKs9jGo7VCa3blw8IHTGYj6A',
  'https://lh3.googleusercontent.com/H_VZ66he6I-aO8WF_s3Gi1plDuMSSXpGfoUPMz2XSaH_XfGmZKomC0ivP7ohBpSla_kCOUd76mocc18a8U1NsQ',
  'https://lh3.googleusercontent.com/LSgnKh7NfzfRhqDi-_5Csw8TluRWY2nJYpNQWyPNQpYRCNT3OA6j8F9_Abr8Ag8brzh8dsO00GmqQGEZGCuJ5Q',
  'https://lh3.googleusercontent.com/Caw3_KiZYO13pEVErNsT72G2jKqLYJOM8Rk8VVa6DxuVWL-rSMqfWU5088eY4opS5unmcUn0SVR96PS-x2gCdw',
  'https://lh3.googleusercontent.com/60JmnLeVFWch5pNwoXLU47KrZaBqm86QOWKQ-NcMAZgJdIK2yK4vrf0oh95pSYd7uQ7O7yUY7pHLS8Bao3KeOg',
  'https://lh3.googleusercontent.com/T4sd2iEYHccakVupruZzGz1CctOGnBaRtvdBBZ54boHaNYDahdSYt6FcNxqSubQVp8UQAqgnKQyTkV5pv0kScg',
]

export const LIFE_WHEEL_ID = '-KRnSRNqjn-H4jC0kLQO'

export const SMILEY_COLORS = {
  1: '#FF93AD',
  2: '#D36DF6',
  3: '#6986BF',
  4: '#A4DBE8',
  5: '#70C9B7',
}
