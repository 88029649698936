const compileTodoListItems = ({
  goalTasksById,
  todoTasksById,
  activeTodoTasks,
  plannedTaskOrder = [],
  autoPlannedTasks,
}) => {
  const auto = autoPlannedTasks.concat(activeTodoTasks)

  if (plannedTaskOrder.length === 0) return auto

  const manuallyOrderedTasks = plannedTaskOrder
    .map(id => goalTasksById[id] || todoTasksById[id])
    .filter(task => task)
    .map((task, i) => ({
      ...task,
      manualSortIndex: i,
    }))

  const manuallyOrderedTaskIds = manuallyOrderedTasks.map(({id}) => id)

  return auto
    .filter(({id}) => manuallyOrderedTaskIds.indexOf(id) < 0)
    .concat(manuallyOrderedTasks)
}

export {compileTodoListItems}
