import {getMuiTheme} from 'material-ui/styles'

const REMENTE_RED = '#F48785'
const REMENTE_ORANGE = '#F3A63D'
const REMENTE_GRAY = '#515151'
const CLOUD = '#f4f4f6'
const CLOUD_DARK = '#7b7b93'
const WHITE = '#ffffff'
const BLACK = '#000000'
const DARK = '#242527'
const PRIMARY_1 = REMENTE_ORANGE
const PRIMARY_2 = REMENTE_RED
const YELLOW_BRIGHT = '#FFFCD2'
const YELLOW_DARK = '#908A4B'
const DISABLED = '#E0E0E4'
const SIDEBAR_BG = WHITE

const theme = {
  colorBodyFg: REMENTE_GRAY,
  colorBodyLinkFg: REMENTE_GRAY,
  colorBodySubtleFg: '#b3b6b9',
  colorBodyBg: WHITE,
  colorFaded: CLOUD,
  colorFadedDark: CLOUD_DARK,
  colorPrimary1: PRIMARY_1,
  colorPrimary2: PRIMARY_2,
  colorDark1: DARK,
  colorMaskedInputBg: YELLOW_BRIGHT,
  colorMaskedInputFg: YELLOW_DARK,
  colorDelete: REMENTE_RED,
  colorDiffPositive: '#7ADAC3',
  colorDiffNegative: REMENTE_RED,
  colorDisabled: DISABLED,

  // RouteAuthenticated
  colorMainMenuBg: REMENTE_ORANGE,
  colorMainMenuFg: WHITE,

  colorAppAccent: '#F9D29E',
  colorSidebarBg: SIDEBAR_BG,
  colorSidebarFg: REMENTE_GRAY,
  colorSidebarItemBg: SIDEBAR_BG,
  colorSidebarItemFg: REMENTE_GRAY,
  colorSidebarItemActiveBg: CLOUD,
  colorSidebarItemActiveFg: WHITE,
  colorSidebarItemSelectedBg: YELLOW_BRIGHT,
  colorSidebarItemSelectedFg: REMENTE_GRAY,

  colorPrimaryContentBg: WHITE,
  heightAppHeader: 80,

  // CoverPhotoHeader
  Journey: {
    c: {
      timeline: CLOUD,
      circleBg: CLOUD,
      circleBorder: '#ccc',
    },
  },

  Timeline: {
    c: {
      staticFg: '#c1c1c9',
      dayLabelFg: '#737379',
      verticalLine: '#cecece',
    },
  },

  // CoverPhotoHeader
  CoverPhotoHeader: {
    c: {
      photoBg: BLACK,
      photoFg: WHITE,
      loadingBg: CLOUD,
      loadingFg: REMENTE_GRAY,
    },
  },

  // GoalList
  GoalList: {
    c: {
      fg: WHITE,
      bg: DARK,
      itemFg: '#eee',
      itemBg: DARK,
      itemActiveFg: WHITE,
      itemActiveBg: '#353739',
      tnBorder: '#353739',
      tnActiveBorder: '#fff',
    },
  },

  // NextLesson
  NextLesson: {
    c: {
      fg: WHITE,
      bg: BLACK,
      hoverBg: DARK,
      iconFg: WHITE,
    },
  },

  // AuthForm
  colorAuthBg: CLOUD,
  colorAuthCardBg: WHITE,

  radiusSmall: 4,
}

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: PRIMARY_1,
    primary2Color: PRIMARY_2,
    primary3Color: REMENTE_RED,
    accent1Color: REMENTE_RED,
    accent2Color: WHITE,
    accent3Color: '#ff0000',
    textColor: '#515151',
    alternateTextColor: '#ffffff',
    canvasColor: '#ffffff',
    borderColor: '#eee',
    shadowColor: '#6A6A81',
    disabledColor: DISABLED,
  },
})

export {muiTheme}
export default theme
