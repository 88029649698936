import styled, {css} from 'styled-components'
import {truncate} from '../utils/css'

const Split = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1010;
  overflow-x: hidden;
  overflow-y: scroll;
`

export const ModalBox = styled.div`
  margin: 40px auto;
  width: 100%;
  max-width: ${({fullWidth}) => (fullWidth ? '1000px' : '700px')};
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: visible;
  border: 2px solid #fff;
  will-change: scroll-position;
  transform: translate3d(0, 0, 0);
`

export const ModalPadding = styled.div`
  padding: 50px;
`

export const ModalHeader = styled.div`
  ${Split}
  padding-bottom: 1em;
  h3 {
    margin: 0;
  }
`

export const ModalTitle = styled.h3`
  flex: 1;
  ${truncate()}
`

export const ModalSubtle = styled.div`
  opacity: 0.6;
  font-size: 0.8em;
  padding-left: 2em;
`

export const ModalFooter = styled.div`
  ${Split}
  align-items: flex-end;
  margin-top: 30px;
  button:first-child {
    margin-right: 0.5em !important;
  }
`
