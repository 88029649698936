import React from 'react'
import IconButton from 'material-ui/IconButton'
import IconAdd from 'material-ui/svg-icons/content/add'

import {
  Wrap,
  IconWrap,
  TextField,
  InputOverlay,
  TextFieldWrap,
} from './AddListItemInput.styles.js'

const AddListItemInput = ({
  iconProps,
  buttonProps,
  inputOverlay,
  disabled,
  ...rest
}) => {
  const iconColor = rest.value && rest.value.length > 0 ? '#F3A63D' : '#515151'

  const handleSubmit = e => {
    e.preventDefault()
    if (rest.onSubmit) rest.onSubmit(rest.value)
  }

  return (
    <Wrap onSubmit={handleSubmit}>
      <IconWrap>
        <IconButton disabled={disabled} {...buttonProps} type="submit">
          <IconAdd color={iconColor} {...iconProps} />
        </IconButton>
      </IconWrap>

      <TextFieldWrap>
        <TextField disabled={disabled} {...rest} />

        <InputOverlay>{inputOverlay}</InputOverlay>
      </TextFieldWrap>
    </Wrap>
  )
}

export default AddListItemInput
