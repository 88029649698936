import styled from 'styled-components'
import {truncate} from '../utils/css'

export const Content = styled.div`
  position: relative;
  border: none;
  background: none;
  text-align: left;
  flex: 1;
  small {
    opacity: 0.5;
  }
  padding: 10px;
  padding-left: 13px;
  border-radius: 4px;
  width: 0;
  ${({onClick, theme}) =>
    onClick &&
    `
    &:hover,
    &:focus {
      cursor: pointer;
      background: ${theme.colorMaskedInputBg};
    }
  `}
`

export const CircleWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`

export const PieWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 30px;
  z-index: 1;
`

export const CheckboxWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 24px;
  height: 24px;
  z-index: 2;
`

export const Toolbar = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  font-weight: bold;
  ${({onClick}) =>
    onClick
      ? `
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `
      : ''}
`

export const Subtitle = styled.div`
  ${truncate()}
  font-size: .8em;
  margin-top: 0.4em;
  color: #c1c1c9;
`

export const IconBar = styled.div`
  margin-top: 0.4em;
  img {
    width: 12px;
    height: 12px;
    opacity: 0.4;
  }
`

export const Wrap = styled.div`
  ${({isCompact, theme: {colorSidebarItemBg}}) =>
    isCompact
      ? `
    background: ${colorSidebarItemBg};
  `
      : `
    background: #fff;
  `}
`

export const GoalTaskWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 19px;
  padding: 0 9px;
  border-radius: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  transition: transform 80ms ease-out;
  ${({isCompact, theme}) =>
    isCompact &&
    `
    margin: 0 1em;
    padding: 0 0 0 .5em;
    font-size: .8em;
    background: none;
    svg {
      fill: ${theme.colorSidebarFg} !important;
    }
  `}
  ${({isSelected, theme}) =>
    isSelected &&
    `
    opacity: 1 !important;
    transform: scale(1) !important;
    background: ${theme.colorSidebarItemSelectedBg};
    svg {
      fill: ${theme.colorSidebarItemSelectedFg} !important;
    }
  `}
  ${PieWrap} {
    ${({isCompact}) =>
      isCompact &&
      `
      background: transparent;
    `}
  }

  ${Content} {
    ${({isCompact, theme}) =>
      isCompact &&
      `
      margin-left: 0;
      color: ${theme.colorSidebarFg};
    `}
    ${({isSelected, theme}) =>
      isSelected &&
      `
      color: ${theme.colorSidebarItemSelectedFg};
    `}
    ${({isCompleted, theme: {colorDisabled}}) =>
      isCompleted &&
      `
      color: ${colorDisabled};
    `}
  }

  ${Title} {
    ${({isCompleted}) =>
      isCompleted &&
      `
      text-decoration: line-through;
    `}
    ${({isGoalCompleted}) =>
      isGoalCompleted &&
      `
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    `}
    ${({isCompact}) =>
      isCompact &&
      `
      ${truncate()}
    `}
  }

  &:hover {
    ${Toolbar} {
      display: flex;
    }
  }
`

export const Description = styled.div`
  > div {
    max-width: 600px;
  }
  font-size: 0.8em;
  margin: -16px 40px 0 42px;
  padding: 1em 1em 0.5em 27px;
  border-left: 2px solid #f4f4f6;
  border-radius: 0 0 0 8px;
  line-height: 1.5em;
`
