import styled from 'styled-components'

export const Wrap = styled.div`
  position: relative;
  padding-bottom: 5vw;
  min-height: 250px;
  max-height: 300px;
  transition: all 0.6s ease-out;
  overflow: hidden;
  ${({loading, theme}) =>
    loading
      ? `background: ${theme.CoverPhotoHeader.c.loadingBg};`
      : `background: ${theme.CoverPhotoHeader.c.photoBg};`}
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  padding: 0 16px;
  text-align: center;
  h2 {
    margin: 0;
    color: ${({theme}) => theme.CoverPhotoHeader.c.photoFg};
    small {
      font-size: 0.55em;
      font-weight: 300;
      opacity: 1;
    }
  }
`

export const Hero = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
  opacity: 0.3;
`
