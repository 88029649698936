import {combineEpics, ofType} from 'redux-observable'
import {createAction} from 'redux-actions'
import {createSelector} from 'reselect'
import {of} from 'rxjs'
import {map, mergeMap, catchError} from 'rxjs/operators'
import {values} from 'lodash'
import {FirebaseApi} from '../../api'
import getTodoTasksLastPlannedById from '../../utils/dayplanner/getTodoTasksLastPlannedById.js'

import {getMyTodoTasksById} from '../userContent'

import {getDayplansByYearMonthDay} from '../dayplan'

export const createTodoTask = createAction('remente/dayplan/createTodoTask')
export const createTodoTaskFulfilled = createAction(
  'remente/dayplan/createTodoTaskFulfilled',
)
export const createTodoTaskRejected = createAction(
  'remente/dayplan/createTodoTaskRejected',
)
export const deleteTodoTask = createAction('remente/dayplan/deleteTodoTask')
export const deleteTodoTaskFulfilled = createAction(
  'remente/dayplan/deleteTodoTaskFulfilled',
)
export const deleteTodoTaskRejected = createAction(
  'remente/dayplan/deleteTodoTaskRejected',
)
export const setTodoTaskTitle = createAction('remente/dayplan/setTodoTaskTitle')
export const setTodoTaskTitleFulfilled = createAction(
  'remente/dayplan/setTodoTaskTitleFulfilled',
)
export const setTodoTaskTitleRejected = createAction(
  'remente/dayplan/setTodoTaskTitleRejected',
)

/**
 * Selectors
 */

export const getMyTodoTasks = createSelector(getMyTodoTasksById, tasks =>
  values(tasks).filter(
    // https://github.com/remente/remente-docs/blob/master/app/specs/dayplanner.md#visibility-of-tasks
    ({createdAt}) => createdAt > 1494343870000,
  ),
)

export const getMyTodoTasksActive = createSelector(getMyTodoTasks, tasks =>
  tasks.filter(({checkedInAt}) => !checkedInAt),
)

export const todoTasksLastPlannedHashMapSelector = createSelector(
  getMyTodoTasksById,
  getDayplansByYearMonthDay,
  getTodoTasksLastPlannedById,
)

/**
 * Epics
 */

export const createTodoTaskEpic = action$ =>
  action$.pipe(
    ofType(createTodoTask().type),
    map(({payload}) => payload),
    mergeMap(title => {
      return FirebaseApi.createTodoTask({title}).pipe(
        map(createTodoTaskFulfilled),
        catchError(err => of(createTodoTaskRejected(err))),
      )
    }),
  )

export const deleteTodoTaskEpic = action$ =>
  action$.pipe(
    ofType(deleteTodoTask().type),
    map(({payload}) => payload),
    mergeMap(id => {
      return FirebaseApi.deleteTodoTask({id}).pipe(
        map(deleteTodoTaskFulfilled),
        catchError(err => of(deleteTodoTaskRejected(err))),
      )
    }),
  )

export const setTodoTaskTitleEpic = action$ =>
  action$.pipe(
    ofType(setTodoTaskTitle().type),
    map(({payload}) => payload),
    mergeMap(({organizationId, goalId, id, title}) => {
      const method = goalId ? 'setGoalTaskTitle' : 'setTodoTaskTitle'
      return FirebaseApi[method]({organizationId, goalId, id, title}).pipe(
        map(setTodoTaskTitleFulfilled),
        catchError(err => of(setTodoTaskTitleRejected)),
      )
    }),
  )

export const todoTaskEpics = combineEpics(
  createTodoTaskEpic,
  deleteTodoTaskEpic,
  setTodoTaskTitleEpic,
)
