import styled from 'styled-components'

export const MoodAssessmentDetails = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 400ms ease-out;
  transition-delay: 900ms;
  transform: scale(0.9);
  will-change: transform;
  ${({isVisible}) =>
    isVisible
      ? `
    opacity: 1;
    max-height: 800px;
    transform: scale(1);
  `
      : ''}
`
