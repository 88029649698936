import {connect} from 'react-redux'

import EditJourneyEntryModal from './EditJourneyEntryModal.js'

import {closeModal} from '../ducks/app'

import {getAuthenticatedUserId} from '../ducks/auth'

import {
  getEditingJourneyEntryId,
  getEditingJourneyEntryGoalId,
  getEditingJourneyEntryNotes,
  getEditingJourneyEntryTaskTitle,
  getEditingJourneyEntryLoggedAt,
  getEditingJourneyEntryOrganizationId,
  updateJourneyEntry,
} from '../ducks/journeyEntry'

const mapStateToProps = state => ({
  uid: getAuthenticatedUserId(state),
  id: getEditingJourneyEntryId(state),
  goalId: getEditingJourneyEntryGoalId(state),
  notes: getEditingJourneyEntryNotes(state),
  taskTitle: getEditingJourneyEntryTaskTitle(state),
  loggedAt: getEditingJourneyEntryLoggedAt(state),
  organizationId: getEditingJourneyEntryOrganizationId(state),
})

const mapDispatchToProps = dispatch => ({
  onDone: payload => dispatch(updateJourneyEntry(payload)),
  onCancel: () => dispatch(closeModal()),
})

class EditJourneyEntryModalContainerClass extends EditJourneyEntryModal {}

const EditJourneyEntryModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditJourneyEntryModalContainerClass)

export default EditJourneyEntryModalContainer
