import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {get} from 'lodash'
import {of} from 'rxjs'
import {map, mergeMap, catchError} from 'rxjs/operators'

import config from '../../config'
import {FirebaseApi} from '../../api'
import {createEnsureAuthenticatedEpic} from '../../utils/epic'

import {setLanguage as setClientLanguage} from '../app'

const DEFAULT_LANGUAGE = 'en'

export const setSettings = createAction('remente/userSetting/setSettings')
export const setLanguage = createAction('remente/userSetting/setLanguage')
export const setLanguageFulfilled = createAction(
  'remente/userSetting/setLanguageFulfilled',
)
export const setLanguageRejected = createAction(
  'remente/userSetting/setLanguageRejected',
)

/**
 * Reducers
 */

const settingsReducer = handleActions(
  {
    [setSettings]: (state, {payload: {settings}}) => settings,
  },
  null,
)

export default combineReducers({
  settings: settingsReducer,
})

/**
 * Selectors
 */

const settingsSelector = ({userSetting}) => userSetting.settings
export const getSettings = createSelector(settingsSelector, value => value)
export const getLanguageSettings = createSelector(getSettings, settings =>
  get(settings, 'language'),
)

/**
 * Epics
 */

const settingsEpic = createEnsureAuthenticatedEpic({
  createObservables: ({uid}) => ({
    settings: FirebaseApi.observableRef(`user-settings/${uid}`),
  }),
  actionFulfilled: setSettings,
})

const setClientLanguageEpic = (action$, state$) =>
  action$.pipe(
    ofType(setSettings().type),
    map(() => {
      const language = getLanguageSettings(state$.value) || DEFAULT_LANGUAGE
      const isLanguageSupported = config.SUPPORTED_LANGUAGES[language]
      return setClientLanguage(
        isLanguageSupported ? language : DEFAULT_LANGUAGE,
      )
    }),
  )

const setPreferredLanguageEpic = action$ =>
  action$.pipe(
    ofType(setLanguage().type),
    map(({payload}) => payload),
    mergeMap(language =>
      FirebaseApi.setPreferredLanguage({language}).pipe(
        map(setLanguageFulfilled),
        catchError(err => of(setLanguageRejected(err))),
      ),
    ),
  )

export const userSettingEpics = combineEpics(
  settingsEpic,
  setClientLanguageEpic,
  setPreferredLanguageEpic,
)
