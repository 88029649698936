import React, {Component} from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import ChecklistItem from './ChecklistItem.js'
import ChecklistItemAdd from './ChecklistItemAdd.js'
import styles from './Checklist.module.sass'

const SortableChecklistItem = SortableElement(item => (
  <ChecklistItem {...item} />
))

const SortableChecklist = SortableContainer(({children}) => (
  <ul className={styles.checklist}>{children}</ul>
))

class Checklist extends Component {
  state = {
    editingItemId: null,
  }

  setEditingItemId(editingItemId) {
    this.setState({
      ...this.state,
      editingItemId,
    })
  }

  handleTitleChanged(payload) {
    const {onTitleChanged} = this.props
    if (onTitleChanged) onTitleChanged(payload)
    this.setEditingItemId(null)
  }

  handleCheck(payload) {
    const {onCheck} = this.props
    if (onCheck) onCheck(payload)
  }

  handleDelete(payload) {
    const {onDelete} = this.props
    if (onDelete) onDelete(payload)
  }

  handleAdd(title) {
    const {onAdd} = this.props
    if (onAdd) onAdd(title)
  }

  handleSortEnd(res) {
    const {onSortEnd} = this.props
    if (onSortEnd) onSortEnd(res)
  }

  render() {
    const {items} = this.props
    const {isDragging, editingItemId} = this.state

    return (
      <React.Fragment>
        <SortableChecklist
          helperClass="dnd-dragging"
          isDragging={isDragging}
          lockAxis="y"
          lockToContainerEdges={true}
          useDragHandle={false}
          pressDelay={200}
          onSortStart={() => this.setState({isDragging: true})}
          onSortEnd={this.handleSortEnd.bind(this)}
        >
          {items.map((item, i) => (
            <SortableChecklistItem
              key={item.id}
              index={i}
              title={item.title}
              checkedAt={item.checkedAt}
              state={item.id === editingItemId ? 'editing' : 'idle'}
              onToggleEditTitle={isEditing =>
                isEditing === false
                  ? this.setEditingItemId(null)
                  : this.setEditingItemId(item.id)
              }
              onTitleChanged={newTitle =>
                this.handleTitleChanged({i, item, newTitle})
              }
              onCheck={() => this.handleCheck({i, item})}
              onDelete={() => this.handleDelete({i, item})}
            />
          ))}
        </SortableChecklist>
        <div className={styles.add}>
          <ChecklistItemAdd onSubmit={title => this.handleAdd(title)} />
        </div>
      </React.Fragment>
    )
  }
}

export default Checklist
