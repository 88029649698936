import {combineEpics, ofType} from 'redux-observable'
import {createAction} from 'redux-actions'
import {of} from 'rxjs'
import {map, concat, mergeMap, catchError} from 'rxjs/operators'
import {FirebaseApi} from '../../api'
import {setCurrentModal, closeModal} from '../app'

export const showCreateMoodAssessmentModal = createAction(
  'remente/editMoodAssessmentModal/showCreateMoodAssessmentModal',
)
export const updateMoodAssessment = createAction(
  'remente/editMoodAssessmentModal/updateMoodAssessment',
)
const updateMoodAssessmentFulfilled = createAction(
  'remente/editMoodAssessmentModal/updateMoodAssessmentFulfilled',
)
const updateMoodAssessmentRejected = createAction(
  'remente/editMoodAssessmentModal/updateMoodAssessmentRejected',
)

/**
 * Epics
 */

const showCreateMoodAssessmentModalEpic = action$ =>
  action$.pipe(
    ofType(showCreateMoodAssessmentModal().type),
    map(() => setCurrentModal({currentModal: 'mood-assessment/edit'})),
  )

const updateMoodAssessmentEpic = action$ =>
  action$.pipe(
    ofType(updateMoodAssessment().type),
    map(({payload}) => payload),
    mergeMap(assessment =>
      of(closeModal()).pipe(
        concat(
          FirebaseApi.createOrUpdateMoodAssessment(assessment).pipe(
            map(updateMoodAssessmentFulfilled),
            catchError(err => of(updateMoodAssessmentRejected(err))),
          ),
        ),
      ),
    ),
  )

export const editMoodAssessmentModalEpics = combineEpics(
  showCreateMoodAssessmentModalEpic,
  updateMoodAssessmentEpic,
)
