import moment from 'moment'
import {values, groupBy, get, sum} from 'lodash'
import {getTaskType} from '../goalTask'

const TASK_TYPE_SINGLE = 'single'

export function attachJourneyEntries({
  goals = [],
  journeyEntriesByGoalId = {},
}) {
  return goals.map(goal => {
    const userJourneyEntries = values(journeyEntriesByGoalId[goal.id])
    const journeyEntriesByTaskId = groupBy(userJourneyEntries, 'taskId')

    const tasksById = goal.tasks

    const tasks = values(tasksById).map(task => {
      const type = getTaskType(task)
      const journeyEntries = get(journeyEntriesByTaskId, task.id, [])
      const numCheckinsMax = getTaskNumCheckinsMax(type, task)
      const numCheckinsTotal = journeyEntries.length
      const percentCompleted = getTaskProgress(
        task,
        numCheckinsTotal,
        numCheckinsMax,
      )
      const isCompleted = task.completedAt !== undefined

      return {
        ...task,
        goalId: goal.id,
        type,
        journeyEntries,
        numCheckinsMax,
        numCheckinsTotal,
        percentCompleted,
        isCompleted,
      }
    })

    const isGoalCompleted = goal.completedAt !== undefined
    const percentCompleted = getGoalPercentCompleted(isGoalCompleted, tasks)

    return {
      ...goal,
      tasksById,
      tasks,
      percentCompleted,
      isCompleted: isGoalCompleted,
    }
  })
}

function getTaskProgress(task, numCheckinsTotal, numCheckinsMax) {
  if (!task.linkedTo)
    return Math.min(1, numCheckinsMax ? numCheckinsTotal / numCheckinsMax : 0)
  let total = 0
  if (task.linkedTo.completedAt) return 1
  const links = values(task.linkedTo)
  links.forEach(link => {
    if (link.numTasks) total += link.numTasksCompleted / link.numTasks
  })
  return total / links.length
}

function getGoalPercentCompleted(isGoalCompleted, tasks) {
  if (isGoalCompleted) return 1
  if (tasks.length === 0) return 0
  const acc = sum(
    tasks.map(({isCompleted, percentCompleted}) =>
      isCompleted ? 1 : percentCompleted,
    ),
  )
  return Math.min(1, acc / tasks.length)
}

function getTaskNumCheckinsMax(type, {createdAt, startDate, endDate, target}) {
  if (type === TASK_TYPE_SINGLE) return 1
  if (!endDate) return null
  const start = moment(startDate || createdAt).startOf('week')
  const end = moment(endDate).endOf('week')
  const numWeeks = end.diff(start, 'weeks') + 1
  const targetFrequency = get(target, 'frequency', 1)
  return numWeeks * targetFrequency
}
