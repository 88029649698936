import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {historyChanged} from '../ducks/route'

const mapDispatchToProps = dispatch => ({
  onHistoryChanged: (location, action) =>
    dispatch(
      historyChanged({
        location,
        action,
      }),
    ),
})

class RouterReduxClass extends Component {
  componentWillMount() {
    const {history, onHistoryChanged} = this.props
    history.listen(onHistoryChanged)
    onHistoryChanged(history.location)
  }

  render() {
    const {children} = this.props

    return <div>{children}</div>
  }
}

const RouterRedux = withRouter(
  connect(null, mapDispatchToProps)(RouterReduxClass),
)

export default RouterRedux
