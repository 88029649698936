import React, {Fragment} from 'react'
import {Route} from 'react-router-dom'
import LoadableRouteResourceIndex from './RouteResourceIndex.loadable.js'
import RouteResourceCourseContainer from './RouteResourceCourseContainer.js'
import RouteResourceCourseLessonContainer from './RouteResourceCourseLessonContainer.js'
import RouteResourceBoostContainer from './RouteResourceBoostContainer.js'

const RouteResource = () => {
  return (
    <Fragment>
      <Route exact path="/resources" component={LoadableRouteResourceIndex} />

      <Route
        exact
        path="/resources/courses/:language/:courseId"
        component={RouteResourceCourseContainer}
      />

      <Route
        exact
        path="/resources/courses/:language/:courseId/:lessonId"
        component={RouteResourceCourseLessonContainer}
      />

      <Route
        exact
        path="/resources/boosts/:language/:boostId"
        component={RouteResourceBoostContainer}
      />
    </Fragment>
  )
}

export default RouteResource
