import React, {Component, Fragment} from 'react'
import {FormattedMessage} from 'react-intl'
import CircularProgress from 'material-ui/CircularProgress'
import {get, keyBy} from 'lodash'
import {FirebaseApi} from '../api'

import Modal, {ModalPadding} from './Modal.js'

import imgBetterRemente from '../graphics/paywall/better-remente.svg'
import imgExport from '../graphics/paywall/export.svg'
import imgGrow from '../graphics/paywall/grow-learn-apply.svg'
import imgLookBack from '../graphics/paywall/look-back-plan-ahead.svg'
import imgUnderstandYourself from '../graphics/paywall/understand-yourself.svg'
import imgPin from '../graphics/paywall/pincode.svg'

import {
  MainTitle,
  Feature,
  Features,
  FeatureTitle,
  FeatureImage,
  FeatureDescription,
  Plans,
  Plan,
  PlanPrice,
  PlanDuration,
  PlanHighlight,
  FinePrint,
  FeaturesHeader,
  BackgroundFill,
  SlideDown,
} from './PaywallModal.styles.js'

const features = [
  {
    title: 'Understand Yourself',
    image: imgUnderstandYourself,
    description:
      'See complete statistics to gain new insights about how your mood works and what affects your emotions.',
  },
  {
    title: 'Grow, Learn & Apply',
    image: imgGrow,
    description:
      'Access more than 80 Courses and over 150 Exercises for a richer life that helps you grow as a person.',
  },
  {
    title: 'Look Back, Plan Ahead',
    image: imgLookBack,
    description:
      'Plan upcoming days in detail to keep focus over time. Reflect and learn from your past.',
  },
  {
    title: 'Export Your Data',
    image: imgExport,
    description:
      'Export data from the app as a JSON or CSV file for further analysis and quantification.',
  },
  {
    title: 'Pincode Protection',
    image: imgPin,
    description:
      'Add an extra layer of protection by requiring a pin code when opening the app. Only available on mobile.',
  },
  {
    title: 'A Better Remente',
    image: imgBetterRemente,
    description:
      'With your support, we can improve the service and help more people around the world. Thank you!',
  },
]

class PaywallModal extends Component {
  state = {
    offers: [],
    products: [],
    receiptValidationError: null,
    isPurchasing: false,
    isReceiptSentForValidation: false,
  }

  componentDidMount() {
    const {isMacAppStoreBuild} = this.props

    FirebaseApi.getPaywallOffers({isMacAppStoreBuild}).then(offers => {
      this.setState({
        ...this.state,
        offers,
      })

      if (!isMacAppStoreBuild) return

      this.pendingReceiptsRef = FirebaseApi.getCurrentUsersPendingReceiptsRef()
      this.pendingReceiptsRef.on('value', this.handlePendingReceiptsValue)

      const productIds = offers.map(({id}) => id)
      this.fetchMacAppStoreProducts(productIds)
    })
  }

  componentWillUnmount() {
    const {isMacAppStoreBuild} = this.props
    if (!isMacAppStoreBuild) return
    this.pendingReceiptsRef.off('value', this.handlePendingReceiptsValue)
  }

  handlePendingReceiptsValue = snap => {
    const val = snap.val()
    this.setState({
      ...this.state,
      receiptValidationError: val && val.error,
      isValidatingReceipt: val && !val.error,
    })
  }

  fetchMacAppStoreProducts = productIds => {
    const {onValidateReceipt} = this.props
    const {inAppPurchase} = window.require('electron').remote

    inAppPurchase.on('transactions-updated', (event, transactions) => {
      let isPurchasing = false
      transactions.forEach(transaction => {
        const {payment, transactionState, transactionDate} = transaction
        const {productIdentifier} = payment

        switch (transactionState) {
          case 'purchasing':
            console.log('purchasing', productIdentifier)
            break

          case 'purchased':
            const fs = window.require('fs')
            const receiptPath = inAppPurchase
              .getReceiptURL()
              .replace('file://', '')
            const receipt = fs.readFileSync(receiptPath).toString('base64')
            console.log(JSON.stringify(transaction, null, 2))
            onValidateReceipt({
              receipt,
              purchaseDetails: {
                price: 99,
                currency: 'SEK',
              },
            })
            inAppPurchase.finishTransactionByDate(transactionDate)
            this.setState({
              ...this.state,
              isReceiptSentForValidation: true,
            })
            break

          case 'failed':
            console.log('purchase failed', productIdentifier)
            inAppPurchase.finishTransactionByDate(transactionDate)
            break

          case 'restored':
            console.log('product restored', productIdentifier)
            break

          case 'deferred':
            console.log('product deferred', productIdentifier)
            break

          default:
            break
        }

        if (transactionState === 'purchasing') isPurchasing = true
      })
      this.setState({
        ...this.state,
        isPurchasing,
      })
    })

    const masCanMakePayments = inAppPurchase.canMakePayments()
    inAppPurchase.getProducts(productIds, products => {
      const masProductsById = keyBy(products, 'productIdentifier')
      this.setState({
        ...this.state,
        masProductsById,
        masCanMakePayments,
      })
    })
  }

  render() {
    const {
      offers,
      isPurchasing,
      isReceiptSentForValidation,
      isValidatingReceipt,
      receiptValidationError,
    } = this.state
    const {isMacAppStoreBuild, onClose} = this.props

    const isReadyToShowOffers =
      offers.length > 0 && !isPurchasing && !isValidatingReceipt

    const LoadingIndicator = !isReadyToShowOffers && (
      <CircularProgress color="#fff" />
    )

    const FinePrintMessage = isMacAppStoreBuild ? (
      <Fragment>
        <FormattedMessage
          id="paywall_offers_fine_print_macos"
          defaultMessage="Remente premium renews automatically within 24 hours before the subscription period ends and you will be charged through your iTunes account. Manage your subscription in Account Settings."
        />
        <br />
        <br />
        <a
          href="https://member.remente.com/terms-of-usage"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy & Terms of Service
        </a>
      </Fragment>
    ) : (
      <FormattedMessage
        id="paywall_offers_fine_print"
        defaultMessage="Pay using credit card or PayPal. The subscription is activated immediately and you'll gain full access to all premium content and features. Cancel your subscription anytime."
      />
    )

    const PurchasingViaAppStore = isPurchasing && !isValidatingReceipt && (
      <FinePrint>
        <br />
        <FormattedMessage
          id="paywall_mas_purchase_active"
          defaultMessage="Connecting App Store..."
        />
      </FinePrint>
    )

    const ValidatingAppStoreReceipt = isValidatingReceipt && (
      <FinePrint>
        <br />
        <FormattedMessage
          id="paywall_mas_purchase_validating"
          defaultMessage="Validating your receipt..."
        />
      </FinePrint>
    )

    const ReceiptValidationError = !isPurchasing &&
      isReceiptSentForValidation &&
      receiptValidationError && (
        <div>
          <FormattedMessage
            id="paywall_mas_purchase_validate_failed"
            defaultMessage="Unable to validate your receipt. Please try again, or contact support if the problem persists."
          />
        </div>
      )

    return (
      <Modal fullWidth={true} onClose={onClose}>
        <BackgroundFill>
          <ModalPadding>
            <MainTitle>
              <FormattedMessage
                id="paywall_modal_title"
                defaultMessage="Upgrade to Premium"
              />
            </MainTitle>

            {LoadingIndicator}

            <SlideDown when={isReadyToShowOffers}>
              <Plans>{offers.map(this.renderOffer)}</Plans>
              <FinePrint>{FinePrintMessage}</FinePrint>
            </SlideDown>

            {PurchasingViaAppStore}
            {ValidatingAppStoreReceipt}
            {ReceiptValidationError}
          </ModalPadding>
        </BackgroundFill>

        <FeaturesHeader>
          <h3>
            <FormattedMessage
              id="paywall_features_header"
              defaultMessage="Why Remente Premium?"
            />
          </h3>
        </FeaturesHeader>
        <Features>{features.map(this.renderFeature)}</Features>
      </Modal>
    )
  }

  renderFeature = ({title, image, description}, i) => {
    return (
      <Feature key={i}>
        <FeatureTitle>{title}</FeatureTitle>
        <FeatureImage>
          <img src={image} alt="" />
        </FeatureImage>
        <FeatureDescription>{description}</FeatureDescription>
      </Feature>
    )
  }

  renderOffer = product => {
    const {id, isHighlighted, title, info, duration} = product
    const {isMacAppStoreBuild} = this.props
    const masProduct = get(this.state.masProductsById, id)

    const Highlight = isHighlighted && <PlanHighlight>Popular</PlanHighlight>

    const durationStr = duration ? (
      <FormattedMessage
        id="paywall_offer_duration"
        defaultMessage="{duration, plural, =1 {Month} other {Months}}"
        values={{duration}}
      />
    ) : (
      <FormattedMessage
        id="paywall_offer_duration_lifetime"
        defaultMessage="Lifetime"
      />
    )

    const formattedPrice = isMacAppStoreBuild
      ? get(masProduct, 'formattedPrice', 'Loading...')
      : get(info, 'en.formattedPrice')

    return (
      <Plan
        key={id}
        title={title}
        isHighlighted={isHighlighted}
        onClick={() => this.handlePurchase(product)}
      >
        {Highlight}
        <PlanDuration>
          {duration || '∞'}
          <small>{durationStr}</small>
        </PlanDuration>
        <PlanPrice>{formattedPrice}</PlanPrice>
      </Plan>
    )
  }

  handlePurchase = product => {
    const {isMacAppStoreBuild, authedUser} = this.props

    if (!isMacAppStoreBuild) {
      const {email, uid} = authedUser
      const paddleUrl = `https://pay.paddle.com/checkout/${product.paddleProductId}?guest_email=${email}&passthrough=${uid}`
      return window.open(paddleUrl, '_blank')
    }

    const {masCanMakePayments} = this.state

    if (!masCanMakePayments) return // products most likely not loaded yet

    this.setState({
      ...this.state,
      isPurchasing: true,
    })

    const {inAppPurchase} = window.require('electron').remote
    inAppPurchase.purchaseProduct(product.id, 1, isProductValid => {
      console.log('purchased product', product.id, isProductValid)
    })
  }
}

export default PaywallModal
