import {combineReducers} from 'redux'
import {createAction, handleActions, combineActions} from 'redux-actions'
import {combineEpics, ofType} from 'redux-observable'
import {FirebaseApi} from '../../api'
import {of, combineLatest} from 'rxjs'
import {map, switchMap, mergeMap, takeUntil, catchError} from 'rxjs/operators'
import {createSelector} from 'reselect'

import {getIsElectron} from '../electron'

export const setEmail = createAction('remente/auth/setEmail')
export const setPassword = createAction('remente/auth/setPassword')
export const authenticateUser = createAction('remente/auth/authenticateUser')
export const authenticateUserFulfilled = createAction(
  'remente/auth/authenticateUserFulfilled',
)
export const authenticateGetRedirectResult = createAction(
  'remente/auth/authenticateGetRedirectResult',
)
export const registerUser = createAction('remente/auth/registerUser')
export const createResetPasswordRequest = createAction(
  'remente/auth/createResetPasswordRequest',
)
export const observeAuthStateChanged = createAction(
  'remente/auth/observeAuthStateChanged',
)
const authenticateUserRejected = createAction(
  'remente/auth/authenticateUserRejected',
)
const authStateChanged = createAction('remente/auth/authStateChanged')
const authenticateGetRedirectResultFulfilled = createAction(
  'remente/auth/authenticateGetRedirectResultFulfilled',
)
const userProfileChanged = createAction('remente/auth/userProfileChanged')
export const userAuthenticated = createAction('remente/auth/userAuthenticated')
export const userUnauthenticated = createAction(
  'remente/auth/userUnauthenticated',
)
export const signOut = createAction('remente/auth/signOut')
const signOutFulfilled = createAction('remente/auth/signOutFulfilled')
const signOutRejected = createAction('remente/auth/signOutRejected')
const noop = createAction('remente/auth/noop')

const emailReducer = handleActions(
  {
    [setEmail]: (state, {payload}) => payload,
    [authStateChanged]: () => '',
  },
  '',
)

const passwordReducer = handleActions(
  {
    [setPassword]: (state, {payload}) => payload,
    [combineActions(authenticateUserRejected, authStateChanged)]: () => '',
  },
  '',
)

const authedUserReducer = handleActions(
  {
    [authStateChanged]: (state, {payload}) =>
      payload.user ? payload.user.toJSON() : null,
    [userProfileChanged]: (state, {payload}) => ({...state, ...payload}),
  },
  null,
)

const hasAuthStateReducer = handleActions(
  {
    [authStateChanged]: () => true,
  },
  false,
)

export default combineReducers({
  email: emailReducer,
  password: passwordReducer,
  authedUser: authedUserReducer,
  hasAuthState: hasAuthStateReducer,
})

/**
 * Selectors
 */

const authedUserSelector = ({auth}) => auth.authedUser
const hasAuthStateSelector = ({auth}) => auth.hasAuthState

export const getHasAuthState = createSelector(
  hasAuthStateSelector,
  hasAuthState => hasAuthState,
)

export const getAuthenticatedUser = createSelector(
  authedUserSelector,
  authedUser => authedUser,
)

export const getIsAuthenticated = createSelector(
  getAuthenticatedUser,
  user => !!user,
)

export const getAuthenticatedUserId = createSelector(
  getAuthenticatedUser,
  ({uid}) => uid,
)

export const getIsAuthenticatedUserPremium = createSelector(
  getAuthenticatedUser,
  ({isPremium}) => !!isPremium,
)

export const getIsAuthenticatedUserAllowedPremiumContent = createSelector(
  getAuthenticatedUser,
  ({isPremium, isAdmin, isEditor}) => isPremium || isAdmin || isEditor,
)

/**
 * Epics
 */

const authenticateUserEpic = action$ =>
  action$.pipe(
    ofType(authenticateUser().type),
    map(({payload}) => payload),
    mergeMap(({provider, email, password}) =>
      FirebaseApi.authenticate({provider, email, password}).pipe(
        map(res => authenticateUserFulfilled(res)),
        catchError(err => {
          alert(err.message)
          return of(authenticateUserRejected(err))
        }),
      ),
    ),
  )

const authenticateGetRedirectResultEpic = action$ =>
  action$.pipe(
    ofType(authenticateGetRedirectResult().type),
    mergeMap(() =>
      FirebaseApi.getRedirectResult().pipe(
        map(payload => authenticateGetRedirectResultFulfilled(payload)),
      ),
    ),
  )

const createResetPasswordRequestEpic = action$ =>
  action$.pipe(
    ofType(createResetPasswordRequest().type),
    map(({payload}) => payload),
    mergeMap(email =>
      FirebaseApi.sendPasswordResetEmail({email}).pipe(map(noop)),
    ),
  )

const registerUserEpic = action$ =>
  action$.pipe(
    ofType(registerUser().type),
    map(({payload}) => payload),
    mergeMap(({email, password, provider}) =>
      FirebaseApi.registerUser({email, password, provider}).pipe(
        map(noop),
        catchError(({message}) => {
          alert(message)
          return of(noop())
        }),
      ),
    ),
  )

const observeAuthStateChangedEpic = action$ =>
  action$.pipe(
    ofType(observeAuthStateChanged().type),
    mergeMap(() =>
      FirebaseApi.onAuthStateChanged().pipe(
        map(user => authStateChanged({user})),
      ),
    ),
  )

const observeAuthStateChangedSplitEpic = action$ =>
  action$.pipe(
    ofType(observeAuthStateChanged().type),
    mergeMap(() =>
      FirebaseApi.onAuthStateChanged().pipe(
        map(user => (user ? userAuthenticated(user) : userUnauthenticated())),
      ),
    ),
  )

const announcePresenceEpic = (action$, state$) =>
  action$.pipe(
    ofType(userAuthenticated().type),
    map(({payload}) => payload),
    mergeMap(firebaseUser =>
      FirebaseApi.connectionStatusObservableRef().pipe(
        map(isConnectedWithBackend => {
          if (!isConnectedWithBackend) return noop()

          const isElectron = getIsElectron(state$.value)
          const user = firebaseUser.toJSON()

          FirebaseApi.announcePresence({isElectron, user})
          return noop()
        }),
        takeUntil(action$.pipe(ofType(userUnauthenticated().type))),
      ),
    ),
  )

const unannouncePresenceEpic = action$ =>
  action$.pipe(
    ofType(signOut().type),
    map(() => {
      FirebaseApi.unannouncePresence()
      return noop()
    }),
  )

const authStateChangedEpic = action$ =>
  action$.pipe(
    ofType(authStateChanged().type),
    switchMap(({payload}) => {
      const {user} = payload
      if (!user) return of({type: 'remente/auth/UNAUTHORIZED'})
      return combineLatest(
        FirebaseApi.observableRef(`users/${user.uid}`),
        FirebaseApi.observableRef(`roles/premium/${user.uid}`),
        FirebaseApi.observableRef(`roles/lifetime-premium/${user.uid}`),
        FirebaseApi.observableRef(`roles/admins/${user.uid}`),
        FirebaseApi.observableRef(`roles/editors/${user.uid}`),
      ).pipe(
        map(res => ({
          profile: res[0],
          isPremium: !!res[1] || !!res[2],
          isLifetimePremium: !!res[2],
          isAdmin: !!res[3],
          isEditor: !!res[4],
        })),
        map(userProfileChanged),
      )
    }),
  )

const signOutEpic = action$ =>
  action$.pipe(
    ofType(signOut().type),
    switchMap(() =>
      FirebaseApi.signOut().pipe(
        map(signOutFulfilled),
        catchError(err => of(signOutRejected(err))),
      ),
    ),
  )

export const authEpics = combineEpics(
  authenticateUserEpic,
  authenticateGetRedirectResultEpic,
  registerUserEpic,
  createResetPasswordRequestEpic,
  observeAuthStateChangedEpic,
  observeAuthStateChangedSplitEpic,
  announcePresenceEpic,
  unannouncePresenceEpic,
  authStateChangedEpic,
  signOutEpic,
)
