import React, {Component} from 'react'
import {ContentApi} from '../api'
import {findIndex, has, get, find} from 'lodash'
import CoverPhotoHeader from './CoverPhotoHeader.js'
import ScrollViewMain from './ScrollViewMain.js'
import CourseOutlinePopover from './CourseOutlinePopover.js'
import MainContainer from './MainContainer.js'
import NextLesson from './NextLesson.js'

class RouteResourceCourseLesson extends Component {
  componentDidMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.lessonId === nextProps.lessonId) return
    this.init(nextProps)
  }

  init(props) {
    const {
      language,
      courseId,
      lessonId,
      course,
      subscribeCourseContent,
      markLessonRead,
      updateUserHistory,
    } = props

    subscribeCourseContent({language, courseId, lessonId})
    markLessonRead({language, courseId, lessonId})

    if (!course) return
    const {id, title} = course

    updateUserHistory({
      language,
      type: 'courses',
      id,
      title,
      imageUrl: get(course, 'images.featured.servingUrl'),
    })
  }

  render() {
    const {
      language,
      courseId,
      lessonId,
      course,
      contentById = {},
      userReadLessons = {},
    } = this.props

    const lesson = find(get(course, 'lessons', []), {
      lessonId,
    })

    const lessonContent = get(lesson, 'content', []).map(c => ({
      ...c,
      ...contentById[c.contentId],
    }))

    const courseOutlinePopover = {
      language,
      ...course,
      userReadLessons,
      currentLesson: lessonId,
    }

    const isLoading = !course
    let nextLesson = null

    if (!isLoading) {
      const currentLessonIndex = findIndex(course.lessons, {lessonId})
      if (
        has(lessonContent, '[0].content') &&
        currentLessonIndex < course.lessons.length
      )
        nextLesson = {
          language,
          courseId,
          ...course.lessons[currentLessonIndex + 1],
        }
    }

    const imageUrl = get(course, 'images.featured.servingUrl')

    return (
      <ScrollViewMain>
        <CoverPhotoHeader image={imageUrl} loading={isLoading}>
          <h2>
            {course && course.title}
            <small>{lesson && lesson.title}</small>
          </h2>
        </CoverPhotoHeader>

        <MainContainer horizontalPadding verticalPadding>
          <CourseOutlinePopover {...courseOutlinePopover} loading={isLoading} />
          {ContentApi.renderContentSegments(lessonContent)}
        </MainContainer>

        <NextLesson {...nextLesson} />
      </ScrollViewMain>
    )
  }
}

export default RouteResourceCourseLesson
