import React from 'react'
import Checkbox from 'material-ui/Checkbox'
import IconButton from 'material-ui/IconButton'
import IconUnchecked from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import IconChecked from 'material-ui/svg-icons/toggle/check-box'
import IconDelete from 'material-ui/svg-icons/action/delete'
import InlineEditText from './InlineEditText.js'
import styles from './ChecklistItem.module.sass'

const ChecklistItem = ({
  title,
  checkedAt,
  state = 'idle',
  onTitleChanged,
  onToggleEditTitle,
  onCheck,
  onDelete,
}) => {
  const isCompleted = Boolean(checkedAt)
  return (
    <li className={styles.item}>
      <Checkbox
        checked={isCompleted}
        checkedIcon={<IconChecked />}
        uncheckedIcon={<IconUnchecked />}
        onCheck={onCheck}
      />
      <div
        className={styles.title}
        onDoubleClick={() => onToggleEditTitle(true)}
      >
        <InlineEditText
          value={title}
          isEditing={state === 'editing'}
          onSubmit={onTitleChanged}
          onBlur={() => onToggleEditTitle(false)}
          style={{
            flex: 1,
          }}
        >
          {title}
        </InlineEditText>
      </div>
      <div className={styles.delete}>
        <IconButton onClick={onDelete}>
          <IconDelete />
        </IconButton>
      </div>
    </li>
  )
}

export default ChecklistItem
