import firebase from 'firebase/app'
import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createAction, handleActions, combineActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {of} from 'rxjs'
import {map, switchMap, catchError} from 'rxjs/operators'
import {get, union} from 'lodash'
import {FirebaseApi} from '../../api'

import {userAuthenticated, signOut} from '../auth'

export const requestFcmPermission = createAction(
  'remente/fcm/requestFcmPermission',
)
const requestFcmPermissionFulfilled = createAction(
  'remente/fcm/requestFcmPermissionFulfilled',
)
const requestFcmPermissionRejected = createAction(
  'remente/fcm/requestFcmPermissionRejected',
)
const setToken = createAction('remente/fcm/setToken')
const associateTokenFulfilled = createAction(
  'remente/fcm/associateTokenFulfilled',
)
const associateTokenRejected = createAction(
  'remente/fcm/associateTokenRejected',
)
const disassociateTokenFulfilled = createAction(
  'remente/fcm/disassociateTokenFulfilled',
)
const disassociateTokenRejected = createAction(
  'remente/fcm/disassociateTokenRejected',
)
const setFcmEnabled = createAction('remente/fcm/setFcmEnabled')

/**
 * Reducers
 */

const isFcmEnabledReducer = handleActions(
  {
    [combineActions(setFcmEnabled, setToken)]: (state, {payload}) => !!payload,
  },
  false,
)

const tokenReducer = handleActions(
  {
    [setToken]: (state, {payload}) => payload,
  },
  null,
)

const checkNotificationPermission = () =>
  get(window, 'Notification.permission', null)

const notificationPermissionStateReducer = handleActions(
  {
    [combineActions(
      requestFcmPermissionFulfilled,
      requestFcmPermissionRejected,
    )]: () => checkNotificationPermission(),
  },
  checkNotificationPermission(),
)

export default combineReducers({
  isFcmEnabled: isFcmEnabledReducer,
  token: tokenReducer,
  notificationPermissionState: notificationPermissionStateReducer,
})

/**
 * Selectors
 */

const isFcmEnabledSelector = ({fcm}) => fcm.isFcmEnabled
const tokenSelector = ({fcm}) => fcm.token
const notificationPermissionStateSelector = ({fcm}) =>
  fcm.notificationPermissionState

export const getIsFcmEnabled = createSelector(
  isFcmEnabledSelector,
  value => value,
)
export const getFcmToken = createSelector(tokenSelector, value => value)
export const getNotificationPermissionState = createSelector(
  notificationPermissionStateSelector,
  value => value,
)

/**
 * Epics
 */

const requestFcmPermissionEpic = action$ =>
  action$.pipe(
    ofType(requestFcmPermission().type),
    switchMap(() =>
      FirebaseApi.fcmRequestPermission().pipe(
        map(requestFcmPermissionFulfilled),
        catchError(err => of(requestFcmPermissionRejected(err))),
      ),
    ),
  )

const getFcmTokenEpic = action$ =>
  action$.pipe(
    ofType(
      userAuthenticated().type,
      requestFcmPermissionFulfilled().type,
      requestFcmPermissionRejected().type,
    ),
    switchMap(() =>
      FirebaseApi.fcmGetToken().pipe(
        map(token => (token ? setToken(token) : setFcmEnabled(false))),
        catchError(() => of(setFcmEnabled(false))),
      ),
    ),
  )

const associateFcmTokenEpic = action$ =>
  action$.pipe(
    ofType(setToken().type),
    map(({payload}) => payload),
    switchMap(registrationId =>
      FirebaseApi.fcmAssociateTokenWithUser({registrationId}).pipe(
        map(associateTokenFulfilled),
        catchError(err => of(associateTokenRejected(err))),
      ),
    ),
  )

const disassociateFcmTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(signOut().type),
    switchMap(() => {
      const registrationId = getFcmToken(state$.value)
      if (!registrationId) return of(disassociateTokenFulfilled())
      return FirebaseApi.fcmDisassociateTokenWithUser({registrationId}).pipe(
        map(disassociateTokenFulfilled),
        catchError(err => of(disassociateTokenRejected(err))),
      )
    }),
  )

const developmentEpics = []

const productionEpics = [
  requestFcmPermissionEpic,
  getFcmTokenEpic,
  associateFcmTokenEpic,
  disassociateFcmTokenEpic,
]

const epics =
  process.env.NODE_ENV === 'production' && firebase.messaging.isSupported()
    ? union(developmentEpics, productionEpics)
    : developmentEpics

export const fcmEpics = combineEpics.apply(this, epics)
