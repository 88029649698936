import React, {Component} from 'react'
import {FormattedMessage} from 'react-intl'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import IconThumbUp from 'material-ui/svg-icons/action/thumb-up'
import IconThumbDown from 'material-ui/svg-icons/action/thumb-down'
import ToggleButton from './ToggleButton.js'
import {Wrap, TabContent, Tags} from './MoodTagPicker.styles.js'

class MoodTagPicker extends Component {
  render() {
    const {
      positiveTags,
      negativeTags,
      tabsSelectedIndex = 0,
      tabsOnSelect,
    } = this.props

    return (
      <Wrap>
        <Tabs selectedIndex={tabsSelectedIndex} onSelect={tabsOnSelect}>
          <TabList>
            <Tab>
              <TabContent>
                <IconThumbDown color="#515151" />
                <FormattedMessage
                  id="mood_tag_picker_feelings_negative"
                  defaultMessage="Negative"
                />
              </TabContent>
            </Tab>
            <Tab>
              <TabContent>
                <IconThumbUp color="#515151" />
                <FormattedMessage
                  id="mood_tag_picker_feelings_positive"
                  defaultMessage="Positive"
                />
              </TabContent>
            </Tab>
          </TabList>

          <TabPanel>
            <Tags>{negativeTags.map(this.renderTag)}</Tags>
          </TabPanel>

          <TabPanel>
            <Tags>{positiveTags.map(this.renderTag)}</Tags>
          </TabPanel>
        </Tabs>
      </Wrap>
    )
  }

  renderTag = ({id, title, isPositive}) => {
    const {selectedTags = {}, onTagToggle} = this.props

    const handleTagToggle = () => onTagToggle && onTagToggle(id)

    return (
      <ToggleButton
        key={id}
        label={title}
        selectedColor={isPositive ? '#7ADAC3' : '#F48785'}
        checked={!!selectedTags[id]}
        onChange={handleTagToggle}
      />
    )
  }
}

export default MoodTagPicker
