import {connect} from 'react-redux'
import AssignGoalTaskInput from './AssignGoalTaskInput.js'
import {
  getGoalOrganizationUsers,
  getGoalOrganizationUsersById,
} from '../ducks/goalDetails'

const mapStateToProps = (state, {taskId}) => ({
  organizationUsers: getGoalOrganizationUsers(state),
  organizationUsersById: getGoalOrganizationUsersById(state),
})

const AssignGoalTaskInputContainer = connect(mapStateToProps)(
  class AssignGoalTaskInputContainer extends AssignGoalTaskInput {},
)

export default AssignGoalTaskInputContainer
