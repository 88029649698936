import styled from 'styled-components'

export const TabContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease-out;
  transform: scale(0.9) translatey(10%);
  will-change: transform;
  opacity: 0.5;
  span {
    margin-left: 0.5em;
  }
`

export const Wrap = styled.div`
  min-width: 450px;
  border: 4px solid #f4f4f6;
  margin: 1em 0;
  border-radius: 4px;

  .react-tabs__tab-list {
    background: #f4f4f6;
    display: flex;
    margin: 0;
    padding: 0;
    li {
      flex: 1;
      list-style: none;
      margin: 0;
      text-align: center;
    }
  }

  .react-tabs__tab {
    color: #515151;
    cursor: pointer;
    padding: 1.5em 0;
    background: #f4f4f6;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 600;
    transition: all 150ms ease-out;
    svg {
      width: 16px;
      height: 16px;
    }
    &:hover {
      ${TabContent} {
        opacity: 1;
      }
    }
    &:first-child {
      border-radius: 4px 0 0 0;
    }
    &:last-child {
      border-radius: 0 4px 0 0;
    }
  }

  .react-tabs__tab--selected {
    background: #fff;
    ${TabContent} {
      transform: scale(1);
      opacity: 1;
    }
  }

  .react-tabs__tab-panel {
    background: #f4f4f6;
  }
`

export const Tags = styled.div`
  border-radius: 0 0 4px 4px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  padding: 1em 4px;
  > * {
    margin: 4px;
    width: calc(25% - 8px);
  }
`
