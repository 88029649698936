import {combineReducers} from 'redux'
import {combineEpics} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {values} from 'lodash'

import {FirebaseApi} from '../../api'
import {createEnsureAuthenticatedEpic} from '../../utils/epic'

export const changeMyMoodAssessments = createAction(
  'remente/moodAssessment/changeMyMoodAssessments',
)

/**
 * Reducers
 */

const myMoodAssessmentsByIdReducer = handleActions(
  {
    [changeMyMoodAssessments]: (state, {payload}) => payload.myMoodAssessments,
  },
  {},
)

export default combineReducers({
  myMoodAssessmentsById: myMoodAssessmentsByIdReducer,
})

/**
 * Selectors
 */

const myMoodAssessmentsByIdSelector = ({moodAssessment}) =>
  moodAssessment.myMoodAssessmentsById

export const getMyMoodAssessmentsById = createSelector(
  myMoodAssessmentsByIdSelector,
  assessmentsById => assessmentsById,
)

export const getMyMoodAssessments = createSelector(
  getMyMoodAssessmentsById,
  myMoodAssessmentsById => values(myMoodAssessmentsById),
)

/**
 * Epics
 */

const myMoodAssessmentsEpic = createEnsureAuthenticatedEpic({
  createObservables: ({uid}) => ({
    myMoodAssessments: FirebaseApi.observableRef(`mood-assessments/${uid}`),
  }),
  actionFulfilled: changeMyMoodAssessments,
})

export const moodAssessmentEpics = combineEpics(myMoodAssessmentsEpic)
