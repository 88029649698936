import moment from 'moment'
import {values, get, orderBy, groupBy} from 'lodash'

export function compileGoalJourney({goal, goalJourneyEntries}) {
  if (!goalJourneyEntries || !goal) return []

  let journeyEntries = joinGoalWithJourneyEntries({
    goal,
    goalJourneyEntries,
  })

  let journeyEntriesByDay = groupBy(
    orderBy(journeyEntries, 'timestamp', 'desc'),
    entry =>
      moment(entry.loggedAt)
        .startOf('day')
        .valueOf(),
  )

  let groupedByDay = []

  for (var key in journeyEntriesByDay) {
    const entries = journeyEntriesByDay[key] || []
    groupedByDay.push({
      timestamp: parseInt(key, 10),
      entries,
    })
  }

  return orderBy(groupedByDay, 'timestamp', 'desc')
}

function joinGoalWithJourneyEntries({goal, goalJourneyEntries}) {
  return values(goalJourneyEntries).map(entry => {
    const task = get(goal, ['tasksById', entry.taskId])
    return {
      ...entry,
      goalId: goal.id,
      entryTitle: get(task, 'title'),
      imageUrl: get(entry, 'attachments.images.featured.servingUrl'),
      notes: get(entry, 'attachments.notes'),
      timestamp: entry.loggedAt,
    }
  })
}
