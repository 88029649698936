import {ofType} from 'redux-observable'
import {of, combineLatest} from 'rxjs'
import {map, switchMap, takeUntil, catchError} from 'rxjs/operators'
import {userAuthenticated, userUnauthenticated} from '../../ducks/auth'

const createEnsureAuthenticatedEpic = ({
  createObservables,
  actionFulfilled,
  actionRejected,
}) => (action$, store) =>
  action$.pipe(
    ofType(userAuthenticated().type),
    map(({payload}) => payload),
    switchMap(authPayload => {
      const observables = createObservables(authPayload, store)
      return combineLatest(Object.values(observables)).pipe(
        takeUntil(action$.pipe(ofType(userUnauthenticated().type))),
        map(res => {
          const keys = Object.keys(observables)
          const payload = {}
          keys.forEach((key, i) => (payload[key] = res[i]))
          return actionFulfilled(payload)
        }),
        catchError(err => of(actionRejected(err))),
      )
    }),
  )

export {createEnsureAuthenticatedEpic}
