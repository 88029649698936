import {connect} from 'react-redux'
import GoalTemplateDetailsModal from './GoalTemplateDetailsModal.js'

import {closeModal} from '../ducks/app'

import {
  getClonedGoalTemplate,
  getTemplatePlanDuration,
  getTemplatePlanDescription,
  getSelectedStartDate,
  setSelectedStartDate,
  acceptGoalTemplate,
} from '../ducks/goalTemplateDetailsModal'

const mapStateToProps = state => ({
  goal: getClonedGoalTemplate(state),
  goalPlanDuration: getTemplatePlanDuration(state),
  goalPlanDescription: getTemplatePlanDescription(state),
  selectedStartDate: getSelectedStartDate(state),
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal()),
  onStartDateChanged: date => dispatch(setSelectedStartDate(date)),
  onAcceptButtonClick: () => dispatch(acceptGoalTemplate()),
})

class GoalTemplateDetailsModalContainerClass extends GoalTemplateDetailsModal {}

const GoalTemplateDetailsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalTemplateDetailsModalContainerClass)

export default GoalTemplateDetailsModalContainer
