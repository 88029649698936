import React from 'react'
import MaskedInput from 'react-maskedinput'
import styled from 'styled-components'
import moment from 'moment'

import {isValid24hTime} from '../utils/date'

const TimeInput = styled(MaskedInput)`
  ${({theme}) => `
    border: none;
    text-align: center;
    padding: .2em .1em;
    font-weight: 600;
    color: ${theme.colorPrimary1};
    &:hover,
    &:focus {
      color: ${theme.colorBodyFg};
      background: ${theme.colorMaskedInputBg};
    }
    ::placeholder {
      color: #000;
      opacity: .2;
    }
    border-radius: 4px;
  `}
`

const parseTimeStr = value => {
  const parts = value.split(':')
  return {
    hour: parseInt(parts[0], 10),
    minute: parseInt(parts[1], 10),
  }
}

const TimePicker = ({onChange, isUtc, ...rest}) => {
  const handleChange = event => {
    const {value} = event.target
    if (!isValid24hTime(value)) return
    const {hour, minute} = parseTimeStr(value)
    if (!isUtc) return onChange({hour, minute, event})
    const timeUtc = moment({hour, minute}).utc()
    const h = timeUtc.hour()
    const m = timeUtc.minute()
    onChange({hour: h, minute: m, event})
  }

  let val = rest.value
  if (isUtc && isValid24hTime(val)) {
    const timeUtc = moment.utc(parseTimeStr(val))
    val = timeUtc.local().format('HH:mm')
  }

  const handleFocus = e => e.target.select()

  return (
    <TimeInput
      mask="11:11"
      placeholder="hh:mm"
      onChange={handleChange}
      onFocus={handleFocus}
      {...rest}
      value={val}
    />
  )
}

export default TimePicker
