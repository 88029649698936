import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {map, startWith, mergeMap, takeUntil} from 'rxjs/operators'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {values} from 'lodash'

import {FirebaseApi} from '../../api'
import {changeLanguage} from '../app'
import {userUnauthenticated} from '../auth'

export const changeMoodTags = createAction(
  'remente/moodAssessment/changeMoodTags',
)

/**
 * Reducers
 */

const moodTagsByIdReducer = handleActions(
  {
    [changeMoodTags]: (state, {payload}) => payload,
  },
  {},
)

export default combineReducers({
  moodTagsById: moodTagsByIdReducer,
})

/**
 * Selectors
 */

const moodTagsByIdSelector = ({moodTag}) => moodTag.moodTagsById

export const getMoodTagsById = createSelector(
  moodTagsByIdSelector,
  moodTagsById => moodTagsById,
)

export const getMoodTags = createSelector(getMoodTagsById, moodTagsById =>
  values(moodTagsById),
)

export const getPositiveMoodTags = createSelector(getMoodTags, moodTags =>
  moodTags.filter(({isPositive}) => isPositive),
)

export const getNegativeMoodTags = createSelector(getMoodTags, moodTags =>
  moodTags.filter(({isPositive}) => !isPositive),
)

/**
 * Epics
 */

const moodTagsByIdEpic = action$ =>
  action$.pipe(
    ofType(changeLanguage().type),
    map(({payload}) => payload.language),
    mergeMap(language =>
      FirebaseApi.observableRef(`mood-tags/${language}`).pipe(
        startWith({}),
        takeUntil(action$.pipe(ofType(userUnauthenticated().type))),
        map(payload => changeMoodTags(payload)),
      ),
    ),
  )

export const moodTagEpics = combineEpics(moodTagsByIdEpic)
