import React from 'react'
import styled from 'styled-components'
import chroma from 'chroma-js'
import {FormattedMessage} from 'react-intl'

const Wrap = styled.div`
  ${({theme: {colorMaskedInputBg, colorBodyFg}}) => `
    background: ${colorMaskedInputBg};
    box-shadow: 0 1px 0 ${chroma(colorMaskedInputBg)
      .darken(0.8)
      .hex()};
    color: ${colorBodyFg};
    a {
      text-decoration: underline;
      color: #3F9DDC;
      &:hover {
        text-decoration: none;
      }
    }
  `}
  max-width: calc(100% - 350px);
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 4;
  font-size: 14px;
  border-radius: 0 0 10px 0;
  padding: 10px 15px 10px 80px;
  text-align: center;
`

const UpdateAvailableBanner = ({isUpdateDownloaded, onUpdateClick}) => {
  if (!isUpdateDownloaded) return null

  const handleClick = e => {
    e.preventDefault()
    onUpdateClick(e)
  }

  const Link = (
    <a href="/update-app" onClick={handleClick}>
      <FormattedMessage
        id="update_available_banner_link_restart_now"
        defaultMessage="Restart now"
      />
    </a>
  )

  return (
    <Wrap>
      <FormattedMessage
        id="update_available_banner_label"
        defaultMessage="Hello! We've got a new version of Remente ready for you. {updateLink} to make it yours."
        values={{
          updateLink: Link,
        }}
      />
    </Wrap>
  )
}

export default UpdateAvailableBanner
