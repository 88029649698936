import {connect} from 'react-redux'
import EditMoodAssessmentModal from './EditMoodAssessmentModal.js'
import {getPositiveMoodTags, getNegativeMoodTags} from '../ducks/moodTag'
import {updateMoodAssessment} from '../ducks/editMoodAssessmentModal'
import {closeModal} from '../ducks/app'

const mapStateToProps = state => ({
  positiveTags: getPositiveMoodTags(state),
  negativeTags: getNegativeMoodTags(state),
  selectedRating: undefined,
  notes: '',
  selectedTags: {},
})

const mapDispatchToProps = dispatch => ({
  onDone: assessment => dispatch(updateMoodAssessment(assessment)),
  onClose: () => dispatch(closeModal()),
  onCancel: () => dispatch(closeModal()),
})

const EditMoodAssessmentModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class EditMoodAssessmentModalContainer extends EditMoodAssessmentModal {})

export default EditMoodAssessmentModalContainer
