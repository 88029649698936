import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'

import SignIn from './SignInContainer.js'
import SignUp from './SignUpContainer.js'
import logoRemente from '../graphics/logo-remente.svg'

import {Cover, Box, Logo, BottomLink} from './AuthForm.styles.js'

class AuthForm extends Component {
  render() {
    const {isAuthenticated} = this.props

    if (isAuthenticated) return <Redirect push to={{pathname: '/'}} />

    return (
      <Cover>
        <Box>
          <Logo>
            <img src={logoRemente} alt="" />
          </Logo>

          <Route path="/login" component={SignIn} />
          <Route path="/register" component={SignUp} />
        </Box>

        <Route path="/login" render={() => this.renderLink()} />
        <Route path="/register" render={() => this.renderLink(true)} />
      </Cover>
    )
  }

  renderLink = isRegister => {
    const url = isRegister ? '/login' : '/register'
    const body = isRegister
      ? 'Already a member? Log in here!'
      : 'Not a member? Create your account here!'
    return <BottomLink to={url}>{body}</BottomLink>
  }
}

export default AuthForm
