import firstBy from 'thenby'

import {sortByGoalTaskRemindersFactory} from './'

const sortTodoListItems = ({items, date}) =>
  items.sort(
    firstBy('isCompleted')
      .thenBy(({checkedInAt}) => checkedInAt)
      .thenBy(({manualSortIndex}) => manualSortIndex)
      .thenBy(
        sortByGoalTaskRemindersFactory({
          date,
        }),
      )
      .thenBy('goalId', -1)
      .thenBy(({createdAt}) => createdAt),
  )

export {sortTodoListItems}
