import React, {Component} from 'react'
import {sortBy} from 'lodash'
import {FormattedMessage} from 'react-intl'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'

import IconSingleReminder from 'material-ui/svg-icons/action/event'
import IconRecurringReminder from 'material-ui/svg-icons/av/repeat'
import IconTrash from 'material-ui/svg-icons/action/delete'

import {List, DeleteButtonWrap, ReminderWrap} from './ReminderList.styles.js'

import ReminderSingle from './ReminderSingle.js'
import ReminderRecurring from './ReminderRecurring.js'

const listEnterAnimation = {
  from: {
    opacity: 0,
    transform: 'translateY(-20%)',
  },
  to: {
    opacity: 1,
  },
}

const listLeaveAnimation = {
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
    transform: 'translateY(-20%)',
  },
}

class ReminderList extends Component {
  renderSingleReminder = ({id, notifyAt}) => {
    const {onSingleReminderChange} = this.props
    const handleNotifyAtChange = newNotifyAt =>
      onSingleReminderChange({
        id,
        notifyAt: newNotifyAt,
      })
    return (
      <ReminderSingle
        id={id}
        notifyAt={notifyAt}
        onNotifyAtChange={handleNotifyAtChange}
      />
    )
  }

  renderRecurringReminder = ({id, time, days}) => {
    const {onRecurringReminderChange} = this.props

    const handleChange = ({time, days}) =>
      onRecurringReminderChange({
        id,
        time,
        days,
      })

    return <ReminderRecurring time={time} days={days} onChange={handleChange} />
  }

  render() {
    const {
      singleReminders,
      recurringReminders,
      onAddSingleReminder,
      onAddRecurringReminder,
      onDeleteReminder,
    } = this.props

    const NoRemindersPlacehodler = singleReminders.length +
      recurringReminders.length ===
      0 && (
      <p>
        <FormattedMessage
          id="edit_goal_step_input_reminders_placeholder"
          defaultMessage="Set reminders to get notified when it's time to take this step."
        />
      </p>
    )

    const Reminders = sortBy(
      recurringReminders
        .map(r => ({...r, type: 'recurring'}))
        .concat(singleReminders.map(r => ({...r, type: 'single'}))),
      'id',
    ).map(r => (
      <ReminderWrap key={r.id}>
        <DeleteButtonWrap>
          <IconButton onClick={() => onDeleteReminder(r.id)}>
            <IconTrash />
          </IconButton>
        </DeleteButtonWrap>
        {r.type === 'single'
          ? this.renderSingleReminder(r)
          : this.renderRecurringReminder(r)}
      </ReminderWrap>
    ))

    return (
      <div>
        {NoRemindersPlacehodler}

        <List
          enterAnimation={listEnterAnimation}
          leaveAnimation={listLeaveAnimation}
        >
          {Reminders}

          <ReminderWrap>
            <FlatButton
              label={
                <FormattedMessage
                  id="edit_goal_step_input_reminders_add_single_label"
                  defaultMessage="Add Single Reminder"
                />
              }
              icon={<IconSingleReminder />}
              onClick={onAddSingleReminder}
            />

            <FlatButton
              label={
                <FormattedMessage
                  id="edit_goal_step_input_reminders_add_recurring_label"
                  defaultMessage="Add Recurring Reminder"
                />
              }
              icon={<IconRecurringReminder />}
              onClick={onAddRecurringReminder}
            />
          </ReminderWrap>
        </List>
      </div>
    )
  }
}

export default ReminderList
