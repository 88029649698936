import React from 'react'
import styled from 'styled-components'

export const AspectRatioWrap = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ratio}) => ratio * 100}%;
`

export const AspectRatioInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const AspectRatio = ({ratio = 1, children, ...rest}) => (
  <AspectRatioWrap ratio={ratio} {...rest}>
    <AspectRatioInner>{children}</AspectRatioInner>
  </AspectRatioWrap>
)

export default AspectRatio
