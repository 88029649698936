import {connect} from 'react-redux'
import {get} from 'lodash'
import RouteResourceBoost from './RouteResourceBoost.js'
import {updateUserHistory} from '../ducks/userContent'

const mapStateToProps = ({content}, {match}) => {
  const {boostsById} = content
  const {language, boostId} = match.params
  const boost = boostsById[boostId]
  const imageUrl = get(boost, 'images.featured.servingUrl')

  return {
    ...boost,
    imageUrl,
    language,
    loading: boost === undefined,
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserHistory: options => dispatch(updateUserHistory(options)),
})

class RouteResourceBoostContainerClass extends RouteResourceBoost {}

const RouteResourceBoostContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteResourceBoostContainerClass)

export default RouteResourceBoostContainer
