import React from 'react'
import {Switch, Route} from 'react-router'
import AuthForm from './AuthForm.js'
import RouteAuthenticated from './RouteAuthenticated.js'

const RouteAuth = ({isAuthenticated}) => {
  return (
    <Switch>
      <Route
        path="/login"
        render={() => <AuthForm isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/register"
        render={() => <AuthForm isAuthenticated={isAuthenticated} />}
      />
      <Route
        render={() => <RouteAuthenticated isAuthenticated={isAuthenticated} />}
      />
    </Switch>
  )
}

export default RouteAuth
