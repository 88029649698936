import {connect} from 'react-redux'
import AssignedTaskProgress from './AssignedTaskProgress.js'
import {getUserProgress} from '../ducks/goalDetails'

const mapStateToProps = (state, props) => ({
  userProgress: getUserProgress(state, props),
})

const AssignedTaskProgressContainer = connect(mapStateToProps)(
  class AssignedTaskProgressContainer extends AssignedTaskProgress {},
)

export default AssignedTaskProgressContainer
