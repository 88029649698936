import {connect} from 'react-redux'

import CreateMenu from './CreateMenu.js'

import {showCreateMoodAssessmentModal} from '../ducks/editMoodAssessmentModal'
import {navigateAndCreateLifeAssessment} from '../ducks/lifeWheel'
import {navigateAndEnterPlanMode} from '../ducks/dayplanner'

const mapDispatchToProps = dispatch => ({
  onCreateMoodAssessmentClick: () => dispatch(showCreateMoodAssessmentModal()),
  onCreateLifeAssessmentClick: () =>
    dispatch(navigateAndCreateLifeAssessment()),
  onPlanMyDayClick: () => dispatch(navigateAndEnterPlanMode()),
})

class CreateMenuContainerClass extends CreateMenu {}

const CreateMenuContainer = connect(
  null,
  mapDispatchToProps,
)(CreateMenuContainerClass)

export default CreateMenuContainer
