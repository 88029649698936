import {connect} from 'react-redux'
import {get} from 'lodash'
import {getIsAuthenticatedUserAllowedPremiumContent} from '../ducks/auth'
import {showPaywall} from '../ducks/app'

import RouteResourceCourse from './RouteResourceCourse.js'

// TODO fix bad prop naming & use selectors
const mapStateToProps = (state, {match}) => {
  const {content, userContent} = state
  const {coursesById} = content
  const {language, courseId} = match.params
  const {userProgress} = userContent
  const userReadLessons = get(userProgress, [language, 'lessons', courseId], {})
  const course = coursesById[courseId]
  const imageUrl = get(course, 'images.featured.servingUrl')
  const loading = !course
  return {
    ...course,
    loading,
    imageUrl,
    language,
    canViewPremiumContent: getIsAuthenticatedUserAllowedPremiumContent(state),
    userReadLessons,
  }
}

const mapDispatchToProps = dispatch => ({
  onPremiumContentClick: () => dispatch(showPaywall()),
})

class RouteResourceCourseContainerClass extends RouteResourceCourse {}

const RouteResourceCourseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteResourceCourseContainerClass)

export default RouteResourceCourseContainer
