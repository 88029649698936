import {connect} from 'react-redux'
import SignIn from './SignIn.js'

import {
  setEmail,
  setPassword,
  authenticateUser,
  createResetPasswordRequest,
} from '../ducks/auth'

const mapStateToProps = ({auth}) => {
  const {email, password} = auth
  return {email, password}
}

const mapDispatchToProps = dispatch => ({
  emailChanged: email => dispatch(setEmail(email)),
  passwordChanged: password => dispatch(setPassword(password)),
  authenticateUser: auth => {
    delete localStorage.authUserDidSignUp
    return dispatch(authenticateUser(auth))
  },
  onResetPassword: email => dispatch(createResetPasswordRequest(email)),
})

const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignIn)

export default SignInContainer
