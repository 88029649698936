import {connect} from 'react-redux'
import UpdateAvailableBanner from './UpdateAvailableBanner.js'

import {getIsUpdateDownloaded, quitAndInstallUpdate} from '../ducks/electron'

const mapStateToProps = state => ({
  isUpdateDownloaded: getIsUpdateDownloaded(state),
})

const mapDispatchToProps = dispatch => ({
  onUpdateClick: () => dispatch(quitAndInstallUpdate()),
})

const UpdateAvailableBannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateAvailableBanner)

export default UpdateAvailableBannerContainer
