import React, {Component} from 'react'
import {defineMessages, injectIntl} from 'react-intl'

import AddListItemInput from './AddListItemInput.js'

const translations = defineMessages({
  placeholder: {
    id: 'checklist_add_task',
    defaultMessage: 'Add something',
  },
})

class ChecklistItemAdd extends Component {
  state = {
    value: '',
  }

  handleSubmit = e => {
    const {value} = this.state
    const {onSubmit} = this.props
    if (value && value.length > 0) {
      onSubmit(value)
      this.setState({
        ...this.state,
        value: '',
      })
    }
    this.input.focus()
    return false
  }

  handleChange = e => {
    this.setState({
      ...this.state,
      value: e.target.value,
    })
  }

  render() {
    const {value} = this.state
    const {intl} = this.props
    const buttonProps = {
      onClick: this.handleSubmit,
    }
    const setInputRef = input => (this.input = input)

    const placeholderTranslation = intl.formatMessage(translations.placeholder)

    return (
      <AddListItemInput
        key="checklist-item-add"
        value={value}
        placeholder={placeholderTranslation}
        onChange={this.handleChange}
        buttonProps={buttonProps}
        innerRef={setInputRef}
      />
    )
  }
}

export default injectIntl(ChecklistItemAdd)
