import moment from 'moment'

import {getFullWeek, isoWeekdayToDayId} from './'

const getFullWeekFormatted = ({
  startAt = moment(),
  formatShort = 'ddd',
  formatLong = 'dddd',
}) =>
  getFullWeek({startAt}).map(day => {
    const isoWeekday = day.isoWeekday()
    const dayNameShort = day.format(formatShort)
    const dayNameLong = day.format(formatLong)
    return {
      isoWeekday,
      weekdayId: isoWeekdayToDayId(isoWeekday),
      dayNameShort,
      dayNameLong,
    }
  })

export {getFullWeekFormatted}
