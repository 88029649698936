import React, {Component} from 'react'
import {get, values} from 'lodash'
import moment from 'moment'
import {FormattedMessage} from 'react-intl'

import IconDuration from 'material-ui/svg-icons/action/date-range'

import DayPicker from './DayPicker.js'
import GoalTask from './GoalTask.js'
import Modal from './Modal.js'
import ModalContent from './ModalContent.js'

import {
  ImageHeaderWrap,
  ImageHeader,
  ImageHeaderTitle,
  ImageHeaderOverlay,
  AcceptButton,
  TemplateDetailsBar,
  Description,
  GoalPlan,
  DurationWrap,
} from './GoalTemplateDetailsModal.styles.js'

const renderTask = (task, i) => <GoalTask key={i} {...task} />

class GoalTemplateDetailsModal extends Component {
  render() {
    const {
      goal,
      goalPlanDuration,
      goalPlanDescription,
      selectedStartDate,
      onStartDateChanged,
      onAcceptButtonClick,
      onClose,
    } = this.props
    if (!goal) return null
    const {title, tasks, images} = goal
    const imageUrl = get(images, 'featured.servingUrl')

    const Duration = !goalPlanDuration ? null : (
      <DurationWrap>
        <IconDuration /> {moment.duration(goalPlanDuration, 'days').humanize()}
      </DurationWrap>
    )

    return (
      <Modal onClose={onClose}>
        <ImageHeaderWrap>
          <ImageHeader src={imageUrl}>
            <ImageHeaderTitle>
              <h2>{title}</h2>
            </ImageHeaderTitle>

            <ImageHeaderOverlay />
          </ImageHeader>

          <AcceptButton onClick={onAcceptButtonClick}>
            <FormattedMessage
              id="goal_template_details_modal_btn_accept"
              defaultMessage="Accept Goal"
            />
          </AcceptButton>
        </ImageHeaderWrap>

        <ModalContent>
          <TemplateDetailsBar>
            <div>
              <FormattedMessage
                id="goal_template_details_modal_input_start_date_label"
                defaultMessage="Starting"
              />
            </div>
            <DayPicker
              id="template-start-date"
              value={selectedStartDate}
              onChange={onStartDateChanged}
            />
            <div>{Duration}</div>
          </TemplateDetailsBar>

          <Description>{goalPlanDescription}</Description>

          <GoalPlan>{values(tasks).map(renderTask)}</GoalPlan>
        </ModalContent>
      </Modal>
    )
  }
}

export default GoalTemplateDetailsModal
