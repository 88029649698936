import styled from 'styled-components'
import IconButton from 'material-ui/IconButton'

const FloatingCircleButton = styled(IconButton)`
  border-radius: 100px;
  background: ${({color}) => color || '#fff'} !important;
  box-shadow: 0 2px 15px 0 rgba(50, 50, 95, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.08);
`

export default FloatingCircleButton
