import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {EMPTY} from 'rxjs'
import {map, mergeMap, startWith} from 'rxjs/operators'
import {has, get, values} from 'lodash'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {FirebaseApi} from '../../api'
import {createEnsureAuthenticatedEpic} from '../../utils/epic'
import {getLanguage} from '../app'

export const contentFavIdProps = {
  boost: 'boostId',
  course: 'courseId',
  'goal-template': 'templateId',
}

const createSinglePropReducer = (action, prop, initialState = {}) =>
  handleActions(
    {
      [action]: (state, action) => action.payload[prop],
    },
    initialState,
  )

export const markLessonRead = createAction('remente/userContent/markLessonRead')
export const updateUserHistory = createAction(
  'remente/userContent/updateUserHistory',
)
export const userContentChanged = createAction(
  'remente/userContent/userContentChanged',
)
export const markLessonReadFulfilled = createAction(
  'remente/userContent/markLessonReadFulfilled',
)
export const updateUserHistoryFulfilled = createAction(
  'remente/userContent/updateUserHistoryFulfilled',
)
export const toggleUserContentFavorite = createAction(
  'remente/userContent/toggleUserContentFavorite',
)
export const toggleUserContentFavoriteFulfilled = createAction(
  'remente/userContent/toggleUserContentFavoriteFulfilled',
)

const userProgressReducer = createSinglePropReducer(
  userContentChanged,
  'userProgress',
)
const userHistoryReducer = createSinglePropReducer(
  userContentChanged,
  'userHistory',
)
const userGoalsByIdReducer = createSinglePropReducer(
  userContentChanged,
  'userGoalsById',
)
const userJourneyEntriesByGoalIdReducer = createSinglePropReducer(
  userContentChanged,
  'userJourneyEntriesByGoalId',
)
const userDailyTaskStateReducer = createSinglePropReducer(
  userContentChanged,
  'userDailyTaskState',
)
const userTodoTasksByIdReducer = createSinglePropReducer(
  userContentChanged,
  'userTodoTasksById',
)
const userGoalOrderReducer = createSinglePropReducer(
  userContentChanged,
  'userGoalOrder',
)
const userContentFavoritesByIdReducer = createSinglePropReducer(
  userContentChanged,
  'userContentFavorites',
)

export default combineReducers({
  userProgress: userProgressReducer,
  userHistory: userHistoryReducer,
  userGoalsById: userGoalsByIdReducer,
  userJourneyEntriesByGoalId: userJourneyEntriesByGoalIdReducer,
  userDailyTaskState: userDailyTaskStateReducer,
  userTodoTasksById: userTodoTasksByIdReducer,
  userGoalOrder: userGoalOrderReducer,
  userContentFavoritesById: userContentFavoritesByIdReducer,
})

/**
 * Selectors
 */

const userProgressSelector = ({userContent}) => userContent.userProgress
const userHistorySelector = ({userContent}) => userContent.userHistory
const userGoalsByIdSelector = ({userContent}) => userContent.userGoalsById
const userJourneyEntriesByGoalIdSelector = ({userContent}) =>
  userContent.userJourneyEntriesByGoalId
const userDailyTaskStateSelector = ({userContent}) =>
  userContent.userDailyTaskState
const userTodoTasksByIdSelector = ({userContent}) =>
  userContent.userTodoTasksById
const userGoalOrderSelector = ({userContent}) => userContent.userGoalOrder
const getUserContentFavoritesById = ({userContent}) =>
  userContent.userContentFavoritesById

export const getMyUserProgressByLanguage = createSelector(
  userProgressSelector,
  userProgress => userProgress,
)

export const getCourseUserProgress = (state, {language, courseId}) => {
  const userProgress = getMyUserProgressByLanguage(state)
  return get(userProgress, [language, 'lessons', courseId], {})
}

export const getMyUserHistoryByLanguage = createSelector(
  userHistorySelector,
  userHistory => userHistory,
)

export const getMyGoalsById = createSelector(
  userGoalsByIdSelector,
  goalsById => goalsById,
)

export const getMyGoalOrder = createSelector(
  userGoalOrderSelector,
  goalOrder => goalOrder || {},
)

export const getMyGoalOrderActive = createSelector(getMyGoalOrder, goalOrder =>
  get(goalOrder, 'active', []),
)

export const getMyGoalOrderCompleted = createSelector(
  getMyGoalOrder,
  goalOrder => get(goalOrder, 'completed', []),
)

// TODO replace with getMyJourneyEntriesByGoalId
export const getUserJourneyEntriesByGoalId = createSelector(
  userJourneyEntriesByGoalIdSelector,
  userJourneyEntriesByGoalId => userJourneyEntriesByGoalId,
)

export const getMyJourneyEntriesByGoalId = createSelector(
  getUserJourneyEntriesByGoalId,
  userJourneyEntriesByGoalId => userJourneyEntriesByGoalId || {},
)

export const getMyDailyTaskState = createSelector(
  userDailyTaskStateSelector,
  userDailyTaskState =>
    userDailyTaskState || {
      lastUpdatedAt: 1403479285216,
      orderUpcoming: [],
      orderToday: [],
    },
)

export const getMyTodoTasksById = createSelector(
  userTodoTasksByIdSelector,
  userTodoTasksById => userTodoTasksById || {},
)

export const getUserContentFavorites = createSelector(
  getUserContentFavoritesById,
  favs => values(favs),
)

export const getUserContentFavoritesByContentId = createSelector(
  getUserContentFavorites,
  favs =>
    favs.reduce((acc, fav) => {
      const idProp = contentFavIdProps[fav.type]
      if (!idProp) return acc
      acc[fav[idProp]] = fav
      return acc
    }, {}),
)

/**
 * Epics
 */

const userContentChangedEpic = createEnsureAuthenticatedEpic({
  createObservables: ({uid}) => ({
    userProgress: FirebaseApi.observableRef(`user-progress/${uid}`).pipe(
      startWith({}),
    ),
    userHistory: FirebaseApi.observableRef(`user-history/${uid}`).pipe(
      startWith({}),
    ),
    userGoalsById: FirebaseApi.observableRef(`goals/${uid}`).pipe(
      startWith({}),
    ),
    userJourneyEntriesByGoalId: FirebaseApi.observableRef(
      `goal-journey-entries/${uid}`,
    ).pipe(startWith({})),
    userDailyTaskState: FirebaseApi.observableRef(
      `daily-task-state/${uid}`,
    ).pipe(startWith({})),
    userTodoTasksById: FirebaseApi.observableRef(`todo-tasks/${uid}`).pipe(
      startWith({}),
    ),
    userGoalOrder: FirebaseApi.observableRef(`goal-order/${uid}`).pipe(
      startWith({}),
    ),
    userContentFavorites: FirebaseApi.observableRef(
      `user-content-favorites/${uid}`,
    ).pipe(startWith({})),
  }),
  actionFulfilled: userContentChanged,
})

const markLessonReadEpic = (action$, state$) =>
  action$.pipe(
    ofType(markLessonRead().type),
    mergeMap(action => {
      const {userContent} = state$.value
      const {userProgress} = userContent

      const {language, courseId, lessonId} = action.payload
      const markedAsRead = has(userProgress, [
        language,
        'lessons',
        courseId,
        lessonId,
      ])

      if (markedAsRead) return EMPTY

      return FirebaseApi.markLessonRead({language, courseId, lessonId}).pipe(
        map(markLessonReadFulfilled),
      )
    }),
  )

const updateUserHistoryEpic = action$ =>
  action$.pipe(
    ofType(updateUserHistory().type),
    mergeMap(({payload}) => {
      const {language, type, id, title, imageUrl} = payload
      return FirebaseApi.updateUserHistory({
        language,
        type,
        id,
        title,
        imageUrl,
      }).pipe(map(updateUserHistoryFulfilled))
    }),
  )

const toggleUserContentFavoriteEpic = (action$, state$) =>
  action$.pipe(
    ofType(toggleUserContentFavorite().type),
    map(({payload}) => payload),
    mergeMap(({id, type, bookmark}) => {
      const language = getLanguage(state$.value)
      const idProp = contentFavIdProps[type]
      const o = bookmark
        ? FirebaseApi.removeUserContentFavorite(bookmark.id)
        : FirebaseApi.createUserContentFavorite({
            type,
            languageId: language,
            [idProp]: id,
          })
      return o.pipe(map(toggleUserContentFavoriteFulfilled))
    }),
  )

export const userContentEpics = combineEpics(
  userContentChangedEpic,
  markLessonReadEpic,
  updateUserHistoryEpic,
  toggleUserContentFavoriteEpic,
)
