import {connect} from 'react-redux'
import MainMenu from './MainMenu.js'
import {getIsElectron} from '../ducks/electron'
import {getPathname} from '../ducks/route'
import {getMyPulseOrganizationId} from '../ducks/pulse'

const mapStateToProps = state => ({
  _forceRefresh: getPathname(state),
  isElectron: getIsElectron(state),
  isPulseAvailable: !!getMyPulseOrganizationId(state),
})

const MainMenuContainer = connect(mapStateToProps)(MainMenu)

export default MainMenuContainer
