import styled from 'styled-components'

export const SvgContainer = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`

export const InnerCircle = styled.div`
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: 2;
  border-radius: 100%;
  ${({fill, stroke, strokeWidth = 1, image, radius = '20%'}) => `

    left: ${radius};
    top: ${radius};
    right: ${radius};
    bottom: ${radius};

    ${
      fill
        ? `
      background-color: ${fill};
    `
        : ''
    }

    ${
      stroke
        ? `
      box-shadow: 0 0 0 ${strokeWidth}px ${stroke};
    `
        : ''
    }

    ${
      image
        ? `
      background-image: url(${image});
    `
        : ''
    }

  `}
`
