import styled from 'styled-components'
import {truncate} from '../utils/css'

export const Wrap = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const IconWrap = styled.div`
  width: 47px;
`

export const TextFieldWrap = styled.div`
  flex: 1;
  position: relative;
`

export const TextField = styled.input`
  border: none;
  width: 100%;
  background: none;
  padding: 5px;
  pointer: cursor;
  &:hover {
    background: ${({theme: {colorMaskedInputBg}}) => colorMaskedInputBg};
    &:disabled {
      background: none;
    }
  }
  &::placeholder {
    color: #515151;
  }
  &:focus {
    background: none;
    pointer: auto;
    &::placeholder {
      color: #e0e0e4;
    }
  }
  &:disabled {
    color: #e0e0e4;
    &::placeholder {
      color: #e0e0e4;
    }
    cursor: not-allowed;
  }
`

export const InputOverlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 5px;
  right: 0;
  transform: translateY(-50%);
  opacity: 0.1;
  pointer-events: none;
  ${truncate()}
`
