import React from 'react'
import styled from 'styled-components'
import RaisedButton from 'material-ui/RaisedButton'
import appstore from '../graphics/stores/appstore.svg'
import googleplay from '../graphics/stores/googleplay.svg'
import IconGuide from 'material-ui/svg-icons/action/open-in-new'

import Modal, {ModalPadding} from './Modal.js'

const CenterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
  > * {
    margin: 0 0.5em;
  }
`

const MobileAppsInfo = (
  <CenterButtons>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://itunes.apple.com/app/apple-store/id961633456?pt=1659064&ct=remente-desktop%20-%20Store%20Badge&mt=8"
    >
      <img src={appstore} alt="Download on the App Store" />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.remente.app&referrer=utm_source%3Dremente-desktop%26utm_medium%3DStore%2520Badge"
    >
      <img src={googleplay} alt="Get it on Google Play" />
    </a>
  </CenterButtons>
)

const WelcomeModal = ({isElectron, onClose}) => (
  <Modal onClose={onClose}>
    <ModalPadding>
      <h2>Welcome to Remente</h2>
      <p>
        Remente is a system of tools and insights to help you lead a richer,
        happier and healthier life, based on how the brain works. Maintain focus
        and direction while managing stress and work-life balance, all in one
        app.
      </p>
      <p>
        Find out how your life is balancing and use our suggested goal plans and
        set smart goals to reach your dreams while keeping track of your daily
        to-dos. Tasks, routines, goals and habits in one place.
      </p>

      <p>
        Get Remente for your smartphone and sign in with your account to have
        your data synced securely across all your devices!
      </p>

      {!isElectron && MobileAppsInfo}

      <p>
        To get started with Remente we recommend everyone to take a look at our
        getting started guide.
      </p>

      <CenterButtons>
        <a
          href="https://help.remente.com/web-and-desktop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RaisedButton
            label="Getting Started Guide"
            primary={false}
            icon={<IconGuide />}
          />
        </a>
      </CenterButtons>

      <CenterButtons>
        <RaisedButton label="Continue" primary={true} onClick={onClose} />
      </CenterButtons>
    </ModalPadding>
  </Modal>
)

export default WelcomeModal
