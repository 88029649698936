import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {interval} from 'rxjs'
import {map, filter, switchMap} from 'rxjs/operators'
import moment from 'moment'

import {focusApp, blurApp} from '../app'

const INITAL_STATE = {
  currentTime: moment(),
  currentTimeMinute: moment().startOf('minute'),
}

export const startHeartbeat = createAction('remente/timing/startHeartbeat')
export const tickHeartbeat = createAction('remente/timing/tickHeartbeat')
export const tickMinuteHeartbeat = createAction(
  'remente/timing/tickMinuteHeartbeat',
)

/**
 * Reducers
 */

const currentTimeReducer = handleActions(
  {
    [tickHeartbeat().type]: () => moment(),
  },
  INITAL_STATE.currentTime,
)

const currentTimeMinuteReducer = handleActions(
  {
    [tickMinuteHeartbeat().type]: () => moment().startOf('minute'),
  },
  INITAL_STATE.currentTimeMinute,
)

export default combineReducers({
  currentTime: currentTimeReducer,
  currentTimeMinute: currentTimeMinuteReducer,
})

/**
 * Selectors
 */

const currentTimeSelector = ({timing}) => timing.currentTime
const currentTimeMinuteSelector = ({timing}) => timing.currentTimeMinute

export const getCurrentTime = createSelector(currentTimeSelector, time => time)

export const getCurrentTimeMinute = createSelector(
  currentTimeMinuteSelector,
  time => time,
)

/**
 * Epics
 */

const startHeartbeatEpic = action$ =>
  action$.pipe(
    ofType(startHeartbeat().type),
    switchMap(() =>
      interval(1000).pipe(
        filter(() => new Date().getSeconds() === 0),
        map(() => tickMinuteHeartbeat()),
      ),
    ),
  )

const focusAppEpic = action$ =>
  action$.pipe(
    ofType(focusApp().type, blurApp().type),
    map(() => tickMinuteHeartbeat()),
  )

export const timingEpics = combineEpics(startHeartbeatEpic, focusAppEpic)
