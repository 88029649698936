import React from 'react'
import styled from 'styled-components'

import ScrollTop from './ScrollTop.js'
import {createScrollbar} from '../utils/css'

const Wrap = styled(ScrollTop)`
  flex: 1;
  overflow-y: scroll;
  ${createScrollbar({
    theme: 'main',
    isMaster: true,
  })}
`

const ScrollViewMain = ({children, ...props}) => (
  <Wrap {...props}>{children}</Wrap>
)

export default ScrollViewMain
