import styled from 'styled-components'

export const Wrap = styled.div`
  background: #ffffd6;
`

export const CenterVertically = styled.div`
  display: flex;
  align-items: center;
`

export const Info = styled.div`
  padding-left: 5%;
  font-size: 1.2em;
  line-height: 1.5em;
  p {
    margin-bottom: 1.5em;
  }
`
