import {combineReducers} from 'redux'
import {createAction, handleActions} from 'redux-actions'
import {combineEpics, ofType} from 'redux-observable'
import {createSelector} from 'reselect'
import {combineLatest} from 'rxjs'
import {map, mergeMap} from 'rxjs/operators'
import {get, values, map as _map} from 'lodash'
import {FirebaseApi} from '../../api'
import {changeLanguage, getLanguage} from '../app'
import {setOrganizations} from '../organization'

const setAppFlavorIds = createAction('remente/appFlavor/setAppFlavorIds')
const setAppFlavors = createAction('remente/appFlavor/setAppFlavors')

const appFlavorIdsReducer = handleActions(
  {
    [setAppFlavorIds]: (state, {payload}) => payload,
    [setOrganizations]: (state, {payload}) => {
      const id = _map(values(payload), 'appFlavorId').filter(it => it)[0]
      if (id) return {[id]: true}
      return {default: true}
    },
  },
  {default: true},
)

const appFlavorsReducer = handleActions(
  {
    [setAppFlavors]: (state, {payload}) => payload,
  },
  [],
)

export default combineReducers({
  appFlavorIds: appFlavorIdsReducer,
  appFlavors: appFlavorsReducer,
})

/**
 * Selectors
 */

export const getAppFlavorIds = ({appFlavor}) => appFlavor.appFlavorIds
export const getAppFlavors = ({appFlavor}) => appFlavor.appFlavors
export const getAppFlavorResourcesTabs = createSelector(
  getAppFlavors,
  flavors =>
    flavors.reduce(
      (acc, flavor) => acc.concat(get(flavor, 'resourcesTabs', [])),
      [],
    ),
)

/**
 * Epics
 */

const appFlavorsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      changeLanguage().type,
      setAppFlavorIds().type,
      setOrganizations().type,
    ),
    mergeMap(() => {
      const flavorIds = Object.keys(getAppFlavorIds(state$.value))
      const language = getLanguage(state$.value)

      const observables = flavorIds.map(id =>
        combineLatest([
          FirebaseApi.observableRefGet(`app-flavors/${id}/content/${language}`),
          FirebaseApi.observableRefGet(
            `app-flavors/${id}/resources-tabs/${language}`,
          ),
        ]).pipe(
          map(([content, resourcesTabs]) => ({
            id,
            content,
            resourcesTabs,
          })),
        ),
      )
      return combineLatest(observables).pipe(map(setAppFlavors))
    }),
  )

export const appFlavorEpics = combineEpics(appFlavorsEpic)
