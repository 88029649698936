import {getRandomPlaceholderImage} from './'

const getRandomFeaturedImage = () => ({
  images: {
    featured: {
      servingUrl: getRandomPlaceholderImage(),
    },
  },
})

export {getRandomFeaturedImage}
