import {combineReducers} from 'redux'
import {createAction, handleActions} from 'redux-actions'
import {combineEpics, ofType} from 'redux-observable'
import {createSelector} from 'reselect'
import {combineLatest, EMPTY} from 'rxjs'
import {map, mergeMap, startWith} from 'rxjs/operators'
import {get} from 'lodash'
import {FirebaseApi} from '../../api'
import {changeLanguage, getLanguage} from '../app'

const setUserGroupIds = createAction('remente/userGroup/setUserGroupIds')
const setUserGroups = createAction('remente/userGroup/setUserGroups')

const userGroupIdsReducer = handleActions(
  {
    [setUserGroupIds]: (state, {payload}) => payload,
  },
  {default: true},
)

const userGroupsReducer = handleActions(
  {
    [setUserGroups]: (state, {payload}) => payload,
  },
  [],
)

export default combineReducers({
  userGroupIds: userGroupIdsReducer,
  userGroups: userGroupsReducer,
})

/**
 * Selectors
 */
export const getUserGroupIds = ({userGroup}) => userGroup.userGroupIds
export const getUserGroups = ({userGroup}) => userGroup.userGroups
export const getUserGroupsResourcesTabs = createSelector(
  getUserGroups,
  groups =>
    groups.reduce((acc, group) => {
      return acc.concat(get(group, 'extraResourcesTabs', []))
    }, []),
)

/**
 * Epics
 */

const userGroupsEpic = (action$, state$) =>
  action$.pipe(
    ofType(changeLanguage().type),
    mergeMap(() => {
      const language = getLanguage(state$.value)
      const observables = FirebaseApi.getUserGroups().pipe(
        /**
         * Filter out active user-group ids
         * In: Transform {'a' : true, 'b' : false}
         * Out: ['a']
         */
        startWith({}),
        map(it => {
          if (!it) return []
          return Object.keys(it).reduce((acc, key) => {
            const activeIds = []
            if (it[key] === true) {
              activeIds.push(key)
            }
            return acc.concat([...activeIds])
          }, [])
        }),
        /**
         * In: ['a', 'b', 'c']
         * Out: [
         *  { 'id' : a,
         *    'extraContent' : {
         *      'courses' : ...
         *      'boosts'  : ...
         *      'templates' : ...
         *     },
         *     'extraResourcesTabs' : {
         *       ...
         *     }
         * },
         * ]
         */
        mergeMap(userGroupIds => {
          if (!userGroupIds) return EMPTY
          if (userGroupIds.length === 0) return EMPTY

          const observables = userGroupIds.map(id =>
            combineLatest(
              FirebaseApi.observableRefGet(
                `user-groups/${id}/extraContent/${language}`,
              ),
              FirebaseApi.observableRefGet(
                `user-groups/${id}/extraResourcesTabs/${language}`,
              ),
              (content, tabs) => ({
                extraContent: content,
                extraResourcesTabs: tabs,
              }),
            ).pipe(map(it => ({id: id, ...it}))),
          )
          return combineLatest(observables)
        }),
      )
      return combineLatest(observables).pipe(
        map(it => it.flat()),
        map(setUserGroups),
      )
    }),
  )

export const userGroupEpics = combineEpics(userGroupsEpic)
