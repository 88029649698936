import styled from 'styled-components'
import chroma from 'chroma-js'
import ModalContentBase from './ModalContent.js'

export const ModalContent = styled(ModalContentBase)``

export const ImageHeaderWrap = styled.div`
  position: relative;
  padding-bottom: 30%;
`

export const ImageHeader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f6;
  background-size: cover;
  background-position: center;
  background-image: url(${({src}) => src});
  border-radius: 4px;
  overflow: hidden;
`

export const ImageHeaderTitle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
    color: #fff;
  }
  z-index: 1;
`

export const ImageHeaderOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
`

export const AcceptButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%) translateY(50%) translateZ(0px);
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: uppercase;
  border: none;
  color: #fff;
  background: ${({theme}) => theme.colorPrimary1};
  border-radius: 100px;
  padding: 1em 1.5em;
  cursor: pointer;
  transition: all 150ms ease-out;
  font-weight: 600;
  &:hover {
    background: ${({theme}) =>
      chroma(theme.colorPrimary1)
        .darken(0.5)
        .hex()};
  }
  &:active {
    background: ${({theme}) =>
      chroma(theme.colorPrimary1)
        .darken(0.8)
        .hex()};
    transform: translateX(-50%) translateY(50%) scale(0.9);
  }
  box-shadow: 0 2px 15px 0 rgba(50, 50, 95, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.08);
`

export const TemplateDetailsBar = styled.div`
  display: flex;
  justify-content: space-between;
  color: #aaa;
  margin-bottom: 2em;
  > div {
    display: flex;
    align-items: center;
    &:nth-child(2) {
      flex: 1;
      padding-top: 3px;
    }
  }
`

export const Description = styled.div`
  line-height: 1.5em;
  position: relative;
`

export const GoalPlan = styled.div`
  padding: 1em 0 0;
`

export const DurationWrap = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 0.3em;
  }
`
