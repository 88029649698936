import fuzzysearch from 'fuzzysearch'
import {combineReducers} from 'redux'
import {createAction, handleActions} from 'redux-actions'
import {createSelector} from 'reselect'
import {uniq, flatten} from 'lodash'

import {getGoalTemplateCategoriesPopulated} from '../goalTemplate'
import {historyChanged} from '../route'

const INITAL_STATE = {
  titleInputValue: '',
}

export const setTitleInputValue = createAction(
  'remente/goal/create/setTitleInputValue',
)

/**
 * Reducers
 */

const titleInputValueReducer = handleActions(
  {
    [setTitleInputValue]: (state, {payload}) => payload,
    [historyChanged]: () => INITAL_STATE.titleInputValue,
  },
  INITAL_STATE.titleInputValue,
)

export default combineReducers({
  titleInputValue: titleInputValueReducer,
})

/**
 * Selectors
 */

const titleInputValueSelector = ({createGoal}) => createGoal.titleInputValue

export const getTitleInputValue = createSelector(
  titleInputValueSelector,
  titleInputValue => titleInputValue,
)

export const getGoalTemplatesFilteredByGoalTitle = createSelector(
  getGoalTemplateCategoriesPopulated,
  getTitleInputValue,
  (categories, goalTitle) =>
    uniq(
      flatten(categories.map(({goalTemplates}) => goalTemplates)),
    ).filter(({title}) =>
      fuzzysearch(goalTitle.toLowerCase(), title.toLowerCase()),
    ),
)
