import moment from 'moment'
import {values, flatten, keyBy, get} from 'lodash'

export function compileJourneyEntryLookupMap({userJourneyEntriesByGoalId}) {
  if (!userJourneyEntriesByGoalId) return {}

  var result = {}

  for (var goalId in userJourneyEntriesByGoalId) {
    const goalJourneyEntries = userJourneyEntriesByGoalId[goalId]
    var goalResult = {}

    for (var entryId in goalJourneyEntries) {
      const entry = goalJourneyEntries[entryId]
      const {taskId, loggedAt} = entry
      const loggedAtDay = moment(loggedAt).format('YYMMDD')
      goalResult[taskId] = goalResult[taskId] || {}
      goalResult[taskId][loggedAtDay] = goalResult[taskId][loggedAtDay] || []
      goalResult[taskId][loggedAtDay].push({
        ...entry,
        goalId,
      })
    }

    result[goalId] = goalResult
  }

  return result
}

export function compileTodoList(params) {
  const {
    userGoalsById,
    userTodoTasksById,
    userDailyTaskState,
    userJourneyEntryLookup,
    dateTime,
  } = params
  const taskMap = getTaskMap({userGoalsById, userTodoTasksById})
  const orderToday = userDailyTaskState.orderToday || []
  const todayDay = moment(dateTime).format('YYMMDD')
  return orderToday
    .map(id => taskMap[id])
    .filter(task => !!task)
    .map(task => {
      const {id, goalId, checkedInAt} = task

      // Goal task
      if (goalId) {
        const journeyEntries = get(userJourneyEntryLookup, [
          goalId,
          id,
          todayDay,
        ])
        return {
          ...task,
          isCompleted: !!journeyEntries,
          completedAt: get(journeyEntries, '[0].loggedAt'),
          journeyEntryId: get(journeyEntries, '[0].id'),
          journeyEntries,
        }
      }

      // Todo task
      return {
        ...task,
        isCompleted: !!checkedInAt,
        completedAt: checkedInAt,
      }
    })
}

export function getActiveGoals(goals) {
  if (!goals) return []
  return goals.filter(filterGoalNotCompleted)
}

export function getCompletedGoals(goals) {
  if (!goals) return []
  return goals.filter(filterGoalCompleted)
}

export function getTasksFromGoals(goals) {
  const enhancedTasks = goals.map(({id, tasks}) => {
    if (!tasks) return []
    return values(tasks).map(task => ({
      ...task,
      goalId: id,
    }))
  })
  return flatten(enhancedTasks)
}

export function getRelevantTasksFromGoals({goals, dateTime = moment()}) {
  if (!goals || !goals.length) return []

  const goalsNotCompleted = getGoalsNotCompleted(goals)
  const tasks = getTasksFromGoals(goalsNotCompleted)
  const now = moment(dateTime)

  return tasks.filter(task => {
    const {type, startDate, endDate} = task
    switch (type) {
      case 'single':
        return !startDate || now.isSame(startDate, 'd')

      case 'repeating':
        return withinDates(now, startDate, endDate)

      default:
        return false
    }
  })
}

export function getRelevantTodoTasks({tasks, dateTime = moment()}) {
  if (!tasks) return []
  const tasksArr = Object.values(tasks)
  return tasksArr.filter(filterTasksCreatedAtFactory(dateTime))
}

export function getAutoPlannedTasks(tasks, now = moment()) {
  return tasks.filter(task => {
    if (task.type === 'repeating') return false
    const startDate = task.startDate || null
    return moment(now).isSame(startDate, 'd')
  })
}

export function filterGoalNotCompleted(goal) {
  return !goal.completedAt
}

export function filterGoalCompleted(goal) {
  return goal.completedAt
}

export function filterTasksCreatedAtFactory(date) {
  const m = moment(date)
  return task => m.isSame(task.createdAt, 'd')
}

export function getGoalsCompleted(goals) {
  return goals.filter(filterGoalCompleted)
}

export function getGoalsNotCompleted(goals) {
  return goals.filter(filterGoalNotCompleted)
}

/**
 * Private
 */

function getTaskMap({userGoalsById, userTodoTasksById}) {
  var goalTasks = keyBy(getTasksFromGoals(Object.values(userGoalsById)), 'id')
  return {
    ...userTodoTasksById,
    ...goalTasks,
  }
}

function withinDates(d, startDate, endDate) {
  if (startDate)
    return endDate
      ? d.isBetween(startDate, endDate, 'd', '[]')
      : d.isSameOrAfter(startDate, 'd')
  if (endDate) return d.isSameOrBefore(endDate, 'd')
}
