import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

export const ListItemCss = css`
  display: flex;
  align-items: center;
  padding: 0.5em 0;
  transition: all 150ms ease-out;
  broder-radius: 4px;
  line-height: 1.5em;
  &:last-child {
    border: none;
  }
  &:hover {
    ${({href}) => (href ? 'text-decoration: underline' : '')}
  }
`

export const ListItemLink = styled(Link)`
  ${ListItemCss}
`

export const ListItemDiv = styled.div`
  ${ListItemCss}
`
