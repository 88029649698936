import {connect} from 'react-redux'

import ComponentSwitch from './ComponentSwitch.js'

const components = {}

const mapStateToProps = ({app}) => ({
  components,
  selectedComponent: app.currentCover,
})

class CoverSwitchContainerClass extends ComponentSwitch {}

const CoverSwitchContainer = connect(mapStateToProps)(CoverSwitchContainerClass)

export default CoverSwitchContainer
