import moment from 'moment'
import firstBy from 'thenby'
import {get, values} from 'lodash'

import {isoWeekdayToDayId} from '../date'

const MAX_MINUTES = 1500

const sortByGoalTaskRemindersFactory = ({date}) => ({reminders}) => {
  if (!reminders) return MAX_MINUTES
  const single = extractSingle(reminders, date)
  const recurring = extractRecurring(reminders, date)
  const remindersByPrio = single
    .concat(recurring)
    .sort(firstBy('isToday', -1).thenBy(({minutes}) => minutes))
  return get(remindersByPrio, '[0].minutes', MAX_MINUTES)
}

const toMinutes = ({hour, minute}) => hour * 60 + minute

function extractRecurring({recurring}, date) {
  if (!recurring) return []
  const dayId = isoWeekdayToDayId(date.isoWeekday())
  return values(recurring).map(({days, time}) => {
    const minutes = toMinutes(time)
    const isToday = get(days, dayId)
    return {
      minutes,
      isToday,
    }
  })
}

function extractSingle({single}, date) {
  if (!single) return []
  return values(single).map(({notifyAt}) => {
    const notifyAtMoment = moment(notifyAt)
    const isToday = date.isSame(notifyAtMoment, 'day')
    const minutes = toMinutes({
      hour: notifyAtMoment.hours(),
      minute: notifyAtMoment.minutes(),
    })
    return {
      minutes,
      isToday,
    }
  })
}

export {sortByGoalTaskRemindersFactory}
