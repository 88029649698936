import {combineReducers} from 'redux'
import {createAction, handleActions} from 'redux-actions'
import {combineEpics, ofType} from 'redux-observable'
import {of} from 'rxjs'
import {map, mergeMap, takeUntil, startWith, catchError} from 'rxjs/operators'
import {FirebaseApi} from '../../api'

import {changeLanguage} from '../app'
import {userUnauthenticated} from '../auth'

export const subscribeCourseContent = createAction(
  'remente/content/subscribeCourseContent',
)
export const subscribeCourseContentRejected = createAction(
  'remente/content/subscribeCourseContentRejected',
)
export const subscribeCourseContentValue = createAction(
  'remente/content/subscribeCourseContentValue',
)

export const setCoursesById = createAction('remente/content/setCoursesById')
export const setCoursesByIdRejected = createAction(
  'remente/content/setCoursesByIdRejected',
)
export const setBoostsById = createAction('remente/content/setBoostsById')
export const setBoostsByIdRejected = createAction(
  'remente/content/setBoostsByIdRejected',
)
export const setGoalTemplateCategoriesById = createAction(
  'remente/content/setGoalTemplateCategoriesById',
)
export const setGoalTemplateCategoriesByIdRejected = createAction(
  'remente/content/setGoalTemplateCategoriesByIdRejected',
)
export const setGoalTemplatesById = createAction(
  'remente/content/setGoalTemplatesById',
)
export const setGoalTemplatesByIdRejected = createAction(
  'remente/content/setGoalTemplatesByIdRejected',
)
export const setContentTagsById = createAction(
  'remente/content/setContentTagsById',
)
export const setContentTagsByIdRejected = createAction(
  'remente/content/setContentTagsByIdRejected',
)
export const setCourseLessonContent = createAction(
  'remente/content/setCourseLessonContent',
)

const coursesByIdReducer = handleActions(
  {
    [setCoursesById]: (state, {payload}) => payload,
  },
  {},
)

const boostsByIdReducer = handleActions(
  {
    [setBoostsById]: (state, {payload}) => payload,
  },
  {},
)

const goalTemplateCategoriesByIdReducer = handleActions(
  {
    [setGoalTemplateCategoriesById]: (state, {payload}) => payload,
  },
  {},
)

const goalTemplatesByIdReducer = handleActions(
  {
    [setGoalTemplatesById]: (state, {payload}) => payload,
  },
  {},
)

const contentTagsByIdReducer = handleActions(
  {
    [setContentTagsById]: (state, {payload}) => payload,
  },
  {},
)

const courseContentByLessonIdReducer = handleActions(
  {
    [setCourseLessonContent]: (state, {payload: {lessonId, content}}) => ({
      ...state,
      [lessonId]: content,
    }),
  },
  {},
)

export default combineReducers({
  coursesById: coursesByIdReducer,
  boostsById: boostsByIdReducer,
  goalTemplateCategoriesById: goalTemplateCategoriesByIdReducer,
  goalTemplatesById: goalTemplatesByIdReducer,
  contentTagsById: contentTagsByIdReducer,
  courseContentByLessonId: courseContentByLessonIdReducer,
})

/**
 * Selectors
 */

export const getCoursesById = ({content}) => content.coursesById
export const getBoostsById = ({content}) => content.boostsById
export const getGoalTemplateCategoriesById = ({content}) =>
  content.goalTemplateCategoriesById
export const getGoalTemplatesById = ({content}) => content.goalTemplatesById
export const getContentTagsById = ({content}) => content.contentTagsById
export const getCourseContentByLessonId = ({content}) =>
  content.courseContentByLessonId

/**
 * Epics
 */

const createContentEpic = (collection, resolve, reject) => action$ =>
  action$.pipe(
    ofType(changeLanguage().type),
    map(({payload}) => payload.language),
    mergeMap(language =>
      FirebaseApi.observableRefGet(`${collection}/${language}`).pipe(
        startWith({}),
        takeUntil(action$.pipe(ofType(userUnauthenticated().type))),
        map(resolve),
        catchError(error => of(reject(error))),
      ),
    ),
  )

export const coursesByIdEpic = createContentEpic(
  'courses',
  setCoursesById,
  setCoursesByIdRejected,
)

export const boostsByIdEpic = createContentEpic(
  'boosts',
  setBoostsById,
  setBoostsByIdRejected,
)

export const goalTemplateCategoriesByIdEpic = createContentEpic(
  'goal-template-categories',
  setGoalTemplateCategoriesById,
  setGoalTemplateCategoriesByIdRejected,
)

export const goalTemplatesByIdEpic = createContentEpic(
  'goal-templates',
  setGoalTemplatesById,
  setGoalTemplatesByIdRejected,
)

export const contentTagsByIdEpic = createContentEpic(
  'content-tags',
  setContentTagsById,
  setContentTagsByIdRejected,
)

export const subscribeCourseContentEpic = action$ =>
  action$.pipe(
    ofType(subscribeCourseContent().type),
    map(({payload}) => payload),
    mergeMap(({language, courseId, lessonId}) =>
      FirebaseApi.observableRefGet(
        `course-content/${language}/${courseId}/${lessonId}`,
      ).pipe(map(content => setCourseLessonContent({lessonId, content}))),
    ),
  )

export const contentEpics = combineEpics(
  coursesByIdEpic,
  boostsByIdEpic,
  goalTemplateCategoriesByIdEpic,
  goalTemplatesByIdEpic,
  contentTagsByIdEpic,
  subscribeCourseContentEpic,
)
