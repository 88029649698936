import React, {Component} from 'react'
import {ContentApi} from '../api'

import CoverPhotoHeader from './CoverPhotoHeader.js'
import MainContainer from './MainContainer.js'
import ScrollViewMain from './ScrollViewMain.js'

class RouteResourceBoost extends Component {
  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps)
  }

  init(props) {
    if (props.loading) return
    const {language, id, title, imageUrl, updateUserHistory} = props
    updateUserHistory({
      type: 'boosts',
      language,
      id,
      title,
      imageUrl,
    })
  }

  render() {
    const {title, subTitle, imageUrl, content, loading} = this.props

    return (
      <ScrollViewMain>
        <CoverPhotoHeader image={imageUrl} loading={loading}>
          <h2>
            {title}
            <small>{subTitle}</small>
          </h2>
        </CoverPhotoHeader>

        <MainContainer horizontalPadding verticalPadding>
          {ContentApi.renderContentSegments(content)}
        </MainContainer>
      </ScrollViewMain>
    )
  }
}

export default RouteResourceBoost
