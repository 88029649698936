import {connect} from 'react-redux'

import EditGoalTaskModal from './EditGoalTaskModalContainer.js'
import EditJourneyEntryModal from './EditJourneyEntryModalContainer.js'
import EditMoodAssessmentModal from './EditMoodAssessmentModalContainer.js'
import GoalTemplateDetailsModal from './GoalTemplateDetailsModalContainer.js'

import ComponentSwitch from './ComponentSwitch.js'
import PaywallModal from './PaywallModalContainer.js'
import WelcomeModal from './WelcomeModalContainer.js'

const components = {
  'goal/task/edit': EditGoalTaskModal,
  'goal/journey-entry/edit': EditJourneyEntryModal,
  'goal-template/details': GoalTemplateDetailsModal,
  'mood-assessment/edit': EditMoodAssessmentModal,
  paywall: PaywallModal,
  welcome: WelcomeModal,
}

const mapStateToProps = ({app}) => ({
  components,
  selectedComponent: app.currentModal,
})

class ModalSwitchContainerClass extends ComponentSwitch {}

const ModalSwitchContainer = connect(mapStateToProps)(ModalSwitchContainerClass)

export default ModalSwitchContainer
