const taskDateFilterFactory = ({date}) => ({
  title,
  target,
  startDate,
  endDate,
}) => {
  if (title && !target)
    return !startDate || (startDate && date.isSame(startDate, 'day'))

  const startDateSatisfied =
    !startDate || (startDate && date.isSameOrAfter(startDate, 'day'))
  const endDateSatisfied =
    !endDate || (endDate && date.isSameOrBefore(endDate, 'day'))

  return startDateSatisfied && endDateSatisfied
}

export {taskDateFilterFactory}
