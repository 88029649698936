import React from 'react'
import styled from 'styled-components'
import {FormattedMessage} from 'react-intl'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 30px;
  font-weight: 100;
`

const AppLoading = () => (
  <Wrap>
    <FormattedMessage
      id="app_loading_message"
      defaultMessage="Welcome to Remente"
    />
  </Wrap>
)

export default AppLoading
