import React, {Component} from 'react'
import DatePicker from 'material-ui/DatePicker'
import moment from 'moment'

import DatePickerButtonInput from './DatePickerButtonInput.js'

class DayPicker extends Component {
  render() {
    const {placeholderText, value, ...rest} = this.props
    const formattedValue = value && value.format('ll')
    const dateValue = value && value.toDate()

    return (
      <div>
        <DatePickerButtonInput
          value={formattedValue}
          placeholderText={placeholderText}
          onClick={() => this.datePicker.openDialog()}
        />

        <DatePicker
          style={{display: 'none'}}
          mode="landscape"
          ref={datePicker => (this.datePicker = datePicker)}
          autoOk={true}
          value={dateValue}
          {...rest}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  handleChange = (e, date) => {
    const {onChange} = this.props
    if (!onChange) return
    onChange(moment(date))
  }
}

export default DayPicker
