import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/messaging'
import {Observable, from} from 'rxjs'
import {map} from 'rxjs/operators'
import moment from 'moment'
import {get, zipObject} from 'lodash'
import {getRandomFeaturedImage} from '../../utils/image'
import {LIFE_WHEEL_ID} from '../../constants'
import config from '../../config'

const {auth, database, storage, messaging, initializeApp} = firebase

class FirebaseApi {
  static TIMESTAMP = database.ServerValue.TIMESTAMP

  static getLocalTimestamp() {
    return moment().valueOf()
  }

  static initialize() {
    initializeApp(config.firebase.config)
    database.enableLogging(config.firebase.enableLogging)
    auth().useDeviceLanguage()

    if (
      process.env.isProduction &&
      'serviceWorker' in navigator &&
      messaging.isSupported()
    )
      navigator.serviceWorker
        .register('/sw.js', {scope: './'})
        .then(registration => messaging().useServiceWorker(registration))
  }

  static authenticate({provider, email, password}) {
    if (provider === 'EmailAuthProvider')
      return from(auth().signInWithEmailAndPassword(email, password))

    const authProvider = new auth[provider]()
    const req = auth().signInWithPopup(authProvider)
    return from(req)
  }

  static getRedirectResult() {
    const req = auth().getRedirectResult()
    return from(req)
  }

  static sendPasswordResetEmail({email}) {
    const req = auth()
      .sendPasswordResetEmail(email)
      .then(() => alert('Check your e-mail'))
    return from(req)
  }

  static registerUser({email, password, provider}) {
    if (provider === 'EmailAuthProvider')
      return from(auth().createUserWithEmailAndPassword(email, password))
    return this.authenticate({provider, email, password})
  }

  static onAuthStateChanged() {
    return Observable.create(observable =>
      auth().onAuthStateChanged(observable),
    )
  }

  static signOut() {
    const promise = auth().signOut()
    return from(promise)
  }

  static getCurrentUser() {
    return auth().currentUser
  }

  static getCurrentUid() {
    return auth().currentUser.uid
  }

  static connectionStatusObservableRef() {
    return this.observableRef('.info/connected')
  }

  static announcePresence({isElectron, user}) {
    const userId = this.getCurrentUid()
    const version = config.version
    const rootRef = this.dbRef('connected-clients').child(userId)
    const clientId = rootRef.push().key
    const ref = rootRef.child(clientId)
    ref.onDisconnect().remove()
    this.onlineRef = ref
    this.logLastSeenAt()
    return ref.set({
      userId,
      user,
      connectedAt: this.TIMESTAMP,
      platform: isElectron ? 'electron' : 'web',
      clientInfo: window.navigator.userAgent,
      version,
    })
  }
  static logLastSeenAt() {
    const userId = this.getCurrentUid()
    const p = `user-metadata/${userId}/lastSeenAt`
    return this.observableRefSet(p, this.TIMESTAMP)
  }

  static unannouncePresence() {
    if (this.onlineRef) this.onlineRef.remove()
    this.onlineRef = undefined
  }

  static getIdToken(force) {
    return auth().currentUser.getIdToken(force)
  }

  static getIdTokenObservable(force) {
    return from(this.getIdToken(force))
  }

  static getIdTokenResult() {
    return auth().currentUser.getIdTokenResult()
  }

  static dbRef(path) {
    return database().ref(path)
  }

  static observableRef(path, event = 'value', method = 'on') {
    return Observable.create(o => {
      const ref = database().ref(path)
      const eventHandler = ref[method](
        event,
        snap => o.next(snap.val()),
        err => o.error(err),
      )
      return () => ref.off(event, eventHandler)
    })
  }

  static observableRefGet(path) {
    const promise = database()
      .ref(path)
      .once('value')
      .then(snap => snap.val())
    return from(promise)
  }

  static fetchExercise({id, language}) {
    const path = `exercises/${language}/${id}`
    return this.observableRefGet(path)
  }

  static observableStorageRefPut({path, file}) {
    const ref = storage().ref(path)
    const promise = ref.put(file)
    return from(promise)
  }

  static observableRefSet(path, data) {
    const ref = database().ref(path)
    const promise = ref.set(data)
    return from(promise)
  }

  static observableRefRemove(path) {
    const ref = database().ref(path)
    const promise = ref.remove()
    return from(promise)
  }

  static observableRefUpdate(path, data) {
    const ref = database().ref(path)
    const promise = ref.update(data)
    return from(promise)
  }

  static observableRefPush(path, d) {
    const ref = database().ref(path)
    const id = ref.push().key
    const data = {
      ...d,
      id,
    }
    const promise = ref
      .child(id)
      .set(data)
      .then(() => data)
    return from(promise)
  }

  static markLessonRead({
    uid = this.getCurrentUid(),
    language,
    courseId,
    lessonId,
  }) {
    const p = `user-progress/${uid}/${language}/lessons/${courseId}/${lessonId}`
    return this.observableRefSet(p, {
      completedAt: this.TIMESTAMP,
    })
  }

  static updateUserHistory({
    uid = this.getCurrentUid(),
    language,
    type,
    id,
    title,
    imageUrl,
  }) {
    const p = `user-history/${uid}/${language}/${type}/${id}`
    return this.observableRefSet(p, {
      title,
      imageUrl,
      readAt: this.TIMESTAMP,
    })
  }

  static updateUserDayplans({uid = this.getCurrentUid(), dayplans}) {
    const updates = {}

    dayplans.forEach(
      ({
        datePath: [year, month, day],
        plannedTaskOrder,
        postponedTaskOrder,
      }) => {
        const p = `year-${year}/month-${month}/day-${day}`
        updates[`${p}/updatedAt`] = this.TIMESTAMP
        if (plannedTaskOrder) {
          updates[`${p}/plannedTaskOrder`] = plannedTaskOrder
          updates[`${p}/plannedTasks`] = zipObject(
            plannedTaskOrder,
            plannedTaskOrder.map(() => true),
          )
        }
        if (postponedTaskOrder) {
          updates[`${p}/postponedTaskOrder`] = postponedTaskOrder
          updates[`${p}/postponedTasks`] = zipObject(
            postponedTaskOrder,
            postponedTaskOrder.map(() => true),
          )
        }
      },
    )

    return this.observableRefUpdate(`user-dayplans2/${uid}`, updates)
  }

  static updateGoalOrder({uid = this.getCurrentUid(), ...updates}) {
    const p = `goal-order/${uid}`
    return this.observableRefUpdate(p, updates)
  }

  static createTodoTask({uid = this.getCurrentUid(), title}) {
    const p = `todo-tasks/${uid}`
    return this.observableRefPush(p, {
      title,
      createdAt: this.TIMESTAMP,
    })
  }

  static createAndPlanTodoTask({
    uid = this.getCurrentUid(),
    title,
    datePath: [year, month, date],
  }) {
    const id = database()
      .ref(`todo-tasks/${uid}`)
      .push().key
    const dayplanPath = `user-dayplans2/${uid}/year-${year}/month-${month}/day-${date}`
    const task = {id, title, createdAt: this.TIMESTAMP}
    const updates = {
      [`todo-tasks/${uid}/${id}`]: task,
      [`${dayplanPath}/plannedTasks/${id}`]: true,
      [`${dayplanPath}/updatedAt`]: this.TIMESTAMP,
    }
    return this.observableRefUpdate('/', updates).pipe(map(() => task))
  }

  static deleteTodoTask({uid = this.getCurrentUid(), id}) {
    const p = `todo-tasks/${uid}/${id}`
    return this.observableRefRemove(p)
  }

  static deleteAndUnplanTodoTask({
    uid = this.getCurrentUid(),
    id,
    datePath: [year, month, date],
  }) {
    const dayplanPath = `user-dayplans2/${uid}/year-${year}/month-${month}/day-${date}`
    const updates = {
      [`todo-tasks/${uid}/${id}`]: null,
      [`${dayplanPath}/plannedTasks/${id}`]: null,
      [`${dayplanPath}/updatedAt`]: this.TIMESTAMP,
    }
    return this.observableRefUpdate('/', updates)
  }

  static setTodoTaskTitle({uid = this.getCurrentUid(), id, title}) {
    const p = `todo-tasks/${uid}/${id}/title`
    return this.observableRefSet(p, title)
  }

  static setGoalTaskTitle({
    uid = this.getCurrentUid(),
    organizationId,
    goalId,
    id,
    title,
  }) {
    const p = `goals/${organizationId || uid}/${goalId}/tasks/${id}/title`
    return this.observableRefSet(p, title)
  }

  static toggleTodoTaskCompleted({
    uid = this.getCurrentUid(),
    taskId,
    isCompleted,
    loggedAt,
  }) {
    const p = `todo-tasks/${uid}/${taskId}/checkedInAt`
    const value = isCompleted ? null : loggedAt || this.getLocalTimestamp()
    return this.observableRefSet(p, value)
  }

  static toggleGoalJourneyEntry({
    uid = this.getCurrentUid(),
    organizationId,
    task,
    journeyEntryId,
    loggedAt,
  }) {
    const taskCompletedAtPath = `goals/${organizationId || uid}/${
      task.goalId
    }/tasks/${task.id}/completedAt`
    const goalUpdatedAtPath = `goals/${organizationId || uid}/${
      task.goalId
    }/updatedAt`
    const goalJourneyEntriesPath = `goal-journey-entries/${organizationId ||
      uid}/${task.goalId}`
    const updates = {}

    if (journeyEntryId) {
      updates[`${goalJourneyEntriesPath}/${journeyEntryId}`] = null
      updates[taskCompletedAtPath] = null
      return this.observableRefUpdate('/', updates)
    }

    const createdAt = this.TIMESTAMP
    loggedAt = loggedAt || createdAt
    const id = database()
      .ref(goalJourneyEntriesPath)
      .push().key
    updates[`${goalJourneyEntriesPath}/${id}`] = {
      id,
      createdAt,
      loggedAt,
      taskId: task.id,
    }
    if (task.numCheckinsMax && task.numCheckinsTotal + 1 >= task.numCheckinsMax)
      updates[taskCompletedAtPath] = loggedAt
    updates[goalUpdatedAtPath] = this.TIMESTAMP
    return this.observableRefUpdate('/', updates)
  }

  static setGoalTaskCompletedAt({
    uid = this.getCurrentUid(),
    goalId,
    taskId,
    isCompleted,
  }) {
    var updates = {}
    updates[`tasks/${taskId}/completedAt`] = !isCompleted
      ? null
      : this.TIMESTAMP
    updates['updatedAt'] = this.TIMESTAMP

    const p = `goals/${uid}/${goalId}`
    return this.observableRefSet(p, updates)
  }

  static deleteGoal({uid = this.getCurrentUid(), id, organizationId}) {
    const updates = {
      [`goals/${organizationId || uid}/${id}`]: null,
    }

    if (organizationId) {
      updates[
        `organization-goals-by-user/${organizationId}/${uid}/${id}`
      ] = null
    }

    return this.observableRefUpdate('/', updates)
  }

  static setGoalCompleted({uid = this.getCurrentUid(), id, organizationId}) {
    const updates = {}
    updates['completedAt'] = this.TIMESTAMP
    updates['updatedAt'] = this.TIMESTAMP
    const p = `goals/${organizationId || uid}/${id}`
    return this.observableRefUpdate(p, updates)
  }

  static setGoalActive({uid = this.getCurrentUid(), id, organizationId}) {
    const updates = {}
    updates['completedAt'] = null
    updates['updatedAt'] = this.TIMESTAMP
    const p = `goals/${organizationId || uid}/${id}`
    return this.observableRefUpdate(p, updates)
  }

  static updateJourneyEntry({
    uid = this.getCurrentUid(),
    organizationId,
    goalId,
    id,
    notes = '',
  }) {
    var updates = {}
    updates['attachments/notes'] = notes.length ? notes : null
    const p = `goal-journey-entries/${organizationId || uid}/${goalId}/${id}`
    return this.observableRefUpdate(p, updates)
  }

  static createGoal({uid = this.getCurrentUid(), goal, organizationId}) {
    const updates = {}
    const images = getRandomFeaturedImage()

    let goalData = {
      createdAt: this.TIMESTAMP,
      updatedAt: this.TIMESTAMP,
      ...images,
      ...goal,
    }

    if (organizationId) {
      updates[
        `organization-goals-by-user/${organizationId}/${uid}/${goalData.id}`
      ] = true
      goalData.assignedTo = {userId: uid}
    }

    updates[`goals/${organizationId || uid}/${goal.id}`] = goalData

    return this.observableRefUpdate('/', updates)
  }

  static updateGoal({uid = this.getCurrentUid(), organizationId, id, ...rest}) {
    const p = `goals/${organizationId || uid}/${id}`
    rest['updatedAt'] = this.TIMESTAMP
    return this.observableRefUpdate(p, {...rest})
  }

  static updateGoalTask({
    uid = this.getCurrentUid(),
    organizationId,
    goalId,
    task,
  }) {
    const {assignedUsers, ...taskProperties} = task
    const updates = {}

    const newGoal = {
      title: taskProperties.title,
      createdAt: this.TIMESTAMP,
      origin: {
        goalId,
        taskId: taskProperties.id,
      },
    }

    if (taskProperties.description)
      newGoal.description = taskProperties.description

    const linkedTo = {...taskProperties.linkedTo}

    const removedUserIds = Object.keys(linkedTo).filter(
      id => assignedUsers.indexOf(id) === -1,
    )

    const addedUserIds = assignedUsers.filter(id => !get(linkedTo, id, false))

    addedUserIds.forEach(userId => {
      const newGoalId = this.newId()
      updates[`goals/${organizationId}/${newGoalId}`] = {
        ...newGoal,
        id: newGoalId,
        assignedTo: {userId},
      }
      updates[
        `organization-goals-by-user/${organizationId}/${userId}/${newGoalId}`
      ] = true
      linkedTo[userId] = {userId, goalId: newGoalId}
    })

    removedUserIds.forEach(userId => {
      linkedTo[userId] = null
    })

    updates[
      `goals/${organizationId || uid}/${goalId}/tasks/${taskProperties.id}`
    ] = {
      ...taskProperties,
      linkedTo,
    }
    updates[
      `goals/${organizationId || uid}/${goalId}/updatedAt`
    ] = this.TIMESTAMP

    return this.observableRefUpdate('/', updates)
  }
  /** Write /goals/${userId}/${goalId}/updatedAt
  - When created
  - When updating name
  - When updating goal image
  - When marking as completed
  - When marking as active
  - When adding a task
  - When deleting a task
  - When editing a task
  - When marking task as completed
  - When marking task as active
   */
  static setGoalUpdatedAt({
    organizationId,
    goalId,
    uid = this.getCurrentUid(),
  }) {
    const p = `goals/${organizationId || uid}/${goalId}/updatedAt`
    this.observableRefSet(p, this.TIMESTAMP)
  }
  static setGoalTitle({uid = this.getCurrentUid(), organizationId, id, title}) {
    var updates = {}
    updates['updatedAt'] = this.TIMESTAMP
    updates['title'] = title
    const p = `goals/${organizationId || uid}/${id}`
    return this.observableRefUpdate(p, updates)
  }

  static addGoalTask({
    uid = this.getCurrentUid(),
    organizationId,
    goalId,
    title,
  }) {
    const p = `goals/${organizationId || uid}/${goalId}/tasks`
    const updatedAtPath = `goals/${organizationId || uid}/${goalId}/updatedAt`
    this.observableRefSet(updatedAtPath, this.TIMESTAMP)

    return this.observableRefPush(p, {
      createdAt: this.TIMESTAMP,
      title,
    })
  }

  static deleteGoalTask({
    uid = this.getCurrentUid(),
    organizationId,
    goalId,
    id,
  }) {
    var updates = {}
    updates[`tasks/${id}`] = null
    updates['updatedAt'] = this.TIMESTAMP
    const p = `goals/${organizationId || uid}/${goalId}`
    return this.observableRefUpdate(p, updates)
  }

  static getNewTodoTaskId() {
    const uid = this.getCurrentUid()
    const p = `todo-tasks/${uid}`
    const ref = database().ref(p)
    return ref.push().key
  }

  static createOrUpdateWheelAssessment({
    uid = this.getCurrentUid(),
    wheelId = LIFE_WHEEL_ID,
    ...data
  }) {
    const pathWheel = `wheel-assessments/${uid}/${wheelId}`
    if (!data.id)
      return this.observableRefPush(pathWheel, {
        createdAt: this.TIMESTAMP,
        ...data,
      })
    const pathAssessment = `${pathWheel}/${data.id}`
    return this.observableRefUpdate(pathAssessment, data)
  }

  static createOrUpdateMoodAssessment({uid = this.getCurrentUid(), ...data}) {
    const p = `mood-assessments/${uid}`
    if (!data.id)
      return this.observableRefPush(p, {
        createdAt: this.TIMESTAMP,
        ...data,
      })
    const pathAssessment = `${p}/${data.id}`
    return this.observableRefUpdate(pathAssessment, data)
  }

  static setImageRef({file, rootStoragePath, rootDbPath}) {
    const imageId = database()
      .ref(rootStoragePath)
      .push().key
    const fileExt = file.name.split('.').pop()
    const p = `${rootStoragePath}/web-${imageId}.${fileExt}`
    return storage()
      .ref(p)
      .put(file)
      .then(res =>
        res.ref.getDownloadURL().then(downloadUrl => ({
          res,
          downloadUrl,
        })),
      )
      .then(({res, downloadUrl}) => {
        const {metadata} = res
        return database()
          .ref(rootDbPath)
          .set({
            downloadUrl,
            storagePath: metadata.fullPath,
          })
          .then(() => res)
      })
  }

  static setImageRefObservable(params) {
    const promise = this.setImageRef({...params})
    return from(promise)
  }

  static newId() {
    return database()
      .ref()
      .push().key
  }

  static newIdGenerator() {
    return () => () => this.newId()
  }

  static fcmRequestPermission() {
    if (!messaging.isSupported())
      return Promise.reject(new Error('FB messaging unsupported'))
    const promise = messaging().requestPermission()
    return from(promise)
  }

  static fcmGetToken() {
    if (!messaging.isSupported())
      return Promise.reject(new Error('FB messaging unsupported'))
    const promise = messaging().getToken()
    return from(promise)
  }

  static fcmAssociateTokenWithUser({
    uid = this.getCurrentUid(),
    registrationId,
  }) {
    const p = `fcm-device-tokens/${uid}/${registrationId}`
    return this.observableRefSet(p, {
      createdAt: this.TIMESTAMP,
      platform: 'web',
      registrationId,
    })
  }

  static fcmDisassociateTokenWithUser({
    uid = this.getCurrentUid(),
    registrationId,
  }) {
    const p = `fcm-device-tokens/${uid}/${registrationId}`
    return this.observableRefRemove(p)
  }

  static setPreferredLanguage({uid = this.getCurrentUid(), language}) {
    const p = `user-settings/${uid}/language`
    return this.observableRefSet(p, language)
  }

  static getPaywallOffers({isMacAppStoreBuild}) {
    const platform = isMacAppStoreBuild ? 'macos' : 'desktop'
    const p = `paywall/active-subscriptions/${platform}`
    return database()
      .ref(p)
      .once('value')
      .then(snap => snap.val())
  }

  static createIapPendingReceipt({uid, idToken, purchaseDetails, receipt}) {
    const p = `/iap/pending-receipts/apple/${uid}`
    const data = {
      idToken,
      purchaseDetails,
      receipt,
    }
    return this.observableRefSet(p, data)
  }

  static getCurrentUsersPendingReceiptsRef() {
    const uid = this.getCurrentUid()
    const p = `/iap/pending-receipts/apple/${uid}`
    return database().ref(p)
  }

  static createUserContentFavorite(favorite) {
    const uid = this.getCurrentUid()
    const p = `/user-content-favorites/${uid}`
    return this.observableRefPush(p, {
      ...favorite,
      createdAt: this.TIMESTAMP,
    })
  }

  static removeUserContentFavorite(id) {
    const uid = this.getCurrentUid()
    const p = `/user-content-favorites/${uid}/${id}`
    return this.observableRefRemove(p)
  }

  static getUserGroups() {
    const uid = this.getCurrentUid()
    const p = `user-groups-by-user/${uid}`
    return this.observableRef(p)
  }
}

export {FirebaseApi}
