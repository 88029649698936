import React from 'react'
import styled from 'styled-components'

export const FormSection = styled.div`
  padding: 1.2em 50px;
  border-top: 2px solid #f4f4f6;
  &:last-child {
    border-bottom: 2px solid #f4f4f6;
  }
`

export const FormSectionContent = styled.div`
  margin-top: 0.5em;
`

const InputHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InputHeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 600;
  height: 32px;
`

export const InputHeaderIcon = styled.div`
  margin-right: 0.5em;
`

const renderIcon = Icon => <InputHeaderIcon>{Icon}</InputHeaderIcon>

const InputHeader = ({children, icon}) => (
  <InputHeaderWrap>
    {icon && renderIcon(icon)}
    {children}
  </InputHeaderWrap>
)

export default InputHeader
