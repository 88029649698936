import styled from 'styled-components'
import chroma from 'chroma-js'

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: -1;
  width: ${({theme}) => theme.heightAppHeader}px;
  background: ${({theme}) => theme.colorMainMenuBg};
  -webkit-font-smoothing: subpixel-antialiased;
  transform: scale(1) translateZ(0px);
  overflow: hidden;

  ${({isElectron}) =>
    isElectron
      ? `
    margin-top: 36px;
    -webkit-app-region: drag;
  `
      : ''}

  a {
    position: relative;
    width: 76px;
    height: 76px;
    color: ${({theme}) => theme.colorMainMenuFg};
    svg,
    span {
      pointer-events: none;
    }
    &.active {
      svg {
        transform: scale(1) translateY(0);
      }
      span {
        opacity: 1;
        transform: scale(1) translateY(5px);
      }
    }
  }

  a,
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({theme}) => theme.colorMainMenuBtnBg};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 2px;
    span {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: bold;
      bottom: 10px;
      transition: all 150ms ease-out;
      transform: scale(0.8) translateY(-2px);
      opacity: 0;
      color: ${({theme}) => theme.colorMainMenuFg};
    }
    svg {
      width: 35%;
      opacity: 0.95;
      transition: all 150ms ease-out;
    }
  }

  &:hover {
    li {
      &:hover {
        svg {
          opacity: 1;
          transform: scale(1) translateY(-10%);
        }
        span {
          opacity: 1;
        }
      }
      &:last-child {
        margin-bottom: none;
      }
      svg {
        transform: scale(0.85) translateY(-10%);
        opacity: 0.8;
      }
      span {
        opacity: 0.8;
        transform: scale(1) translateY(0);
      }
    }
  }

  a:active {
    svg,
    span {
      transform: scale(0.85) translateY(0) !important;
      transition: all 50ms ease-out !important;
    }
  }
`

export const Separator = styled.li`
  height: 1px;
  ${({theme}) => `
    background: ${chroma(theme.colorMainMenuBg)
      .brighten(0.5)
      .hex()};
    border-top: 1px solid ${chroma(theme.colorMainMenuBg)
      .darken(0.4)
      .hex()};
  `}
`
