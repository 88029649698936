import moment from 'moment'
import firstBy from 'thenby'
import {combineReducers} from 'redux'
import {createSelector} from 'reselect'
import {createAction, handleActions} from 'redux-actions'
import {get, sum, keys, values, flatten, groupBy, take} from 'lodash'

import {getMyMoodAssessments} from '../moodAssessment'
import {getMoodTagsById} from '../moodTag'

const MOOD_RATING_CHART_GROUP_PAIRS = {
  year: 'month',
  month: 'week',
  week: 'day',
  day: 'hour',
}

const NUM_MOOD_TAG_CLOUD_TAGS = 18
const NUM_CHECKINS_START = 3
const NUM_CHECKINS_INC = 30

export const changeStartDate = createAction(
  'remente/moodStatistics/changeStartDate',
)
export const changeStartDateFilter = createAction(
  'remente/moodStatistics/changeStartDateFilter',
)
export const changeCheckinFilter = createAction(
  'remente/moodStatistics/changeCheckinFilter',
)
export const showMoreCheckins = createAction(
  'remente/moodStatistics/showMoreCheckins',
)

/**
 * Reducers
 */

const startDateFilterReducer = handleActions(
  {
    [changeStartDateFilter]: (state, {payload}) => payload,
  },
  'week',
)

const startDateReducer = handleActions(
  {
    [changeStartDate]: (state, {payload}) => payload,
    [changeStartDateFilter]: (state, {payload}) =>
      moment().subtract(1, payload),
  },
  moment().subtract(1, 'week'),
)

const checkinFilterReducer = handleActions(
  {
    [changeCheckinFilter]: (state, {payload}) => payload,
  },
  null,
)

const numCheckinsMaxReducer = handleActions(
  {
    [changeStartDateFilter]: () => NUM_CHECKINS_START,
    [showMoreCheckins]: num => num + NUM_CHECKINS_INC,
  },
  NUM_CHECKINS_START,
)

export default combineReducers({
  startDate: startDateReducer,
  startDateFilter: startDateFilterReducer,
  checkinFilter: checkinFilterReducer,
  numCheckinsMax: numCheckinsMaxReducer,
})

/**
 * Selectors
 */

const startDateSelector = ({moodStatistics}) => moodStatistics.startDate
const startDateFilterSelector = ({moodStatistics}) =>
  moodStatistics.startDateFilter
const checkinFilterSelector = ({moodStatistics}) => moodStatistics.checkinFilter
const numCheckinsMaxSelector = ({moodStatistics}) =>
  moodStatistics.numCheckinsMax

export const getStartDate = createSelector(
  startDateSelector,
  startDate => startDate,
)
export const getStartDateFilter = createSelector(
  startDateFilterSelector,
  startDateFilter => startDateFilter,
)
export const getCheckinFilter = createSelector(
  checkinFilterSelector,
  checkinFilter => checkinFilter,
)
export const getNumCheckinsMax = createSelector(
  numCheckinsMaxSelector,
  max => max,
)

const filterByStartDateSelector = createSelector(
  getMyMoodAssessments,
  startDateSelector,
  (moodAssessments, startDate) =>
    moodAssessments.filter(({createdAt}) =>
      startDate.isSameOrBefore(createdAt, 'day'),
    ),
)

const attachTagsSelector = createSelector(
  filterByStartDateSelector,
  getMoodTagsById,
  (assessments, tagsById) =>
    assessments.map(assessment => {
      const moodTags = keys(assessment['mood-tags'])
        .map(tagId => tagsById[tagId])
        .filter(tag => !!tag)
      return {
        ...assessment,
        moodTags,
      }
    }),
)

const sortDescSelector = createSelector(attachTagsSelector, assessments =>
  assessments.sort((a, b) => b.createdAt - a.createdAt),
)

const filterByCheckinFilterSelector = createSelector(
  sortDescSelector,
  getCheckinFilter,
  (assessments, checkinFilter) => {
    if (!checkinFilter) return assessments
    return assessments.filter(({rating, notes}) =>
      checkinFilter === 'notes' ? !!notes : rating === checkinFilter,
    )
  },
)

const moodTagsSelector = createSelector(attachTagsSelector, assessments =>
  flatten(assessments.map(({moodTags}) => moodTags)),
)

const positiveMoodTagsSelector = createSelector(moodTagsSelector, tags =>
  tags.filter(({isPositive}) => isPositive),
)

const negativeMoodTagsSelector = createSelector(moodTagsSelector, tags =>
  tags.filter(({isPositive}) => !isPositive),
)

export const getNumMoodTags = createSelector(
  moodTagsSelector,
  tags => tags.length,
)
export const getNumPositiveMoodTags = createSelector(
  positiveMoodTagsSelector,
  tags => tags.length,
)
export const getNumNegativeMoodTags = createSelector(
  negativeMoodTagsSelector,
  tags => tags.length,
)

export const getMoodRatingChartGroupByProp = createSelector(
  getStartDateFilter,
  filter => MOOD_RATING_CHART_GROUP_PAIRS[filter] || 'day',
)

const moodRatingChartData = createSelector(
  filterByStartDateSelector,
  getMoodRatingChartGroupByProp,
  (assessments, groupByProp) => {
    const extra = assessments.map(assessment => ({
      groupKey: +moment(assessment.createdAt).startOf(groupByProp),
      ...assessment,
    }))
    const grouped = groupBy(extra, 'groupKey')

    const chartData = []
    let excludeZ = true
    let firstNum

    for (const key in grouped) {
      const as = grouped[key]
      if (!firstNum) firstNum = as.length
      if (firstNum !== as.length) excludeZ = false
      const x = parseInt(key, 10)
      const ratingSum = sum(as.map(({rating}) => rating))
      const y = ratingSum / as.length
      const z = as.length
      chartData.push({x, y, z})
    }

    excludeZ = assessments.length < 2 || excludeZ

    return excludeZ ? chartData.map(({x, y}) => ({x, y})) : chartData
  },
)

export const getChartData = createSelector(moodRatingChartData, data =>
  data.sort(firstBy('x')),
)

export const getCheckins = createSelector(
  filterByCheckinFilterSelector,
  assessments => assessments,
)

export const getNumCheckins = createSelector(
  filterByCheckinFilterSelector,
  assessments => assessments.length,
)

export const getNumCheckinsHidden = createSelector(
  getNumCheckins,
  getNumCheckinsMax,
  (numCheckins, numCheckinsMax) => Math.max(0, numCheckins - numCheckinsMax),
)

export const getHasMoreCheckins = createSelector(
  getNumCheckinsHidden,
  num => num > 0,
)

export const getCheckinsByDate = createSelector(
  getCheckins,
  getNumCheckinsMax,
  (assessments, numCheckinsMax) => {
    const keyed = assessments.slice(0, numCheckinsMax).map(assessment => ({
      ...assessment,
      day: moment(assessment.createdAt)
        .startOf('day')
        .valueOf(),
    }))
    const grouped = groupBy(keyed, 'day')
    return values(grouped)
  },
)

const moodTagsByIdSelector = createSelector(attachTagsSelector, assessments =>
  groupBy(flatten(assessments.map(({moodTags}) => moodTags)), 'id'),
)

const moodTagsCountSelector = createSelector(moodTagsByIdSelector, tags =>
  keys(tags).map(id => {
    const tag = tags[id]
    const count = tag.length
    const singleTag = tag[0]
    const {title, isPositive} = singleTag
    return {id, title, isPositive, count}
  }),
)

export const getMoodTagCloud = createSelector(moodTagsCountSelector, tags =>
  take(tags.sort(firstBy('count', -1)), NUM_MOOD_TAG_CLOUD_TAGS),
)

export const getMoodTagCloudMin = createSelector(getMoodTagCloud, tags =>
  tags.length === 0
    ? []
    : get(
        tags,
        [Math.min(tags.length - 1, NUM_MOOD_TAG_CLOUD_TAGS - 1), 'count'],
        0,
      ),
)

export const getMoodTagCloudMax = createSelector(getMoodTagCloud, tags =>
  get(tags, [0, 'count'], 0),
)
