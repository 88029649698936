import React, {Component} from 'react'

import {
  Overlay,
  ModalBox,
  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
} from './Modal.styles.js'

class Modal extends Component {
  handleClose = ({e, overlay}) => {
    const {onClose} = this.props
    if (!onClose || e.currentTarget !== e.target) return
    onClose({overlay})
  }

  render() {
    const {children, fullWidth} = this.props
    return (
      <Overlay onClick={e => this.handleClose({e, overlay: true})}>
        <ModalBox fullWidth={fullWidth}>{children}</ModalBox>
      </Overlay>
    )
  }
}

export {
  Modal as default,
  ModalPadding,
  ModalHeader,
  ModalTitle,
  ModalSubtle,
  ModalFooter,
}
