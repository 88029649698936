import React from 'react'
import Slider from 'material-ui/Slider'
import {get} from 'lodash'
import {FormattedMessage} from 'react-intl'

const sliderStyle = {
  marginBottom: 0,
}

const defaultMessage = `{frequency, plural,
  =0 {This step occurs only once.}
  one {One time each week}
  other {# times each week}
}`

const TargetPicker = ({target, isRepeating, onFrequencyChange}) => {
  const targetFrequency = get(target, 'frequency', 1)
  const frequency = isRepeating ? targetFrequency : 0

  return (
    <div>
      <Slider
        step={1}
        min={1}
        max={7}
        value={targetFrequency}
        sliderStyle={sliderStyle}
        onChange={(e, value) => onFrequencyChange(value)}
        disabled={!isRepeating}
      />

      <p>
        <FormattedMessage
          id="edit_goal_step_input_repeat_description"
          defaultMessage={defaultMessage}
          values={{frequency}}
        />
      </p>
    </div>
  )
}

export default TargetPicker
