import React from 'react'
import moment from 'moment'
import {FormattedMessage} from 'react-intl'

import DayPicker from './DayPicker.js'
import TimePicker from './TimePicker.js'

import {Wrap} from './ReminderSingle.styles.js'

const ReminderSingle = ({id, notifyAt, onNotifyAtChange}) => {
  const notifyAtMoment = moment(notifyAt)
  const time = notifyAt ? notifyAtMoment.format('HH:mm') : ''
  const date = notifyAt && notifyAtMoment

  const handleTimeChange = ({hour, minute}) => {
    if (!date) return
    const newNotifyAt = moment(date)
      .startOf('day')
      .hour(hour)
      .minute(minute)
    onNotifyAtChange(newNotifyAt.valueOf())
  }

  const handleDateChange = date => {
    const newNotifyAt = date
      .hour(notifyAtMoment.hour())
      .minute(notifyAtMoment.minute())
    onNotifyAtChange(newNotifyAt.valueOf())
  }

  return (
    <Wrap>
      <TimePicker value={time} onChange={handleTimeChange} />

      <FormattedMessage
        id="edit_goal_step_input_reminders_single_at_separator"
        defaultMessage="on"
      />

      <DayPicker
        id={id}
        value={date}
        onChange={handleDateChange}
        minDate={new Date()}
      />
    </Wrap>
  )
}

export default ReminderSingle
