import {combineReducers} from 'redux'
import {combineEpics} from 'redux-observable'

import app, {appEpics} from './ducks/app'
import route, {routeEpics} from './ducks/route'
import auth, {authEpics} from './ducks/auth'
import content, {contentEpics} from './ducks/content'
import userContent, {userContentEpics} from './ducks/userContent'
import {goalEpics} from './ducks/goal'
import goalTemplateDetailsModal, {
  goalTemplateDetailsModalEpics,
} from './ducks/goalTemplateDetailsModal'
import createGoal from './ducks/createGoal'
import goalDetails, {goalDetailsEpics} from './ducks/goalDetails'
import editGoalTask, {editGoalTaskEpics} from './ducks/editGoalTask'
import goalNav, {goalNavEpics} from './ducks/goalNav'
import dayplanner, {dayplannerEpics} from './ducks/dayplanner'
import timing, {timingEpics} from './ducks/timing'
import {todoTaskEpics} from './ducks/todoTask'
import journeyEntry, {journeyEntryEpics} from './ducks/journeyEntry'
import wheel, {wheelEpics} from './ducks/wheel'
import lifeWheel, {lifeWheelEpics} from './ducks/lifeWheel'
import wheelAssessment, {wheelAssessmentEpics} from './ducks/wheelAssessment'
import moodAssessment, {moodAssessmentEpics} from './ducks/moodAssessment'
import moodTag, {moodTagEpics} from './ducks/moodTag'
import moodStatistics from './ducks/moodStatistics'
import fcm, {fcmEpics} from './ducks/fcm'
import electron, {electronEpics} from './ducks/electron'
import {dataExportEpics} from './ducks/dataExport'
import userSetting, {userSettingEpics} from './ducks/userSetting'
import language, {languageEpics} from './ducks/language'
import dayplan, {dayplanEpics} from './ducks/dayplan'
import exercise, {exerciseEpics} from './ducks/exercise'
import {editMoodAssessmentModalEpics} from './ducks/editMoodAssessmentModal'
import {metricEpics} from './ducks/metric'
import organization, {organizationEpics} from './ducks/organization'
import pulse, {pulseEpics} from './ducks/pulse'
import appFlavor, {appFlavorEpics} from './ducks/appFlavor'
import userGroup, {userGroupEpics} from './ducks/userGroup'

export const rootReducer = combineReducers({
  app,
  route,
  auth,
  content,
  userContent,
  goalTemplateDetailsModal,
  createGoal,
  goalDetails,
  editGoalTask,
  goalNav,
  dayplanner,
  timing,
  journeyEntry,
  wheel,
  lifeWheel,
  wheelAssessment,
  moodAssessment,
  moodTag,
  moodStatistics,
  fcm,
  electron,
  userSetting,
  language,
  dayplan,
  exercise,
  organization,
  pulse,
  appFlavor,
  userGroup,
})

export const rootEpic = combineEpics(
  appEpics,
  routeEpics,
  authEpics,
  contentEpics,
  userContentEpics,
  goalEpics,
  goalTemplateDetailsModalEpics,
  goalDetailsEpics,
  editGoalTaskEpics,
  dayplannerEpics,
  timingEpics,
  todoTaskEpics,
  journeyEntryEpics,
  goalNavEpics,
  wheelEpics,
  lifeWheelEpics,
  wheelAssessmentEpics,
  moodAssessmentEpics,
  moodTagEpics,
  fcmEpics,
  electronEpics,
  dataExportEpics,
  userSettingEpics,
  languageEpics,
  dayplanEpics,
  exerciseEpics,
  editMoodAssessmentModalEpics,
  metricEpics,
  organizationEpics,
  pulseEpics,
  appFlavorEpics,
  userGroupEpics,
)
