import React, {Component} from 'react'
import {FormattedMessage} from 'react-intl'
import {get} from 'lodash'
import Select from './Select.loadable.js'

import {Wrap, AutosuggestWrap} from './AssignGoalTaskInput.styles.js'

class AssignGoalTaskInput extends Component {
  render() {
    const {
      organizationUsers,
      organizationUsersById,
      value,
      ...rest
    } = this.props

    const selectOptions = organizationUsers.map(({id, name}) => ({
      value: id,
      label: name,
    }))

    // hotfix(28/9-18): react-select v2 require both value & label
    // https://github.com/JedWatson/react-select/issues/3063
    const v = value.map(({value, label}) => ({
      value,
      label:
        label || get(organizationUsersById, [value, 'name'], 'Unknown User'),
    }))

    return (
      <Wrap>
        <FormattedMessage
          id="assign_task_description"
          defaultMessage="Select the persons you would like to assign this task to."
        />

        <AutosuggestWrap>
          <Select
            name="form-field-name"
            options={selectOptions}
            value={v}
            isMulti={true}
            {...rest}
          />
        </AutosuggestWrap>
      </Wrap>
    )
  }
}

export default AssignGoalTaskInput
