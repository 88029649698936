import {createAction} from 'redux-actions'
import {createSelector} from 'reselect'
import {combineEpics, ofType} from 'redux-observable'
import {values, keyBy} from 'lodash'
import {of} from 'rxjs'
import {map, mergeMap, catchError, concat} from 'rxjs/operators'
import {FirebaseApi} from '../../api'

import {
  getActiveGoals,
  getCompletedGoals,
  getTasksFromGoals,
} from '../../utils/goal'

import {redirectToGoalDetails} from '../route'
import {getMyGoalsById} from '../userContent'

export const createGoal = createAction('remente/goal/createGoal')
export const createGoalFromExercise = createAction(
  'remente/goal/createGoalFromExercise',
)
export const createGoalFulfilled = createAction(
  'remente/goal/createGoalFulfilled',
)
export const createGoalRejected = createAction(
  'remente/goal/createGoalRejected',
)
export const deleteGoal = createAction('remente/goal/deleteGoal')
const deleteGoalFulfilled = createAction('remente/goal/deleteGoalFulfilled')
const deleteGoalRejected = createAction('remente/goal/deleteGoalRejected')
export const setGoalCompleted = createAction('remente/goal/setGoalCompleted')
const setGoalCompletedFulfilled = createAction(
  'remente/goal/setGoalCompletedFulfilled',
)
const setGoalCompletedRejected = createAction(
  'remente/goal/setGoalCompletedRejected',
)
export const setGoalActive = createAction('remente/goal/setGoalActive')
const setGoalActiveFulfilled = createAction(
  'remente/goal/setGoalActiveFulfilled',
)
const setGoalActiveRejected = createAction('remente/goal/setGoalActiveRejected')

/**
 * Selectors
 */

export const getMyGoals = createSelector(getMyGoalsById, goalsById =>
  values(goalsById),
)

export const getMyGoalsActive = createSelector(getMyGoals, goals =>
  getActiveGoals(goals),
)

export const getMyGoalsCompleted = createSelector(getMyGoals, goals =>
  getCompletedGoals(goals),
)

export const getNumMyGoalsActive = createSelector(
  getMyGoalsActive,
  goals => goals.length,
)

export const getNumMyGoalsCompleted = createSelector(
  getMyGoalsCompleted,
  goals => goals.length,
)

export const getMyGoalsActiveTasks = createSelector(getMyGoalsActive, goals =>
  getTasksFromGoals(goals),
)

export const getMyGoalsActiveTasksById = createSelector(
  getMyGoalsActiveTasks,
  tasks => keyBy(tasks, 'id'),
)

/**
 * Epics
 */

const createGoalEpic = action$ =>
  action$.pipe(
    ofType(createGoal().type),
    map(({payload}) => payload),
    mergeMap(({organizationId, ...goal}) => {
      const id = FirebaseApi.newId()
      return of(redirectToGoalDetails(id)).pipe(
        concat(
          FirebaseApi.createGoal({goal: {id, ...goal}, organizationId}).pipe(
            map(createGoalFulfilled),
            catchError(err => of(createGoalRejected(err))),
          ),
        ),
      )
    }),
  )

const createGoalFromExerciseEpic = action$ =>
  action$.pipe(
    ofType(createGoalFromExercise().type),
    map(({payload}) => payload),
    mergeMap(goal => {
      const id = FirebaseApi.newId()
      return FirebaseApi.createGoal({goal: {id, ...goal}}).pipe(
        map(createGoalFulfilled),
        catchError(err => of(createGoalRejected(err))),
      )
    }),
  )

const deleteGoalEpic = action$ =>
  action$.pipe(
    ofType(deleteGoal().type),
    mergeMap(({payload}) =>
      FirebaseApi.deleteGoal(payload).pipe(
        map(deleteGoalFulfilled),
        catchError(err => of(deleteGoalRejected(err))),
      ),
    ),
  )

const setGoalCompletedEpic = action$ =>
  action$.pipe(
    ofType(setGoalCompleted().type),
    map(({payload}) => payload),
    mergeMap(({id, organizationId}) =>
      FirebaseApi.setGoalCompleted({id, organizationId}).pipe(
        map(setGoalCompletedFulfilled),
        catchError(err => of(setGoalCompletedRejected(err))),
      ),
    ),
  )

const setGoalActiveEpic = action$ =>
  action$.pipe(
    ofType(setGoalActive().type),
    map(({payload}) => payload),
    mergeMap(({id, organizationId}) =>
      FirebaseApi.setGoalActive({id, organizationId}).pipe(
        map(setGoalActiveFulfilled),
        catchError(err => of(setGoalActiveRejected(err))),
      ),
    ),
  )

export const goalEpics = combineEpics(
  createGoalEpic,
  createGoalFromExerciseEpic,
  deleteGoalEpic,
  setGoalCompletedEpic,
  setGoalActiveEpic,
)
