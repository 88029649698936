import React, {Component} from 'react'

import {VideoContainer} from './VideoEmbed.styles.js'

class VideoEmbed extends Component {
  render() {
    const {props} = this
    const {youtube, vimeo, ...rest} = props
    const videoUrl = vimeo
      ? `https://player.vimeo.com/video/${vimeo}?color=ffffff&title=0&byline=0&portrait=0`
      : `https://www.youtube.com/embed/${youtube}`

    return (
      <VideoContainer {...rest}>
        <iframe
          src={videoUrl}
          frameBorder="0"
          title="video"
          allowFullScreen
        ></iframe>
      </VideoContainer>
    )
  }
}

export default VideoEmbed
